.dashboard {
  padding-left: 0;
  padding-right: 0;
  transition: all 0.3s;

  .ico-file,
  .ico-print,
  .ico-mods {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      color: $orange;
    }
  }

  label {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      font-size: map-get($font-sizes, 14);
    }
  }

  #edit-salarie {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      padding: 24px 32px;
    }

    #jform_a_cafat,
    #jform_est_etudiant {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        display: flex;
      }

      .form-check {
        // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
        @include media-breakpoint-between(md, xxl) {
          display: flex;
          align-items: baseline;
        }

        label {
          // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
          @include media-breakpoint-between(md, xxl) {
            margin-left: 10px;
          }
        }
      }
    }

    .form-check-label {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        font-size: map-get($font-sizes, 16);
      }
    }
  }

  &-login {
    @include media-breakpoint-between(md, xxl) {
      padding-left: 100px;
    }

    #zone-quizz {
      .quizz-39,
      .quizz-40 {
        .draggable {
          p {
            @extend .ff-2;
            @extend .fs-14;
            @extend .fw-medium;
            color: $gray-dark;
            padding: 0 25px;
          }
        }
      }

      .quizz-39 {
        .draggable {
          img {
            height: 100px;
            max-width: fit-content;
          }
        }
      }

      .quizz-40 {
        .ui-droppable {
          &-hover {
            @extend .border-secondary;
          }
        }


        .draggable {
          img {
            height: 90px;
            max-width: fit-content;
          }
        }
      }
    }

    #menu {
      position: fixed;
      z-index: 20;
      @include media-breakpoint-up(xxl) {
        width: 360px;
      }
    }

    #main {
      @include media-breakpoint-up(xxl) {
        padding-left: 360px;
      }
    }

    #jform_privacyconsent_privacy {
      box-shadow: none;
      border-radius: 4px;
      border: 1px solid #989898;
      width: 16px;
      height: 16px;
      flex: inherit;
      margin-right: 8px;
    }

    #jform_privacyconsent_privacy label {
      padding-left: 0;
      margin-right: 35px;
    }

    #member-profile legend {
      font-family: Inter;
      font-size: 16px;
      font-weight: $font-weight-normal;
      line-height: 26px;
    }

    #charte-employeur {
      li {
        list-style: none;
        @extend .d-flex;
        @extend .align-items-center;

        &:before {
          content: "";
          @extend .d-block;
          border-radius: 4px;
          width: 4px;
          height: 4px;
          background-color: $orange;
          margin-right: 8px;
        }
      }

      #accepter_charte {
        margin-right: 4px;
      }
    }

    .profile {
      background-color: $gray-light;
      color: $dark;
      font-size: map-get($font-sizes, 20);
      font-weight: $font-weight-bold;
      font-family: Urbanist;

      .initiale {
        font-size: map-get($font-sizes, 32);
        line-height: 42px;
        padding: 10px;
        border-radius: 128px;
        color: $white;
        background-color: $dark;
        width: 56px;
        height: 56px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    #choisir-employeur {
      .employeur-item {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    #formations_employeur {
      .avancement {
        @extend .position-absolute;
        width: min-content;
        text-align: center;
        top: 32px;
        right: 32px;
      }
    }
  }

  #reponses {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      padding: 40px 25px;
    }

    > .btn {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        padding: 0;
        margin-bottom: 30px !important;
      }
    }

    > .card-body {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        padding: 40px 48px;
      }
    }

    .accordion-button {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        padding: 20px 24px;
      }

      span {
        // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
        @include media-breakpoint-between(md, xxl) {
          display: flex;
          align-items: center;
        }

      }
    }

    ul li,
    p {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        line-height: 25px;
      }
    }

    .text-secondary {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        font-size: map-get($font-sizes, 14);
      }
    }
  }

  &:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      left: 330px;
      padding-left: 0;
    }
  }

  &-menu {
    height: 100vh;
    top: 0;
    z-index: 1;
    overflow: auto;
  }

  &-main {
    min-height: 100vh;

    .carousel-inner {
      @include media-breakpoint-between(md, xxl) {
        background-position: center !important;
      }
    }
  }

  &-logo {
    display: block;
    margin: 0 auto;
    width: 8.2rem;
  }

  &-title-wrapper {
    margin-top: 5vh;
  }

  &-breadcrumb {
    .nav-item {
      color: $secondary;
      display: flex;
      align-items: center;
      font-size: map-get($font-sizes, 18);
      font-weight: $font-weight-medium;
      margin-bottom: 2.4rem;

      &:before {
        content: '';
        width: 2rem;
        height: 2rem;
        display: inline-block;
        margin-right: .8rem;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.active {
        color: $white;

        &:before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxOCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC45MTExIDAuNDEwNzYyQzExLjIzNjUgMC4wODUzMjY3IDExLjc2NDIgMC4wODUzMjg5IDEyLjA4OTYgMC40MTA3NjdMMTcuMDg5NiA1LjQxMDc3QzE3LjQxNSA1LjczNjIgMTcuNDE1IDYuMjYzODQgMTcuMDg5NiA2LjU4OTI3TDEyLjA4OTYgMTEuNTg5M0MxMS43NjQyIDExLjkxNDcgMTEuMjM2NSAxMS45MTQ3IDEwLjkxMTEgMTEuNTg5M0MxMC41ODU3IDExLjI2MzggMTAuNTg1NyAxMC43MzYyIDEwLjkxMTEgMTAuNDEwOEwxNC40ODg1IDYuODMzMzVIMS41MDAzM0MxLjA0MDA5IDYuODMzMzUgMC42NjY5OTIgNi40NjAyNiAwLjY2Njk5MiA2LjAwMDAyQzAuNjY2OTkyIDUuNTM5NzggMS4wNDAwOSA1LjE2NjY5IDEuNTAwMzMgNS4xNjY2OUgxNC40ODg1TDEwLjkxMTEgMS41ODkyN0MxMC41ODU3IDEuMjYzODQgMTAuNTg1NyAwLjczNjE5OCAxMC45MTExIDAuNDEwNzYyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
        }
      }

      &.validated {
        color: $success;

        &:before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTMuNTE4MzUgMy41MTgxQzUuMTc2MyAxLjg2MDE4IDcuNDY5MSAwLjgzMzI1MiAxMC4wMDAyIDAuODMzMjUyQzEyLjUzMTIgMC44MzMyNTIgMTQuODI0IDEuODYwMTggMTYuNDgyIDMuNTE4MUMxOC4xMzk5IDUuMTc2MDUgMTkuMTY2OCA3LjQ2ODg2IDE5LjE2NjggOS45OTk5MkMxOS4xNjY4IDEyLjUzMSAxOC4xMzk5IDE0LjgyMzggMTYuNDgyIDE2LjQ4MTdDMTQuODI0IDE4LjEzOTYgMTIuNTMxMiAxOS4xNjY2IDEwLjAwMDIgMTkuMTY2NkM3LjQ2OTEgMTkuMTY2NiA1LjE3NjMgMTguMTM5NiAzLjUxODM1IDE2LjQ4MTdDMS44NjA0MyAxNC44MjM4IDAuODMzNDk2IDEyLjUzMSAwLjgzMzQ5NiA5Ljk5OTkyQzAuODMzNDk2IDcuNDY4ODYgMS44NjA0NCA1LjE3NjA0IDMuNTE4MzUgMy41MTgxWk0xNC43NTYxIDguMDg5MTdDMTUuMDgxNSA3Ljc2Mzc0IDE1LjA4MTUgNy4yMzYxIDE0Ljc1NjEgNi45MTA2NkMxNC40MzA2IDYuNTg1MjMgMTMuOTAzIDYuNTg1MjMgMTMuNTc3NiA2LjkxMDY2TDkuMTY2ODMgMTEuMzIxNEw3LjI1NjA4IDkuNDEwNjZDNi45MzA2NSA5LjA4NTIzIDYuNDAzMDEgOS4wODUyMyA2LjA3NzU3IDkuNDEwNjZDNS43NTIxNCA5LjczNjEgNS43NTIxNCAxMC4yNjM3IDYuMDc3NTcgMTAuNTg5Mkw4LjU3NzU3IDEzLjA4OTJDOC45MDMwMSAxMy40MTQ2IDkuNDMwNjUgMTMuNDE0NiA5Ljc1NjA4IDEzLjA4OTJMMTQuNzU2MSA4LjA4OTE3WiIgZmlsbD0iIzY0QjM3NSIvPjwvc3ZnPg==");
        }
      }
    }
  }

  .login {
    margin-top: 65px;
    padding: 0 50px;

    label {
      font-family: Inter;
      font-size: map-get($font-sizes, 14);
      font-style: normal;
      font-weight: $font-weight-medium;
      margin-bottom: 8px;
    }

    input {
      @extend .border-0;
      font-family: Inter;
      font-size: map-get($font-sizes, 16);
      font-weight: $font-weight-normal;
      padding: 16px;
      color: $gray-md;
      background-color: $gray-light;
    }

    .btn {
      font-family: Inter;
      font-weight: $font-weight-semi-bold;
    }

    a {
      font-family: Inter;
      font-size: map-get($font-sizes, 14);
    }
  }

  .dashboard-title {
    span {
      @extend .d-block;
    }
  }

  &-offline {
    .dashboard-logo {
      width: 16rem;
    }

    .dashboard-title {
      margin-top: 40px;
    }
  }

  &:not(.dashboard-offline) {
    .dashboard-breadcrumb {
      margin-top: 7vh;
    }
  }

  .overflow-tablet {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      overflow: hidden;
    }
  }

  .ps-header {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      padding: 16px;
    }
  }

  .filtre_statut_passage {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      width: 10%;
    }
  }

  select,
  input {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      font-family: Inter;
      font-size: map-get($font-sizes, 14);
      font-weight: $font-weight-medium;
      color: $gray-md;
    }
  }

  select {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      background-color: $white;
    }
  }

  .btn_action_employeur {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      height: 100%;
      display: flex;
    }
  }

  #filtre-salarie {
    h3 {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        display: none;
      }
    }
  }

  .menu-chapitre {
    .dropdown-toggle::after {
      @extend .d-none;
    }

    .ss_chapitre {
      @extend .text-black;

      &.active,
      &:hover {
        @extend .text-primary;
      }
    }

    @include media-breakpoint-between(md, xxl) {
      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .actions-salaries a,
  #tab-vue-detail-modules,
  #tab-vue-generale {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      font-size: map-get($font-sizes, 16);
    }
  }

  #tab-vue-generale,
  #tab-vue-detail-modules {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      padding: 12px 24px;
      color: $gray-md;
    }

    &.active {
      color: $black;
    }
  }

  #vue-generale {
    thead {

    }
  }

  .actions-salaries {
    li {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        margin-right: 30px
      }

      &:last-child {
        // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
        @include media-breakpoint-between(md, xxl) {
          margin-right: 0px
        }
      }
    }

    a {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        padding: 0;
      }
    }
  }

  table {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      margin-top: 0;
      padding: 24px
    }

    th {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        padding: 12px;
      }

      &:first-child {
        // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
        @include media-breakpoint-between(md, xxl) {
          padding: 12px;
          text-align: center;
        }

        div {
          // Samsung galaxy TAB A7  + Lenovo M10 ZAG4 + Samsung TAB3
          @include media-breakpoint-between(md, xxl) {
            display: none;
          }
        }
      }
    }

    td {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        padding: 0 12px !important;
        background-color: $white !important;
        font-family: Inter;
        font-size: map-get($font-sizes, 14);
        font-weight: $font-weight-medium;
      }

      span {
        // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
        @include media-breakpoint-between(md, xxl) {
          font-family: Inter;
          font-size: map-get($font-sizes, 14);
          font-weight: $font-weight-normal;
        }
      }

      .badge,
      .badge span {
        // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
        @include media-breakpoint-between(md, xxl) {
          font-family: Inter;
          font-size: map-get($font-sizes, 12);
          font-weight: $font-weight-bold;
        }
      }

      .badge {
        // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
        @include media-breakpoint-between(md, xxl) {
          padding: 8px 16px;
        }
      }
    }

    .show-hide-password {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        width: 16px;
        height: 16px;
        right: -7px;
        position: relative;
        top: -1px;
      }
    }

    #show-hide-password {
      display: flex;

      img {
        width: 16px;
        height: 16px;
        object-fit: none;
        // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
        @include media-breakpoint-between(md, xxl) {
          width: 16px;
          height: 16px;
          right: 8px;
          position: absolute;
        }
      }
    }
  }

  #liste-modules-complementaires {
    table {
      td {
        // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
        @include media-breakpoint-between(md, xxl) {
          padding-top: 12px !important;
          padding-bottom: 12px !important;
        }
      }
    }
  }

  #liste-modules-complementaires,
  #vue-detail-modules {
    table {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        padding: 12px 24px;
      }

      th {
        .text-info {
          // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
          @include media-breakpoint-between(md, xxl) {
            font-weight: $font-weight-semi-bold;
          }
        }
      }
    }
  }
}

#offcanvas-push {
  display: initial;

  .uk-offcanvas-close {
    opacity: 0;
  }

  .menu {
    font-family: Urbanist;
    font-size: map-get($font-sizes, 16);
    font-weight: $font-weight-bold;
  }

  .informations {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .mod-languages_langue {
      .lang-inline {
        width: max-content;
        transform: translate3d(0, -35px, 0px) !important;
      }
    }

    #mod_solde {
      .card-body {
        @extend .text-center;
      }
    }

    .dropdown-toggle::after {
      width: 30px;
    }

    .mod-languages_langue,
    #mod_solde,
    .dashboard-breadcrumb,
    h1 {
      display: none !important;

    }

    h1 {
      white-space: nowrap;
    }

    #mod_solde {
      overflow: hidden;
      height: 217px;
    }


    #login-form {
      pointer-events: none;

      .dropdown-menu {
        width: max-content;
        transform: translate3d(0, -170px, 0px) !important
      }

      button {
        border-color: transparent !important;

        .ps-avatar-initials {
          transform: scale(1.5);
        }

        span:last-child {
          display: none;
          opacity: 0;
          transition: all 1s;
        }
      }
    }

    h1 {
      @extend .mb-0;
      margin-top: 48px;
    }

    .dashboard-breadcrumb {
      margin-top: 0;
    }
  }


  &.uk-open {
    .informations {
      height: inherit;
    }

    .mod-languages_langue {
      display: initial;
    }

    .uk-offcanvas-close {
      opacity: 1;
    }

    .burger {
      display: none !important;
    }

    #mod_solde,
    .dashboard-breadcrumb,
    h1 {
      display: block !important;
    }

    .mod-languages_langue {
      display: flex !important;
    }

    #login-form {
      pointer-events: initial;

      button {
        border-color: initial !important;

        .ps-avatar-initials {
          transform: scale(1);
        }

        span:last-child {
          display: block;
          opacity: 1;
        }
      }
    }

    > .uk-offcanvas-bar {
      left: 0;
      width: 330px;
    }
  }
}

.uk-offcanvas-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100px;
  left: 0;
  padding: 0 10px;
  padding-bottom: 40px;
  transition: all 0.3s;
}

// Ipad
@include media-breakpoint-between(md, xxl) {
  margin-left: 15px;
}