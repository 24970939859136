.bannergroup_bandeaux{
  &:after {
    content: '';
    position: absolute;
    background: rgba($black, 0.2);
    inset: 0 0 0 0;
  }
  #slider_custom {
    ul {
      li {
        img {
          min-height: calc(100vh - 143px);
        }
      }
    }
  }
}

#message-maintenance {
  display: flex;
  background: #FFF3CD;
  align-items: center;
  justify-content: center;
  color: #856404;
  font-family: Inter;
  padding: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  p {
    margin: 0;
  }
  .en-savoir-plus {
    color: #856404;
    font-family: Inter;
    text-align: center;
    margin-left: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    text-decoration-line: underline;
  }
}