// override rsform class
#userForm{
  background-color: $white;
  border-radius: map-get($radius,2);
  padding: map-get($spacers,30);
  margin-bottom: map-get($spacers,30);
}

.form-row{
  @include make-row();
  padding: 0 map-get($gutters,3);
  margin: 0 -2rem;
  & > div{
    padding:0 map-get($gutters,3);
    label{
      margin: 1rem 0;
    }
  }
}