.ps{
  &-avatar-initials {
    border-radius: 50%;
    background-color: $white;
    color: $primary-dark;
    aspect-ratio: 1;
    width: 4.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-01;
    font-size: map-get($font-sizes, 16);
    font-weight: 700;
    border: 1px solid $light;
  }
  &-header {
    --ps-header-padding-x: 1.6rem;
    --ps-header-padding-y: 2.4rem;
    padding: var(--ps-header-padding-x) var(--ps-header-padding-y);
  }
  &-avatar-container {
    position: relative;
  }
  &-avatar-btn {
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    padding-top: .8rem;
    padding-bottom: .8rem;
  }
  &-avatar-img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.actions-salaries {
  a {
    &.disabled {
      color: $black;
    }
    &.active {
      color: $primary-dark;
      font-weight: 700;
    }
  }
}

.mdp {
  &.hide {
    display: none;
  }
}

.table{
  .table-link-hover {
    visibility: hidden;
  }
  tr:hover .table-link-hover {
    visibility: visible;
  }
  .table-highlight td {
    color: $primary;
    font-weight: 600;
  }
}

#show-hide-password {
  img {
    width: 24px;
  }
}
.show-hide-password {
  img {
    vertical-align: baseline;
  }
}

#affectation-modules {
  table {
    font-size: 1.2rem;
    .passeport {
      width: 150px;
      padding: 1rem 4.4rem 1rem 1rem;
    }
  }
}

.recherche-salarie {
  font-size: 1.4rem;
  td {
    .js-password:nth-child(2) {
      font-weight: bold;
    }
  }
}

#vue-generale, #vue-detail-modules {
  table {
    tbody {
      td:nth-child(2), td:nth-child(3) {
        padding: 1.6rem 0.5rem;
      }
    }
  }
}

.text-overflow {
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.force-position {
  top: 0 !important;
  margin-top: 50px !important;
}

select:invalid {
  height: 0px !important;
  opacity: 0 !important;
  position: absolute !important;
  display: flex !important;
}

select:invalid[multiple] {
  margin-top: 15px !important;
}

#videowrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.hidden {
  display: none !important;
}

#bar {
  width: 0%;
  height: 5px;
  border-radius: 16px;
  border: 1px solid #ECE8DF;
  height: 16px;
}

#zone-quizz {
  .mauvais {
    background-color: #E05C5C !important;
    color: #fff;
    border-color: initial;
  }

  .bon {
    background-color: #64B375 !important;
    color: #fff;
    border-color: initial;
  }
  .drop-zone {
    position: relative;
    & > div {
      font-size: 14px;
    }
    img {
      //position: absolute;
      //bottom: 15px;
      //left: 0;
      //right: 0;
      //margin: auto;
    }
    .draggable {
      margin: auto;
    }
  }
}

.form-actions-bar {
  z-index: 10;
}