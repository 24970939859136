// Tous les types de titre par défaut

h1{
  @include font($font-01, $h1-font-size, $font-weight-bold);
}

h2{
  @include font($font-01, $h2-font-size, $font-weight-bold);
  line-height: 4.2rem;
}

h3{
  @include font($font-01, $h3-font-size, $font-weight-bold);
}

h4{
  @include font($font-02, $h4-font-size, $font-weight-bold);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  line-height: 1.7rem;
}

h5{
  @include font($font-02, $h5-font-size, $font-weight-bold);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.h1{
  font-family: $font-01 !important;
  font-size: $h1-font-size !important;
  font-weight: $font-weight-bold !important;
}

.h2{
  font-family: $font-01 !important;
  font-size: $h2-font-size !important;
  font-weight: $font-weight-bold !important;
  line-height: 4.2rem !important;
}

.h3{
  font-family: $font-01 !important;
  font-size: $h3-font-size !important;
  font-weight: $font-weight-bold !important;
}

.h4{
  font-family: $font-02 !important;
  font-size: $h4-font-size !important;
  font-weight: $font-weight-bold !important;
  text-transform: uppercase !important;
  letter-spacing: 0.2rem !important;
  line-height: 1.7rem !important;
}

.h5{
  font-family: $font-02 !important;
  font-size: $h5-font-size !important;
  font-weight: $font-weight-bold !important;
  text-transform: uppercase !important;
  letter-spacing: 0.1rem !important;
}