// Mettre à jour ce lien via : https://fonts.google.com/

@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* ======= fonts à déclarer
*/

$font-01 : Urbanist, 'Open Sans', sans-serif;
$font-02 : Inter, 'Open Sans', sans-serif;
//$font-03 : 'Open Sans', sans-serif;

$font-family-base: $font-01;

/* ======= fonts weight
*/

/* ======= variables de bootsrap à réutiliser
https://getbootstrap.com/docs/5.1/utilities/text/#font-weight-and-italics
*/

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;

/* ======= fonts sizes
*/

$font-sizes: (
  10: map-get($sizes, 10),
  12: map-get($sizes, 12),
  14: map-get($sizes, 14),
  16: map-get($sizes, 16),
  18: map-get($sizes, 18),
  20: map-get($sizes, 20),
  24: map-get($sizes, 24),
  28: map-get($sizes, 28),
  32: map-get($sizes, 32),
  64: map-get($sizes, 64),
);

$h1-font-size:                map-get($font-sizes, 64);
$h2-font-size:                map-get($font-sizes, 32);
$h3-font-size:                map-get($font-sizes, 20);
$h4-font-size:                map-get($font-sizes, 12);
$h5-font-size:                map-get($font-sizes, 10);
$h6-font-size:                map-get($font-sizes, 10);
$base-body-font-size:         map-get($font-sizes, 16);