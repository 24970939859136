
/* ======= border-radius
https://getbootstrap.com/docs/5.1/utilities/borders/#variables
*/

$border-radius: map-get($sizes, 16);
$border-radius-sm: map-get($sizes, 8);
$border-radius-lg: map-get($sizes, 24);
$border-radius-xl: map-get($sizes, 32);
$border-radius-pill: map-get($sizes, 50);

$radius: ();

$radius: (
	null: $border-radius,
	0: 0,
	1: $border-radius-sm,
	2: $border-radius,
	3: $border-radius-lg,
	4: $border-radius-xl,
	circle:50%,
	pill: $border-radius-pill
);

/* ======= spacers
https://getbootstrap.com/docs/5.1/utilities/spacing/#sass
*/

$spacer: 1rem;
$spacers: (
	0: 0,
	6: map-get($sizes, 6),
	8: map-get($sizes, 8),
	10: map-get($sizes, 10),
	12: map-get($sizes, 12),
	14: map-get($sizes, 14),
	16: map-get($sizes, 16),
	18: map-get($sizes, 18),
	20: map-get($sizes, 20),
	24: map-get($sizes, 24),
	32: map-get($sizes, 32),
	40: map-get($sizes, 40),
	42: map-get($sizes, 42),
	48: map-get($sizes, 48),
	64: map-get($sizes, 64),
	112: 11.2rem
);

/* ======= les gutters
https://getbootstrap.com/docs/5.1/layout/gutters/#change-the-gutters
*/

$grid-gutter-width: map-get($sizes, 16);
$gutters: (
	0: 0,
	1: $spacer * .25,
	2: $spacer * .5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
	8: 8rem,
);

/* ======= position absolute
https://getbootstrap.com/docs/5.1/utilities/position/#sass
*/

$position-values: (
	0: 0,
	10: 10%,
	20: 20%,
	40: 40%,
	50: 50%,
	90: 90%,
	100: 100%
);

$positions: ( static relative absolute fixed sticky );

/* ======= z-index
*/

$zindexs: (1 2 3 4 5);

/* ======= width
https://getbootstrap.com/docs/5.1/utilities/sizing/#sass
*/

$widths: (
	20: 20%,
	25: 25%,
	50: 50%,
	70: 70%,
	75: 75%,
	80: 80%,
	90: 90%,
	100: 100%,
	auto: auto
);

/* ======= utilities de bootstrap
*/

$utilities: ();
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
	(
		"height": (
			property: height,
			class: h,
			responsive:true,
			values: $widths
		),
		"width": (
				property: width,
				class: w,
				responsive:true,
				values: $widths
		),
		"position": (
				property: position,
				responsive:true,
				values: $positions
		),
		"z-index": (
				property: z-index,
				responsive: true,
				values: $zindexs
		),
		"font-size": (
				rfs: true,
				property: font-size,
				responsive: true,
				class: fs,
				values: $font-sizes
		),
		"font-weight": (
				property: font-weight,
				class: fw,
				values: (
						light: $font-weight-light,
						normal: $font-weight-normal,
						semi-bold: $font-weight-semi-bold,
						bold: $font-weight-bold
				)
		),
		"font-family" : (
			property: font-family,
			class: ff,
			values: (
				01: $font-01,
				02: $font-02,
			)
		),
		"line-height": (
				property: line-height,
				class: lh,
				values: $spacers
		),
		"gap": (
			property: gap,
			class: gap,
			responsive: true,
			values: $spacers
		),
		"cursor": (
			property: cursor,
			class: cr,
			values: (
				pointer: pointer,
				help: help,
				wait: wait,
				not-allowed: not-allowed
			)
		),
		"rounded": (
				property: border-radius,
				class: rounded,
				responsive: true,
				values: ( $radius )
		),
		"rounded-top": (
				property: border-top-left-radius border-top-right-radius,
				class: rounded-top,
				responsive: true,
				values: ( $radius )
		),
		"rounded-end": (
				property: border-top-right-radius border-bottom-right-radius,
				class: rounded-end,
				responsive: true,
				values: ( $radius )
		),
		"rounded-bottom": (
				property: border-bottom-right-radius border-bottom-left-radius,
				class: rounded-bottom,
				responsive: true,
				values: ( $radius )
		),
		"rounded-start": (
				property: border-bottom-left-radius border-top-left-radius,
				class: rounded-start,
				responsive: true,
				values: ( $radius )
		),
	),
	$utilities
);

/* ======= alerts
https://getbootstrap.com/docs/5.1/components/alerts/#variables
*/

$alert-padding-y: map-get($sizes, 24);
$alert-padding-x: map-get($sizes, 24);
$alert-bg-scale: 0;
$alert-border-scale: 0;
$alert-border-radius: $border-radius-sm;

	/* ======= transitions
    feinte à @hugo
    */

$transition: .25s ease-in-out;

/* ======= shadow générique
https://getbootstrap.com/docs/5.1/utilities/shadows/#variables
*/

$enable-shadows: true;
$box-shadow-sm: 0 .5rem 1rem rgba($black, .05);
$box-shadow: 0 1rem 2rem rgba($black, .05);
$box-shadow-lg: 0 2rem 4rem rgba($black, .15);

/* ======= card
https://getbootstrap.com/docs/5.1/components/card/#variables
*/

$card-spacer-y: map-get($sizes, 32);
$card-spacer-x: map-get($sizes, 32);
$card-spacer-y-sm: map-get($sizes, 24);
$card-spacer-x-sm: map-get($sizes, 24);
$card-spacer-y-lg: map-get($sizes, 48);
$card-spacer-x-lg: map-get($sizes, 48);
$card-border-width: 0;
$card-box-shadow: null;

/* =================== Taille des containers
https://getbootstrap.com/docs/5.1/layout/containers/#sass
 */

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1340px
);

/* =================== Badge
https://getbootstrap.com/docs/5.3/components/badge/#variables
 */
$badge-border-radius: $border-radius-pill;
$badge-padding-y:                   map-get($sizes, 6);
$badge-padding-x:                   map-get($sizes, 16);
$badge-font-size:                   map-get($sizes, 12);
$badge-font-weight:                 $font-weight-bold;
$badge-color:                       $white;

/* =================== Nav
https://getbootstrap.com/docs/5.1/components/navs-tabs/#variables
 */

$nav-link-padding-x: map-get($sizes, 24);
$nav-link-padding-y: map-get($sizes, 8);
$nav-pills-border-radius: $border-radius-sm;
$nav-pills-link-active-color:       $black;
$nav-pills-link-active-bg:          $primary;

/* =================== Btn
https://getbootstrap.com/docs/5.1/components/buttons/#variables
 */

$btn-padding-y: map-get($sizes, 12);
$btn-padding-x: map-get($sizes, 24);
$btn-padding-y-lg:map-get($sizes, 16);
$btn-padding-x-lg:map-get($sizes, 24);
$btn-font-family: $font-01;
$btn-font-size: map-get($font-sizes,16);
$btn-font-size-lg: map-get($font-sizes,16);
$btn-font-weight: $font-weight-bold;
$btn-line-height: map-get($sizes, 18);
$btn-box-shadow: 0 10px 20px 0 rgba($primary, .2);
$btn-border-radius:           $border-radius-sm;
$btn-border-radius-sm:        $border-radius-sm;
$btn-border-radius-lg:        $border-radius-sm;

/* =================== select
https://getbootstrap.com/docs/5.1/forms/select/#variables
 */

$form-select-padding-y:             map-get($sizes, 12);
$form-select-padding-x:             map-get($sizes, 20);
$form-select-font-family:           $font-02;
$form-select-font-size:             map-get($font-sizes,16);
$form-select-font-weight:           $font-weight-normal;
$form-select-border-radius:       	$border-radius-sm;
$form-select-color:       			$black;
$form-select-indicator-color:       $black;
$form-select-bg:						$light;
$form-select-border-color:				$light;
$form-select-box-shadow:				none;

/* ======= dropdown
https://getbootstrap.com/docs/5.1/components/dropdowns/#variables
*/

$dropdown-padding-y: map-get($sizes, 24);
$dropdown-padding-x: map-get($sizes, 24);
$dropdown-min-width: 100%;
$dropdown-border-radius:            $border-radius;
$dropdown-border-width: 0;
$dropdown-font-size: map-get($font-sizes,16);
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            $light;
$dropdown-link-active-color:        $white;
$dropdown-link-active-bg:           $primary;
$dropdown-inner-border-radius:      0;

/* =================== input
https://getbootstrap.com/docs/5.1/forms/form-control/#variables
 */

$input-font-size:                       map-get($font-sizes,16);
$input-padding-y:						map-get($sizes, 16);
$input-padding-x:						map-get($sizes, 16);
$input-border-radius:                   $border-radius-sm;
$input-bg:								$light;
$input-border-color:					$light;
$input-box-shadow:						none;
$form-label-font-size: 					map-get($font-sizes,14);
$form-label-font-weight:				500;
$form-label-color: 						$gray-dark;

/* =================== Checks & radios
https://getbootstrap.com/docs/5.3/forms/checks-radios/#sass-variables
 */
$form-check-input-bg: transparent;
$form-check-input-border: 1px solid $secondary ;
$form-check-input-checked-color: $primary;
$form-check-input-checked-bg-color: transaparent;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fill='#{$form-check-input-checked-color}' d='M17,5v10c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V5C2.9,3.9,3.9,3,4.9,3h10C16.1,3,17,3.9,17,5z'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-check-input-checked-color}'/></svg>");

/* =================== breadcrumb
https://getbootstrap.com/docs/5.1/components/breadcrumb/#variables
 */

$breadcrumb-item-padding-x:         .5rem;
$breadcrumb-margin-bottom:          1rem;
$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $gray-600;
$breadcrumb-divider:                quote("/");
$breadcrumb-divider-flipped:        $breadcrumb-divider;

/* =================== offcanvas
https://getbootstrap.com/docs/5.1/components/offcanvas/#variables
 */

$offcanvas-horizontal-width:        400px;

$hr-color: $light;
$hr-opacity: 1;

/* =================== tooltip
https://getbootstrap.com/docs/5.3/content/tables/#sass-variables
 */

$table-border-width: 			0;
$table-bg: 						$white;

/* =================== accordion
https://getbootstrap.com/docs/5.3/components/accordion/#sass-variables
 */
$accordion-padding-y: map-get($sizes, 24);
$accordion-padding-x: map-get($sizes, 24);
$accordion-bg: $light;
$accordion-border-width: none;
$accordion-border-radius: $border-radius-sm;
$accordion-inner-border-radius: $border-radius-sm;

$accordion-button-active-bg:              var(--bs-accordion-btn-bg);
$accordion-button-active-color:           var(--bs-accordion-btn-color);
$accordion-button-focus-border-color:     transparent;
$accordion-button-focus-box-shadow:       none;


//*** ============================ UIKIT
/* =================== tooltip */
$tooltip-padding-vertical: map-get($sizes, 24);
$tooltip-padding-horizontal: map-get($sizes, 24);
$tooltip-background: $white;
$tooltip-color: $black;
$tooltip-border-radius: $border-radius;
$tooltip-font-size: map-get($sizes, 16);
$tooltip-max-width:280px;
$tooltip-margin: map-get($sizes, 15)
