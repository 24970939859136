.optional {
  display: none;
}

.form {
  &-actions-bar {
    background: $white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.6rem 2.4rem;
    text-align: right;
    box-shadow: 0 -4px 12px rgba($black, 0.06);
  }
}

.form-check-input:checked {
  background-color: transparent !important;
}

label {
  // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
  @include media-breakpoint-between(md, xxl) {
    font-size: map-get($font-sizes, 20);
  }
}

.control-group {
  // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
  @include media-breakpoint-between(md, xxl) {
    margin-top: 15px;
  }
}

input {
  // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
  @include media-breakpoint-between(md, xxl) {
    width: 50%;
  }

  &[type="radio"], &[type="checkbox"] {
    padding: 0;
    @extend .form-check-input;
  }
}

textarea {
  // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
  @include media-breakpoint-between(md, xxl) {
    width: 50%;
  }
}

.calendar-container table {
  max-width: 500px;
}

.contact-form {
  form {
    .control-group {
      margin-bottom: 10px;
    }
  }
}

@include media-breakpoint-down(xl) {
  .form-control, .chzn-container .chzn-drop .chzn-search input[type="text"] {
    font-size: var(--bs-nav-link-font-size);
  }
}