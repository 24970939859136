.uk-tab {
  margin: 20px 0;
  li {
    border-radius: 5px;
    font-weight: bold;
    margin: 0 10px;
    padding: 8px 24px;
    color: $secondary;
    a {
      padding: 0;
    }
    &.uk-active {
      background: $orange;
      color: $black;
      &:hover {
        background: $primary-dark;
      }
    }
  }
}

.uk-slider-items > img {
  object-fit: cover;
}

.uk-modal-dialog {
  border-radius: $border-radius-lg;
}
.uk-modal-header {
  background: none;
  border-bottom: 1px solid $light;
  padding: 3.2rem;
}
.uk-modal-body {
  padding: 3.2rem;
}

.uk-tooltip{
  box-shadow: 0 4px 4px rgba($black, 0.25);
  font-weight: 600;
  line-height: 2.4rem;
  &.uk-active {
    &.arrow-bottom-left:after, &.arrow-bottom-right:after {
      content: '';
      position: absolute;
      top: -11px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 11px solid $tooltip-background;
    }
    &.arrow-bottom-left:after {
      left: 32px;
    }
    &.arrow-bottom-right:after {
      right: 32px;
    }
  }
}

.uk-notification{
  &-message {
    background: $light;
    color: $black;
    font-size: map-get($font-sizes, 16);
    cursor: pointer;
    font-weight: 600;
    &-success {
      background-color: $success;
      color: $success-light;
    }
    &-primary { // Uikit n'a pas de type info
      background-color: $info;
      color: $info-light;
    }
    &-warning {
      background-color: $warning;
      color: $warning-light;
    }
    &-danger {
      background-color: $error;
      color: $error-light;
    }
  }
}