@charset "UTF-8";
/* ======= uikit overlay couleur
*/
/* ======= couleur des liens par défault
*/
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* ======= fonts à déclarer
*/
/* ======= fonts weight
*/
/* ======= variables de bootsrap à réutiliser
https://getbootstrap.com/docs/5.1/utilities/text/#font-weight-and-italics
*/
/* ======= fonts sizes
*/
/* ======= border-radius
https://getbootstrap.com/docs/5.1/utilities/borders/#variables
*/
/* ======= spacers
https://getbootstrap.com/docs/5.1/utilities/spacing/#sass
*/
/* ======= les gutters
https://getbootstrap.com/docs/5.1/layout/gutters/#change-the-gutters
*/
/* ======= position absolute
https://getbootstrap.com/docs/5.1/utilities/position/#sass
*/
/* ======= z-index
*/
/* ======= width
https://getbootstrap.com/docs/5.1/utilities/sizing/#sass
*/
/* ======= utilities de bootstrap
*/
/* ======= alerts
https://getbootstrap.com/docs/5.1/components/alerts/#variables
*/
/* ======= transitions
    feinte à @hugo
    */
/* ======= shadow générique
https://getbootstrap.com/docs/5.1/utilities/shadows/#variables
*/
/* ======= card
https://getbootstrap.com/docs/5.1/components/card/#variables
*/
/* =================== Taille des containers
https://getbootstrap.com/docs/5.1/layout/containers/#sass
 */
/* =================== Badge
https://getbootstrap.com/docs/5.3/components/badge/#variables
 */
/* =================== Nav
https://getbootstrap.com/docs/5.1/components/navs-tabs/#variables
 */
/* =================== Btn
https://getbootstrap.com/docs/5.1/components/buttons/#variables
 */
/* =================== select
https://getbootstrap.com/docs/5.1/forms/select/#variables
 */
/* ======= dropdown
https://getbootstrap.com/docs/5.1/components/dropdowns/#variables
*/
/* =================== input
https://getbootstrap.com/docs/5.1/forms/form-control/#variables
 */
/* =================== Checks & radios
https://getbootstrap.com/docs/5.3/forms/checks-radios/#sass-variables
 */
/* =================== breadcrumb
https://getbootstrap.com/docs/5.1/components/breadcrumb/#variables
 */
/* =================== offcanvas
https://getbootstrap.com/docs/5.1/components/offcanvas/#variables
 */
/* =================== tooltip
https://getbootstrap.com/docs/5.3/content/tables/#sass-variables
 */
/* =================== accordion
https://getbootstrap.com/docs/5.3/components/accordion/#sass-variables
 */
/* =================== tooltip */
/* ====== obligatoire
 */
/* ====== optionnel
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #EB9E03;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #373736;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #373736;
  --bs-gray-900: #212529;
  --bs-primary: #EB9E03;
  --bs-primary-dark: #C98600;
  --bs-secondary: #989898;
  --bs-success: #64B375;
  --bs-info: #133C6B;
  --bs-warning: #C0AB77;
  --bs-danger: #E05C5C;
  --bs-light: #F9F7F4;
  --bs-dark: #1A1A1A;
  --bs-white: #fff;
  --bs-primary-rgb: 235, 158, 3;
  --bs-primary-dark-rgb: 201, 134, 0;
  --bs-secondary-rgb: 152, 152, 152;
  --bs-success-rgb: 100, 179, 117;
  --bs-info-rgb: 19, 60, 107;
  --bs-warning-rgb: 192, 171, 119;
  --bs-danger-rgb: 224, 92, 92;
  --bs-light-rgb: 249, 247, 244;
  --bs-dark-rgb: 26, 26, 26;
  --bs-white-rgb: 255, 255, 255;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Urbanist, Open Sans, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #000;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #ECE8DF;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 1.6rem;
  --bs-border-radius-sm: 0.8rem;
  --bs-border-radius-lg: 2.4rem;
  --bs-border-radius-xl: 3.2rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 5rem;
  --bs-link-color: #EB9E03;
  --bs-link-hover-color: #bc7e02;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: #F9F7F4;
  border: 0;
  border-top: 1px solid;
  opacity: 1; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.765rem + 6.18vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 6.4rem; } }

h2, .h2 {
  font-size: calc(1.445rem + 2.34vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 3.2rem; } }

h3, .h3 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 2rem; } }

h4, .h4 {
  font-size: 1.2rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--bs-link-color);
  text-decoration: underline; }
  a:hover {
    color: var(--bs-link-hover-color); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.8rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block, .dashboard-login #charte-employeur li:before, .dashboard .dashboard-title span {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .dashboard-login #charte-employeur li {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none, .dashboard .menu-chapitre .dropdown-toggle::after {
  display: none !important; }

.shadow {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05) !important; }

.shadow-sm {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.15) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute, .dashboard-login #formations_employeur .avancement {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-10 {
  top: 10% !important; }

.top-20 {
  top: 20% !important; }

.top-40 {
  top: 40% !important; }

.top-50 {
  top: 50% !important; }

.top-90 {
  top: 90% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-10 {
  bottom: 10% !important; }

.bottom-20 {
  bottom: 20% !important; }

.bottom-40 {
  bottom: 40% !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-90 {
  bottom: 90% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-10 {
  left: 10% !important; }

.start-20 {
  left: 20% !important; }

.start-40 {
  left: 40% !important; }

.start-50 {
  left: 50% !important; }

.start-90 {
  left: 90% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-10 {
  right: 10% !important; }

.end-20 {
  right: 20% !important; }

.end-40 {
  right: 40% !important; }

.end-50 {
  right: 50% !important; }

.end-90 {
  right: 90% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0, .dashboard .login input {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-primary-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-dark-rgb), var(--bs-border-opacity)) !important; }

.border-secondary, .dashboard-login #zone-quizz .quizz-40 .ui-droppable-hover {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-20 {
  width: 20% !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-70 {
  width: 70% !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100, table {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-20 {
  height: 20% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-70 {
  height: 70% !important; }

.h-75 {
  height: 75% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .dashboard-login #charte-employeur li {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-6 {
  margin: 0.6rem !important; }

.m-8 {
  margin: 0.8rem !important; }

.m-10 {
  margin: 1rem !important; }

.m-12 {
  margin: 1.2rem !important; }

.m-14 {
  margin: 1.4rem !important; }

.m-16 {
  margin: 1.6rem !important; }

.m-18 {
  margin: 1.8rem !important; }

.m-20 {
  margin: 2rem !important; }

.m-24 {
  margin: 2.4rem !important; }

.m-32 {
  margin: 3.2rem !important; }

.m-40 {
  margin: 4rem !important; }

.m-42 {
  margin: 4.2rem !important; }

.m-48 {
  margin: 4.8rem !important; }

.m-64 {
  margin: 6.4rem !important; }

.m-112 {
  margin: 11.2rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-6 {
  margin-right: 0.6rem !important;
  margin-left: 0.6rem !important; }

.mx-8 {
  margin-right: 0.8rem !important;
  margin-left: 0.8rem !important; }

.mx-10 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-12 {
  margin-right: 1.2rem !important;
  margin-left: 1.2rem !important; }

.mx-14 {
  margin-right: 1.4rem !important;
  margin-left: 1.4rem !important; }

.mx-16 {
  margin-right: 1.6rem !important;
  margin-left: 1.6rem !important; }

.mx-18 {
  margin-right: 1.8rem !important;
  margin-left: 1.8rem !important; }

.mx-20 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.mx-24 {
  margin-right: 2.4rem !important;
  margin-left: 2.4rem !important; }

.mx-32 {
  margin-right: 3.2rem !important;
  margin-left: 3.2rem !important; }

.mx-40 {
  margin-right: 4rem !important;
  margin-left: 4rem !important; }

.mx-42 {
  margin-right: 4.2rem !important;
  margin-left: 4.2rem !important; }

.mx-48 {
  margin-right: 4.8rem !important;
  margin-left: 4.8rem !important; }

.mx-64 {
  margin-right: 6.4rem !important;
  margin-left: 6.4rem !important; }

.mx-112 {
  margin-right: 11.2rem !important;
  margin-left: 11.2rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-6 {
  margin-top: 0.6rem !important;
  margin-bottom: 0.6rem !important; }

.my-8 {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important; }

.my-10 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-12 {
  margin-top: 1.2rem !important;
  margin-bottom: 1.2rem !important; }

.my-14 {
  margin-top: 1.4rem !important;
  margin-bottom: 1.4rem !important; }

.my-16 {
  margin-top: 1.6rem !important;
  margin-bottom: 1.6rem !important; }

.my-18 {
  margin-top: 1.8rem !important;
  margin-bottom: 1.8rem !important; }

.my-20 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.my-24 {
  margin-top: 2.4rem !important;
  margin-bottom: 2.4rem !important; }

.my-32 {
  margin-top: 3.2rem !important;
  margin-bottom: 3.2rem !important; }

.my-40 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.my-42 {
  margin-top: 4.2rem !important;
  margin-bottom: 4.2rem !important; }

.my-48 {
  margin-top: 4.8rem !important;
  margin-bottom: 4.8rem !important; }

.my-64 {
  margin-top: 6.4rem !important;
  margin-bottom: 6.4rem !important; }

.my-112 {
  margin-top: 11.2rem !important;
  margin-bottom: 11.2rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-6 {
  margin-top: 0.6rem !important; }

.mt-8 {
  margin-top: 0.8rem !important; }

.mt-10 {
  margin-top: 1rem !important; }

.mt-12 {
  margin-top: 1.2rem !important; }

.mt-14 {
  margin-top: 1.4rem !important; }

.mt-16 {
  margin-top: 1.6rem !important; }

.mt-18 {
  margin-top: 1.8rem !important; }

.mt-20 {
  margin-top: 2rem !important; }

.mt-24 {
  margin-top: 2.4rem !important; }

.mt-32 {
  margin-top: 3.2rem !important; }

.mt-40 {
  margin-top: 4rem !important; }

.mt-42 {
  margin-top: 4.2rem !important; }

.mt-48 {
  margin-top: 4.8rem !important; }

.mt-64 {
  margin-top: 6.4rem !important; }

.mt-112 {
  margin-top: 11.2rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-6 {
  margin-right: 0.6rem !important; }

.me-8 {
  margin-right: 0.8rem !important; }

.me-10 {
  margin-right: 1rem !important; }

.me-12 {
  margin-right: 1.2rem !important; }

.me-14 {
  margin-right: 1.4rem !important; }

.me-16 {
  margin-right: 1.6rem !important; }

.me-18 {
  margin-right: 1.8rem !important; }

.me-20 {
  margin-right: 2rem !important; }

.me-24 {
  margin-right: 2.4rem !important; }

.me-32 {
  margin-right: 3.2rem !important; }

.me-40 {
  margin-right: 4rem !important; }

.me-42 {
  margin-right: 4.2rem !important; }

.me-48 {
  margin-right: 4.8rem !important; }

.me-64 {
  margin-right: 6.4rem !important; }

.me-112 {
  margin-right: 11.2rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0, #offcanvas-push .informations h1, #offcanvas-push .informations .h1 {
  margin-bottom: 0 !important; }

.mb-6 {
  margin-bottom: 0.6rem !important; }

.mb-8 {
  margin-bottom: 0.8rem !important; }

.mb-10 {
  margin-bottom: 1rem !important; }

.mb-12 {
  margin-bottom: 1.2rem !important; }

.mb-14 {
  margin-bottom: 1.4rem !important; }

.mb-16 {
  margin-bottom: 1.6rem !important; }

.mb-18 {
  margin-bottom: 1.8rem !important; }

.mb-20 {
  margin-bottom: 2rem !important; }

.mb-24 {
  margin-bottom: 2.4rem !important; }

.mb-32 {
  margin-bottom: 3.2rem !important; }

.mb-40 {
  margin-bottom: 4rem !important; }

.mb-42 {
  margin-bottom: 4.2rem !important; }

.mb-48 {
  margin-bottom: 4.8rem !important; }

.mb-64 {
  margin-bottom: 6.4rem !important; }

.mb-112 {
  margin-bottom: 11.2rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-6 {
  margin-left: 0.6rem !important; }

.ms-8 {
  margin-left: 0.8rem !important; }

.ms-10 {
  margin-left: 1rem !important; }

.ms-12 {
  margin-left: 1.2rem !important; }

.ms-14 {
  margin-left: 1.4rem !important; }

.ms-16 {
  margin-left: 1.6rem !important; }

.ms-18 {
  margin-left: 1.8rem !important; }

.ms-20 {
  margin-left: 2rem !important; }

.ms-24 {
  margin-left: 2.4rem !important; }

.ms-32 {
  margin-left: 3.2rem !important; }

.ms-40 {
  margin-left: 4rem !important; }

.ms-42 {
  margin-left: 4.2rem !important; }

.ms-48 {
  margin-left: 4.8rem !important; }

.ms-64 {
  margin-left: 6.4rem !important; }

.ms-112 {
  margin-left: 11.2rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-6 {
  padding: 0.6rem !important; }

.p-8 {
  padding: 0.8rem !important; }

.p-10 {
  padding: 1rem !important; }

.p-12 {
  padding: 1.2rem !important; }

.p-14 {
  padding: 1.4rem !important; }

.p-16 {
  padding: 1.6rem !important; }

.p-18 {
  padding: 1.8rem !important; }

.p-20 {
  padding: 2rem !important; }

.p-24 {
  padding: 2.4rem !important; }

.p-32 {
  padding: 3.2rem !important; }

.p-40 {
  padding: 4rem !important; }

.p-42 {
  padding: 4.2rem !important; }

.p-48 {
  padding: 4.8rem !important; }

.p-64 {
  padding: 6.4rem !important; }

.p-112 {
  padding: 11.2rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-6 {
  padding-right: 0.6rem !important;
  padding-left: 0.6rem !important; }

.px-8 {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important; }

.px-10 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-12 {
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important; }

.px-14 {
  padding-right: 1.4rem !important;
  padding-left: 1.4rem !important; }

.px-16 {
  padding-right: 1.6rem !important;
  padding-left: 1.6rem !important; }

.px-18 {
  padding-right: 1.8rem !important;
  padding-left: 1.8rem !important; }

.px-20 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.px-24 {
  padding-right: 2.4rem !important;
  padding-left: 2.4rem !important; }

.px-32 {
  padding-right: 3.2rem !important;
  padding-left: 3.2rem !important; }

.px-40 {
  padding-right: 4rem !important;
  padding-left: 4rem !important; }

.px-42 {
  padding-right: 4.2rem !important;
  padding-left: 4.2rem !important; }

.px-48 {
  padding-right: 4.8rem !important;
  padding-left: 4.8rem !important; }

.px-64 {
  padding-right: 6.4rem !important;
  padding-left: 6.4rem !important; }

.px-112 {
  padding-right: 11.2rem !important;
  padding-left: 11.2rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-6 {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important; }

.py-8 {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important; }

.py-10 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-12 {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important; }

.py-14 {
  padding-top: 1.4rem !important;
  padding-bottom: 1.4rem !important; }

.py-16 {
  padding-top: 1.6rem !important;
  padding-bottom: 1.6rem !important; }

.py-18 {
  padding-top: 1.8rem !important;
  padding-bottom: 1.8rem !important; }

.py-20 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.py-24 {
  padding-top: 2.4rem !important;
  padding-bottom: 2.4rem !important; }

.py-32 {
  padding-top: 3.2rem !important;
  padding-bottom: 3.2rem !important; }

.py-40 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.py-42 {
  padding-top: 4.2rem !important;
  padding-bottom: 4.2rem !important; }

.py-48 {
  padding-top: 4.8rem !important;
  padding-bottom: 4.8rem !important; }

.py-64 {
  padding-top: 6.4rem !important;
  padding-bottom: 6.4rem !important; }

.py-112 {
  padding-top: 11.2rem !important;
  padding-bottom: 11.2rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-6 {
  padding-top: 0.6rem !important; }

.pt-8 {
  padding-top: 0.8rem !important; }

.pt-10 {
  padding-top: 1rem !important; }

.pt-12 {
  padding-top: 1.2rem !important; }

.pt-14 {
  padding-top: 1.4rem !important; }

.pt-16 {
  padding-top: 1.6rem !important; }

.pt-18 {
  padding-top: 1.8rem !important; }

.pt-20 {
  padding-top: 2rem !important; }

.pt-24 {
  padding-top: 2.4rem !important; }

.pt-32 {
  padding-top: 3.2rem !important; }

.pt-40 {
  padding-top: 4rem !important; }

.pt-42 {
  padding-top: 4.2rem !important; }

.pt-48 {
  padding-top: 4.8rem !important; }

.pt-64 {
  padding-top: 6.4rem !important; }

.pt-112 {
  padding-top: 11.2rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-6 {
  padding-right: 0.6rem !important; }

.pe-8 {
  padding-right: 0.8rem !important; }

.pe-10 {
  padding-right: 1rem !important; }

.pe-12 {
  padding-right: 1.2rem !important; }

.pe-14 {
  padding-right: 1.4rem !important; }

.pe-16 {
  padding-right: 1.6rem !important; }

.pe-18 {
  padding-right: 1.8rem !important; }

.pe-20 {
  padding-right: 2rem !important; }

.pe-24 {
  padding-right: 2.4rem !important; }

.pe-32 {
  padding-right: 3.2rem !important; }

.pe-40 {
  padding-right: 4rem !important; }

.pe-42 {
  padding-right: 4.2rem !important; }

.pe-48 {
  padding-right: 4.8rem !important; }

.pe-64 {
  padding-right: 6.4rem !important; }

.pe-112 {
  padding-right: 11.2rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-6 {
  padding-bottom: 0.6rem !important; }

.pb-8 {
  padding-bottom: 0.8rem !important; }

.pb-10 {
  padding-bottom: 1rem !important; }

.pb-12 {
  padding-bottom: 1.2rem !important; }

.pb-14 {
  padding-bottom: 1.4rem !important; }

.pb-16 {
  padding-bottom: 1.6rem !important; }

.pb-18 {
  padding-bottom: 1.8rem !important; }

.pb-20 {
  padding-bottom: 2rem !important; }

.pb-24 {
  padding-bottom: 2.4rem !important; }

.pb-32 {
  padding-bottom: 3.2rem !important; }

.pb-40 {
  padding-bottom: 4rem !important; }

.pb-42 {
  padding-bottom: 4.2rem !important; }

.pb-48 {
  padding-bottom: 4.8rem !important; }

.pb-64 {
  padding-bottom: 6.4rem !important; }

.pb-112 {
  padding-bottom: 11.2rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-6 {
  padding-left: 0.6rem !important; }

.ps-8 {
  padding-left: 0.8rem !important; }

.ps-10 {
  padding-left: 1rem !important; }

.ps-12 {
  padding-left: 1.2rem !important; }

.ps-14 {
  padding-left: 1.4rem !important; }

.ps-16 {
  padding-left: 1.6rem !important; }

.ps-18 {
  padding-left: 1.8rem !important; }

.ps-20 {
  padding-left: 2rem !important; }

.ps-24 {
  padding-left: 2.4rem !important; }

.ps-32 {
  padding-left: 3.2rem !important; }

.ps-40 {
  padding-left: 4rem !important; }

.ps-42 {
  padding-left: 4.2rem !important; }

.ps-48 {
  padding-left: 4.8rem !important; }

.ps-64 {
  padding-left: 6.4rem !important; }

.ps-112 {
  padding-left: 11.2rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-6 {
  gap: 0.6rem !important; }

.gap-8 {
  gap: 0.8rem !important; }

.gap-10 {
  gap: 1rem !important; }

.gap-12 {
  gap: 1.2rem !important; }

.gap-14 {
  gap: 1.4rem !important; }

.gap-16 {
  gap: 1.6rem !important; }

.gap-18 {
  gap: 1.8rem !important; }

.gap-20 {
  gap: 2rem !important; }

.gap-24 {
  gap: 2.4rem !important; }

.gap-32 {
  gap: 3.2rem !important; }

.gap-40 {
  gap: 4rem !important; }

.gap-42 {
  gap: 4.2rem !important; }

.gap-48 {
  gap: 4.8rem !important; }

.gap-64 {
  gap: 6.4rem !important; }

.gap-112 {
  gap: 11.2rem !important; }

.ff-1 {
  font-family: Urbanist, "Open Sans", sans-serif !important; }

.ff-2, .dashboard-login #zone-quizz .quizz-39 .draggable p,
.dashboard-login #zone-quizz .quizz-40 .draggable p {
  font-family: Inter, "Open Sans", sans-serif !important; }

.fs-10 {
  font-size: 1rem !important; }

.fs-12 {
  font-size: 1.2rem !important; }

.fs-14, .dashboard-login #zone-quizz .quizz-39 .draggable p,
.dashboard-login #zone-quizz .quizz-40 .draggable p {
  font-size: calc(1.265rem + 0.18vw) !important; }

.fs-16 {
  font-size: calc(1.285rem + 0.42vw) !important; }

.fs-18 {
  font-size: calc(1.305rem + 0.66vw) !important; }

.fs-20 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-24 {
  font-size: calc(1.365rem + 1.38vw) !important; }

.fs-28 {
  font-size: calc(1.405rem + 1.86vw) !important; }

.fs-32 {
  font-size: calc(1.445rem + 2.34vw) !important; }

.fs-64 {
  font-size: calc(1.765rem + 6.18vw) !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-semi-bold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.lh-0 {
  line-height: 0 !important; }

.lh-6 {
  line-height: 0.6rem !important; }

.lh-8 {
  line-height: 0.8rem !important; }

.lh-10 {
  line-height: 1rem !important; }

.lh-12 {
  line-height: 1.2rem !important; }

.lh-14 {
  line-height: 1.4rem !important; }

.lh-16 {
  line-height: 1.6rem !important; }

.lh-18 {
  line-height: 1.8rem !important; }

.lh-20 {
  line-height: 2rem !important; }

.lh-24 {
  line-height: 2.4rem !important; }

.lh-32 {
  line-height: 3.2rem !important; }

.lh-40 {
  line-height: 4rem !important; }

.lh-42 {
  line-height: 4.2rem !important; }

.lh-48 {
  line-height: 4.8rem !important; }

.lh-64 {
  line-height: 6.4rem !important; }

.lh-112 {
  line-height: 11.2rem !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center, #offcanvas-push .informations #mod_solde .card-body {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary, .dashboard .menu-chapitre .ss_chapitre.active, .dashboard .menu-chapitre .ss_chapitre:hover {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-primary-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-dark-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-black, .dashboard .menu-chapitre .ss_chapitre {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-primary-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 1.6rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.8rem !important; }

.rounded-2 {
  border-radius: 1.6rem !important; }

.rounded-3 {
  border-radius: 2.4rem !important; }

.rounded-4 {
  border-radius: 3.2rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 5rem !important; }

.rounded-top {
  border-top-left-radius: 1.6rem !important;
  border-top-right-radius: 1.6rem !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-top-1 {
  border-top-left-radius: 0.8rem !important;
  border-top-right-radius: 0.8rem !important; }

.rounded-top-2 {
  border-top-left-radius: 1.6rem !important;
  border-top-right-radius: 1.6rem !important; }

.rounded-top-3 {
  border-top-left-radius: 2.4rem !important;
  border-top-right-radius: 2.4rem !important; }

.rounded-top-4 {
  border-top-left-radius: 3.2rem !important;
  border-top-right-radius: 3.2rem !important; }

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important; }

.rounded-top-pill {
  border-top-left-radius: 5rem !important;
  border-top-right-radius: 5rem !important; }

.rounded-end {
  border-top-right-radius: 1.6rem !important;
  border-bottom-right-radius: 1.6rem !important; }

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-end-1 {
  border-top-right-radius: 0.8rem !important;
  border-bottom-right-radius: 0.8rem !important; }

.rounded-end-2 {
  border-top-right-radius: 1.6rem !important;
  border-bottom-right-radius: 1.6rem !important; }

.rounded-end-3 {
  border-top-right-radius: 2.4rem !important;
  border-bottom-right-radius: 2.4rem !important; }

.rounded-end-4 {
  border-top-right-radius: 3.2rem !important;
  border-bottom-right-radius: 3.2rem !important; }

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.rounded-end-pill {
  border-top-right-radius: 5rem !important;
  border-bottom-right-radius: 5rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 1.6rem !important;
  border-bottom-left-radius: 1.6rem !important; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-bottom-1 {
  border-bottom-right-radius: 0.8rem !important;
  border-bottom-left-radius: 0.8rem !important; }

.rounded-bottom-2 {
  border-bottom-right-radius: 1.6rem !important;
  border-bottom-left-radius: 1.6rem !important; }

.rounded-bottom-3 {
  border-bottom-right-radius: 2.4rem !important;
  border-bottom-left-radius: 2.4rem !important; }

.rounded-bottom-4 {
  border-bottom-right-radius: 3.2rem !important;
  border-bottom-left-radius: 3.2rem !important; }

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.rounded-bottom-pill {
  border-bottom-right-radius: 5rem !important;
  border-bottom-left-radius: 5rem !important; }

.rounded-start {
  border-bottom-left-radius: 1.6rem !important;
  border-top-left-radius: 1.6rem !important; }

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.rounded-start-1 {
  border-bottom-left-radius: 0.8rem !important;
  border-top-left-radius: 0.8rem !important; }

.rounded-start-2 {
  border-bottom-left-radius: 1.6rem !important;
  border-top-left-radius: 1.6rem !important; }

.rounded-start-3 {
  border-bottom-left-radius: 2.4rem !important;
  border-top-left-radius: 2.4rem !important; }

.rounded-start-4 {
  border-bottom-left-radius: 3.2rem !important;
  border-top-left-radius: 3.2rem !important; }

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important; }

.rounded-start-pill {
  border-bottom-left-radius: 5rem !important;
  border-top-left-radius: 5rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

.z-index-5 {
  z-index: 5 !important; }

.cr-pointer {
  cursor: pointer !important; }

.cr-help {
  cursor: help !important; }

.cr-wait {
  cursor: wait !important; }

.cr-not-allowed {
  cursor: not-allowed !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .position-sm-static {
    position: static !important; }
  .position-sm-relative {
    position: relative !important; }
  .position-sm-absolute {
    position: absolute !important; }
  .position-sm-fixed {
    position: fixed !important; }
  .position-sm-sticky {
    position: sticky !important; }
  .w-sm-20 {
    width: 20% !important; }
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-70 {
    width: 70% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-80 {
    width: 80% !important; }
  .w-sm-90 {
    width: 90% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .h-sm-20 {
    height: 20% !important; }
  .h-sm-25 {
    height: 25% !important; }
  .h-sm-50 {
    height: 50% !important; }
  .h-sm-70 {
    height: 70% !important; }
  .h-sm-75 {
    height: 75% !important; }
  .h-sm-80 {
    height: 80% !important; }
  .h-sm-90 {
    height: 90% !important; }
  .h-sm-100 {
    height: 100% !important; }
  .h-sm-auto {
    height: auto !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-6 {
    margin: 0.6rem !important; }
  .m-sm-8 {
    margin: 0.8rem !important; }
  .m-sm-10 {
    margin: 1rem !important; }
  .m-sm-12 {
    margin: 1.2rem !important; }
  .m-sm-14 {
    margin: 1.4rem !important; }
  .m-sm-16 {
    margin: 1.6rem !important; }
  .m-sm-18 {
    margin: 1.8rem !important; }
  .m-sm-20 {
    margin: 2rem !important; }
  .m-sm-24 {
    margin: 2.4rem !important; }
  .m-sm-32 {
    margin: 3.2rem !important; }
  .m-sm-40 {
    margin: 4rem !important; }
  .m-sm-42 {
    margin: 4.2rem !important; }
  .m-sm-48 {
    margin: 4.8rem !important; }
  .m-sm-64 {
    margin: 6.4rem !important; }
  .m-sm-112 {
    margin: 11.2rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-sm-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-sm-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-sm-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-sm-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-sm-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-sm-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-sm-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-sm-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-sm-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-sm-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-sm-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-sm-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-sm-112 {
    margin-right: 11.2rem !important;
    margin-left: 11.2rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-sm-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-sm-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-sm-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-sm-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-sm-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-sm-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-sm-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-sm-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-sm-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-sm-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-sm-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-sm-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-sm-112 {
    margin-top: 11.2rem !important;
    margin-bottom: 11.2rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-6 {
    margin-top: 0.6rem !important; }
  .mt-sm-8 {
    margin-top: 0.8rem !important; }
  .mt-sm-10 {
    margin-top: 1rem !important; }
  .mt-sm-12 {
    margin-top: 1.2rem !important; }
  .mt-sm-14 {
    margin-top: 1.4rem !important; }
  .mt-sm-16 {
    margin-top: 1.6rem !important; }
  .mt-sm-18 {
    margin-top: 1.8rem !important; }
  .mt-sm-20 {
    margin-top: 2rem !important; }
  .mt-sm-24 {
    margin-top: 2.4rem !important; }
  .mt-sm-32 {
    margin-top: 3.2rem !important; }
  .mt-sm-40 {
    margin-top: 4rem !important; }
  .mt-sm-42 {
    margin-top: 4.2rem !important; }
  .mt-sm-48 {
    margin-top: 4.8rem !important; }
  .mt-sm-64 {
    margin-top: 6.4rem !important; }
  .mt-sm-112 {
    margin-top: 11.2rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-6 {
    margin-right: 0.6rem !important; }
  .me-sm-8 {
    margin-right: 0.8rem !important; }
  .me-sm-10 {
    margin-right: 1rem !important; }
  .me-sm-12 {
    margin-right: 1.2rem !important; }
  .me-sm-14 {
    margin-right: 1.4rem !important; }
  .me-sm-16 {
    margin-right: 1.6rem !important; }
  .me-sm-18 {
    margin-right: 1.8rem !important; }
  .me-sm-20 {
    margin-right: 2rem !important; }
  .me-sm-24 {
    margin-right: 2.4rem !important; }
  .me-sm-32 {
    margin-right: 3.2rem !important; }
  .me-sm-40 {
    margin-right: 4rem !important; }
  .me-sm-42 {
    margin-right: 4.2rem !important; }
  .me-sm-48 {
    margin-right: 4.8rem !important; }
  .me-sm-64 {
    margin-right: 6.4rem !important; }
  .me-sm-112 {
    margin-right: 11.2rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-6 {
    margin-bottom: 0.6rem !important; }
  .mb-sm-8 {
    margin-bottom: 0.8rem !important; }
  .mb-sm-10 {
    margin-bottom: 1rem !important; }
  .mb-sm-12 {
    margin-bottom: 1.2rem !important; }
  .mb-sm-14 {
    margin-bottom: 1.4rem !important; }
  .mb-sm-16 {
    margin-bottom: 1.6rem !important; }
  .mb-sm-18 {
    margin-bottom: 1.8rem !important; }
  .mb-sm-20 {
    margin-bottom: 2rem !important; }
  .mb-sm-24 {
    margin-bottom: 2.4rem !important; }
  .mb-sm-32 {
    margin-bottom: 3.2rem !important; }
  .mb-sm-40 {
    margin-bottom: 4rem !important; }
  .mb-sm-42 {
    margin-bottom: 4.2rem !important; }
  .mb-sm-48 {
    margin-bottom: 4.8rem !important; }
  .mb-sm-64 {
    margin-bottom: 6.4rem !important; }
  .mb-sm-112 {
    margin-bottom: 11.2rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-6 {
    margin-left: 0.6rem !important; }
  .ms-sm-8 {
    margin-left: 0.8rem !important; }
  .ms-sm-10 {
    margin-left: 1rem !important; }
  .ms-sm-12 {
    margin-left: 1.2rem !important; }
  .ms-sm-14 {
    margin-left: 1.4rem !important; }
  .ms-sm-16 {
    margin-left: 1.6rem !important; }
  .ms-sm-18 {
    margin-left: 1.8rem !important; }
  .ms-sm-20 {
    margin-left: 2rem !important; }
  .ms-sm-24 {
    margin-left: 2.4rem !important; }
  .ms-sm-32 {
    margin-left: 3.2rem !important; }
  .ms-sm-40 {
    margin-left: 4rem !important; }
  .ms-sm-42 {
    margin-left: 4.2rem !important; }
  .ms-sm-48 {
    margin-left: 4.8rem !important; }
  .ms-sm-64 {
    margin-left: 6.4rem !important; }
  .ms-sm-112 {
    margin-left: 11.2rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-6 {
    padding: 0.6rem !important; }
  .p-sm-8 {
    padding: 0.8rem !important; }
  .p-sm-10 {
    padding: 1rem !important; }
  .p-sm-12 {
    padding: 1.2rem !important; }
  .p-sm-14 {
    padding: 1.4rem !important; }
  .p-sm-16 {
    padding: 1.6rem !important; }
  .p-sm-18 {
    padding: 1.8rem !important; }
  .p-sm-20 {
    padding: 2rem !important; }
  .p-sm-24 {
    padding: 2.4rem !important; }
  .p-sm-32 {
    padding: 3.2rem !important; }
  .p-sm-40 {
    padding: 4rem !important; }
  .p-sm-42 {
    padding: 4.2rem !important; }
  .p-sm-48 {
    padding: 4.8rem !important; }
  .p-sm-64 {
    padding: 6.4rem !important; }
  .p-sm-112 {
    padding: 11.2rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-sm-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-sm-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-sm-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-sm-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-sm-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-sm-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-sm-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-sm-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-sm-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-sm-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-sm-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-sm-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-sm-112 {
    padding-right: 11.2rem !important;
    padding-left: 11.2rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-sm-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-sm-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-sm-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-sm-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-sm-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-sm-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-sm-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-sm-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-sm-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-sm-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-sm-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-sm-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-sm-112 {
    padding-top: 11.2rem !important;
    padding-bottom: 11.2rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-6 {
    padding-top: 0.6rem !important; }
  .pt-sm-8 {
    padding-top: 0.8rem !important; }
  .pt-sm-10 {
    padding-top: 1rem !important; }
  .pt-sm-12 {
    padding-top: 1.2rem !important; }
  .pt-sm-14 {
    padding-top: 1.4rem !important; }
  .pt-sm-16 {
    padding-top: 1.6rem !important; }
  .pt-sm-18 {
    padding-top: 1.8rem !important; }
  .pt-sm-20 {
    padding-top: 2rem !important; }
  .pt-sm-24 {
    padding-top: 2.4rem !important; }
  .pt-sm-32 {
    padding-top: 3.2rem !important; }
  .pt-sm-40 {
    padding-top: 4rem !important; }
  .pt-sm-42 {
    padding-top: 4.2rem !important; }
  .pt-sm-48 {
    padding-top: 4.8rem !important; }
  .pt-sm-64 {
    padding-top: 6.4rem !important; }
  .pt-sm-112 {
    padding-top: 11.2rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-6 {
    padding-right: 0.6rem !important; }
  .pe-sm-8 {
    padding-right: 0.8rem !important; }
  .pe-sm-10 {
    padding-right: 1rem !important; }
  .pe-sm-12 {
    padding-right: 1.2rem !important; }
  .pe-sm-14 {
    padding-right: 1.4rem !important; }
  .pe-sm-16 {
    padding-right: 1.6rem !important; }
  .pe-sm-18 {
    padding-right: 1.8rem !important; }
  .pe-sm-20 {
    padding-right: 2rem !important; }
  .pe-sm-24 {
    padding-right: 2.4rem !important; }
  .pe-sm-32 {
    padding-right: 3.2rem !important; }
  .pe-sm-40 {
    padding-right: 4rem !important; }
  .pe-sm-42 {
    padding-right: 4.2rem !important; }
  .pe-sm-48 {
    padding-right: 4.8rem !important; }
  .pe-sm-64 {
    padding-right: 6.4rem !important; }
  .pe-sm-112 {
    padding-right: 11.2rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-6 {
    padding-bottom: 0.6rem !important; }
  .pb-sm-8 {
    padding-bottom: 0.8rem !important; }
  .pb-sm-10 {
    padding-bottom: 1rem !important; }
  .pb-sm-12 {
    padding-bottom: 1.2rem !important; }
  .pb-sm-14 {
    padding-bottom: 1.4rem !important; }
  .pb-sm-16 {
    padding-bottom: 1.6rem !important; }
  .pb-sm-18 {
    padding-bottom: 1.8rem !important; }
  .pb-sm-20 {
    padding-bottom: 2rem !important; }
  .pb-sm-24 {
    padding-bottom: 2.4rem !important; }
  .pb-sm-32 {
    padding-bottom: 3.2rem !important; }
  .pb-sm-40 {
    padding-bottom: 4rem !important; }
  .pb-sm-42 {
    padding-bottom: 4.2rem !important; }
  .pb-sm-48 {
    padding-bottom: 4.8rem !important; }
  .pb-sm-64 {
    padding-bottom: 6.4rem !important; }
  .pb-sm-112 {
    padding-bottom: 11.2rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-6 {
    padding-left: 0.6rem !important; }
  .ps-sm-8 {
    padding-left: 0.8rem !important; }
  .ps-sm-10 {
    padding-left: 1rem !important; }
  .ps-sm-12 {
    padding-left: 1.2rem !important; }
  .ps-sm-14 {
    padding-left: 1.4rem !important; }
  .ps-sm-16 {
    padding-left: 1.6rem !important; }
  .ps-sm-18 {
    padding-left: 1.8rem !important; }
  .ps-sm-20 {
    padding-left: 2rem !important; }
  .ps-sm-24 {
    padding-left: 2.4rem !important; }
  .ps-sm-32 {
    padding-left: 3.2rem !important; }
  .ps-sm-40 {
    padding-left: 4rem !important; }
  .ps-sm-42 {
    padding-left: 4.2rem !important; }
  .ps-sm-48 {
    padding-left: 4.8rem !important; }
  .ps-sm-64 {
    padding-left: 6.4rem !important; }
  .ps-sm-112 {
    padding-left: 11.2rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-6 {
    gap: 0.6rem !important; }
  .gap-sm-8 {
    gap: 0.8rem !important; }
  .gap-sm-10 {
    gap: 1rem !important; }
  .gap-sm-12 {
    gap: 1.2rem !important; }
  .gap-sm-14 {
    gap: 1.4rem !important; }
  .gap-sm-16 {
    gap: 1.6rem !important; }
  .gap-sm-18 {
    gap: 1.8rem !important; }
  .gap-sm-20 {
    gap: 2rem !important; }
  .gap-sm-24 {
    gap: 2.4rem !important; }
  .gap-sm-32 {
    gap: 3.2rem !important; }
  .gap-sm-40 {
    gap: 4rem !important; }
  .gap-sm-42 {
    gap: 4.2rem !important; }
  .gap-sm-48 {
    gap: 4.8rem !important; }
  .gap-sm-64 {
    gap: 6.4rem !important; }
  .gap-sm-112 {
    gap: 11.2rem !important; }
  .fs-sm-10 {
    font-size: 1rem !important; }
  .fs-sm-12 {
    font-size: 1.2rem !important; }
  .fs-sm-14 {
    font-size: calc(1.265rem + 0.18vw) !important; }
  .fs-sm-16 {
    font-size: calc(1.285rem + 0.42vw) !important; }
  .fs-sm-18 {
    font-size: calc(1.305rem + 0.66vw) !important; }
  .fs-sm-20 {
    font-size: calc(1.325rem + 0.9vw) !important; }
  .fs-sm-24 {
    font-size: calc(1.365rem + 1.38vw) !important; }
  .fs-sm-28 {
    font-size: calc(1.405rem + 1.86vw) !important; }
  .fs-sm-32 {
    font-size: calc(1.445rem + 2.34vw) !important; }
  .fs-sm-64 {
    font-size: calc(1.765rem + 6.18vw) !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .rounded-sm {
    border-radius: 1.6rem !important; }
  .rounded-sm-0 {
    border-radius: 0 !important; }
  .rounded-sm-1 {
    border-radius: 0.8rem !important; }
  .rounded-sm-2 {
    border-radius: 1.6rem !important; }
  .rounded-sm-3 {
    border-radius: 2.4rem !important; }
  .rounded-sm-4 {
    border-radius: 3.2rem !important; }
  .rounded-sm-circle {
    border-radius: 50% !important; }
  .rounded-sm-pill {
    border-radius: 5rem !important; }
  .rounded-top-sm {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-sm-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-sm-1 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-sm-2 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-sm-3 {
    border-top-left-radius: 2.4rem !important;
    border-top-right-radius: 2.4rem !important; }
  .rounded-top-sm-4 {
    border-top-left-radius: 3.2rem !important;
    border-top-right-radius: 3.2rem !important; }
  .rounded-top-sm-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-sm-pill {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important; }
  .rounded-end-sm {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-sm-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .rounded-end-sm-1 {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important; }
  .rounded-end-sm-2 {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-sm-3 {
    border-top-right-radius: 2.4rem !important;
    border-bottom-right-radius: 2.4rem !important; }
  .rounded-end-sm-4 {
    border-top-right-radius: 3.2rem !important;
    border-bottom-right-radius: 3.2rem !important; }
  .rounded-end-sm-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  .rounded-end-sm-pill {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important; }
  .rounded-bottom-sm {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-sm-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rounded-bottom-sm-1 {
    border-bottom-right-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important; }
  .rounded-bottom-sm-2 {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-sm-3 {
    border-bottom-right-radius: 2.4rem !important;
    border-bottom-left-radius: 2.4rem !important; }
  .rounded-bottom-sm-4 {
    border-bottom-right-radius: 3.2rem !important;
    border-bottom-left-radius: 3.2rem !important; }
  .rounded-bottom-sm-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  .rounded-bottom-sm-pill {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important; }
  .rounded-start-sm {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-sm-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }
  .rounded-start-sm-1 {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important; }
  .rounded-start-sm-2 {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-sm-3 {
    border-bottom-left-radius: 2.4rem !important;
    border-top-left-radius: 2.4rem !important; }
  .rounded-start-sm-4 {
    border-bottom-left-radius: 3.2rem !important;
    border-top-left-radius: 3.2rem !important; }
  .rounded-start-sm-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important; }
  .rounded-start-sm-pill {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important; }
  .z-index-sm-1 {
    z-index: 1 !important; }
  .z-index-sm-2 {
    z-index: 2 !important; }
  .z-index-sm-3 {
    z-index: 3 !important; }
  .z-index-sm-4 {
    z-index: 4 !important; }
  .z-index-sm-5 {
    z-index: 5 !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .position-md-static {
    position: static !important; }
  .position-md-relative {
    position: relative !important; }
  .position-md-absolute {
    position: absolute !important; }
  .position-md-fixed {
    position: fixed !important; }
  .position-md-sticky {
    position: sticky !important; }
  .w-md-20 {
    width: 20% !important; }
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-70 {
    width: 70% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-80 {
    width: 80% !important; }
  .w-md-90 {
    width: 90% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .h-md-20 {
    height: 20% !important; }
  .h-md-25 {
    height: 25% !important; }
  .h-md-50 {
    height: 50% !important; }
  .h-md-70 {
    height: 70% !important; }
  .h-md-75 {
    height: 75% !important; }
  .h-md-80 {
    height: 80% !important; }
  .h-md-90 {
    height: 90% !important; }
  .h-md-100 {
    height: 100% !important; }
  .h-md-auto {
    height: auto !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-6 {
    margin: 0.6rem !important; }
  .m-md-8 {
    margin: 0.8rem !important; }
  .m-md-10 {
    margin: 1rem !important; }
  .m-md-12 {
    margin: 1.2rem !important; }
  .m-md-14 {
    margin: 1.4rem !important; }
  .m-md-16 {
    margin: 1.6rem !important; }
  .m-md-18 {
    margin: 1.8rem !important; }
  .m-md-20 {
    margin: 2rem !important; }
  .m-md-24 {
    margin: 2.4rem !important; }
  .m-md-32 {
    margin: 3.2rem !important; }
  .m-md-40 {
    margin: 4rem !important; }
  .m-md-42 {
    margin: 4.2rem !important; }
  .m-md-48 {
    margin: 4.8rem !important; }
  .m-md-64 {
    margin: 6.4rem !important; }
  .m-md-112 {
    margin: 11.2rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-md-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-md-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-md-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-md-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-md-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-md-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-md-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-md-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-md-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-md-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-md-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-md-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-md-112 {
    margin-right: 11.2rem !important;
    margin-left: 11.2rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-md-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-md-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-md-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-md-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-md-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-md-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-md-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-md-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-md-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-md-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-md-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-md-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-md-112 {
    margin-top: 11.2rem !important;
    margin-bottom: 11.2rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-6 {
    margin-top: 0.6rem !important; }
  .mt-md-8 {
    margin-top: 0.8rem !important; }
  .mt-md-10 {
    margin-top: 1rem !important; }
  .mt-md-12 {
    margin-top: 1.2rem !important; }
  .mt-md-14 {
    margin-top: 1.4rem !important; }
  .mt-md-16 {
    margin-top: 1.6rem !important; }
  .mt-md-18 {
    margin-top: 1.8rem !important; }
  .mt-md-20 {
    margin-top: 2rem !important; }
  .mt-md-24 {
    margin-top: 2.4rem !important; }
  .mt-md-32 {
    margin-top: 3.2rem !important; }
  .mt-md-40 {
    margin-top: 4rem !important; }
  .mt-md-42 {
    margin-top: 4.2rem !important; }
  .mt-md-48 {
    margin-top: 4.8rem !important; }
  .mt-md-64 {
    margin-top: 6.4rem !important; }
  .mt-md-112 {
    margin-top: 11.2rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-6 {
    margin-right: 0.6rem !important; }
  .me-md-8 {
    margin-right: 0.8rem !important; }
  .me-md-10 {
    margin-right: 1rem !important; }
  .me-md-12 {
    margin-right: 1.2rem !important; }
  .me-md-14 {
    margin-right: 1.4rem !important; }
  .me-md-16 {
    margin-right: 1.6rem !important; }
  .me-md-18 {
    margin-right: 1.8rem !important; }
  .me-md-20 {
    margin-right: 2rem !important; }
  .me-md-24 {
    margin-right: 2.4rem !important; }
  .me-md-32 {
    margin-right: 3.2rem !important; }
  .me-md-40 {
    margin-right: 4rem !important; }
  .me-md-42 {
    margin-right: 4.2rem !important; }
  .me-md-48 {
    margin-right: 4.8rem !important; }
  .me-md-64 {
    margin-right: 6.4rem !important; }
  .me-md-112 {
    margin-right: 11.2rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-6 {
    margin-bottom: 0.6rem !important; }
  .mb-md-8 {
    margin-bottom: 0.8rem !important; }
  .mb-md-10 {
    margin-bottom: 1rem !important; }
  .mb-md-12 {
    margin-bottom: 1.2rem !important; }
  .mb-md-14 {
    margin-bottom: 1.4rem !important; }
  .mb-md-16 {
    margin-bottom: 1.6rem !important; }
  .mb-md-18 {
    margin-bottom: 1.8rem !important; }
  .mb-md-20 {
    margin-bottom: 2rem !important; }
  .mb-md-24 {
    margin-bottom: 2.4rem !important; }
  .mb-md-32 {
    margin-bottom: 3.2rem !important; }
  .mb-md-40 {
    margin-bottom: 4rem !important; }
  .mb-md-42 {
    margin-bottom: 4.2rem !important; }
  .mb-md-48 {
    margin-bottom: 4.8rem !important; }
  .mb-md-64 {
    margin-bottom: 6.4rem !important; }
  .mb-md-112 {
    margin-bottom: 11.2rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-6 {
    margin-left: 0.6rem !important; }
  .ms-md-8 {
    margin-left: 0.8rem !important; }
  .ms-md-10 {
    margin-left: 1rem !important; }
  .ms-md-12 {
    margin-left: 1.2rem !important; }
  .ms-md-14 {
    margin-left: 1.4rem !important; }
  .ms-md-16 {
    margin-left: 1.6rem !important; }
  .ms-md-18 {
    margin-left: 1.8rem !important; }
  .ms-md-20 {
    margin-left: 2rem !important; }
  .ms-md-24 {
    margin-left: 2.4rem !important; }
  .ms-md-32 {
    margin-left: 3.2rem !important; }
  .ms-md-40 {
    margin-left: 4rem !important; }
  .ms-md-42 {
    margin-left: 4.2rem !important; }
  .ms-md-48 {
    margin-left: 4.8rem !important; }
  .ms-md-64 {
    margin-left: 6.4rem !important; }
  .ms-md-112 {
    margin-left: 11.2rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-6 {
    padding: 0.6rem !important; }
  .p-md-8 {
    padding: 0.8rem !important; }
  .p-md-10 {
    padding: 1rem !important; }
  .p-md-12 {
    padding: 1.2rem !important; }
  .p-md-14 {
    padding: 1.4rem !important; }
  .p-md-16 {
    padding: 1.6rem !important; }
  .p-md-18 {
    padding: 1.8rem !important; }
  .p-md-20 {
    padding: 2rem !important; }
  .p-md-24 {
    padding: 2.4rem !important; }
  .p-md-32 {
    padding: 3.2rem !important; }
  .p-md-40 {
    padding: 4rem !important; }
  .p-md-42 {
    padding: 4.2rem !important; }
  .p-md-48 {
    padding: 4.8rem !important; }
  .p-md-64 {
    padding: 6.4rem !important; }
  .p-md-112 {
    padding: 11.2rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-md-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-md-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-md-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-md-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-md-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-md-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-md-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-md-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-md-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-md-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-md-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-md-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-md-112 {
    padding-right: 11.2rem !important;
    padding-left: 11.2rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-md-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-md-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-md-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-md-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-md-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-md-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-md-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-md-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-md-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-md-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-md-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-md-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-md-112 {
    padding-top: 11.2rem !important;
    padding-bottom: 11.2rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-6 {
    padding-top: 0.6rem !important; }
  .pt-md-8 {
    padding-top: 0.8rem !important; }
  .pt-md-10 {
    padding-top: 1rem !important; }
  .pt-md-12 {
    padding-top: 1.2rem !important; }
  .pt-md-14 {
    padding-top: 1.4rem !important; }
  .pt-md-16 {
    padding-top: 1.6rem !important; }
  .pt-md-18 {
    padding-top: 1.8rem !important; }
  .pt-md-20 {
    padding-top: 2rem !important; }
  .pt-md-24 {
    padding-top: 2.4rem !important; }
  .pt-md-32 {
    padding-top: 3.2rem !important; }
  .pt-md-40 {
    padding-top: 4rem !important; }
  .pt-md-42 {
    padding-top: 4.2rem !important; }
  .pt-md-48 {
    padding-top: 4.8rem !important; }
  .pt-md-64 {
    padding-top: 6.4rem !important; }
  .pt-md-112 {
    padding-top: 11.2rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-6 {
    padding-right: 0.6rem !important; }
  .pe-md-8 {
    padding-right: 0.8rem !important; }
  .pe-md-10 {
    padding-right: 1rem !important; }
  .pe-md-12 {
    padding-right: 1.2rem !important; }
  .pe-md-14 {
    padding-right: 1.4rem !important; }
  .pe-md-16 {
    padding-right: 1.6rem !important; }
  .pe-md-18 {
    padding-right: 1.8rem !important; }
  .pe-md-20 {
    padding-right: 2rem !important; }
  .pe-md-24 {
    padding-right: 2.4rem !important; }
  .pe-md-32 {
    padding-right: 3.2rem !important; }
  .pe-md-40 {
    padding-right: 4rem !important; }
  .pe-md-42 {
    padding-right: 4.2rem !important; }
  .pe-md-48 {
    padding-right: 4.8rem !important; }
  .pe-md-64 {
    padding-right: 6.4rem !important; }
  .pe-md-112 {
    padding-right: 11.2rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-6 {
    padding-bottom: 0.6rem !important; }
  .pb-md-8 {
    padding-bottom: 0.8rem !important; }
  .pb-md-10 {
    padding-bottom: 1rem !important; }
  .pb-md-12 {
    padding-bottom: 1.2rem !important; }
  .pb-md-14 {
    padding-bottom: 1.4rem !important; }
  .pb-md-16 {
    padding-bottom: 1.6rem !important; }
  .pb-md-18 {
    padding-bottom: 1.8rem !important; }
  .pb-md-20 {
    padding-bottom: 2rem !important; }
  .pb-md-24 {
    padding-bottom: 2.4rem !important; }
  .pb-md-32 {
    padding-bottom: 3.2rem !important; }
  .pb-md-40 {
    padding-bottom: 4rem !important; }
  .pb-md-42 {
    padding-bottom: 4.2rem !important; }
  .pb-md-48 {
    padding-bottom: 4.8rem !important; }
  .pb-md-64 {
    padding-bottom: 6.4rem !important; }
  .pb-md-112 {
    padding-bottom: 11.2rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-6 {
    padding-left: 0.6rem !important; }
  .ps-md-8 {
    padding-left: 0.8rem !important; }
  .ps-md-10 {
    padding-left: 1rem !important; }
  .ps-md-12 {
    padding-left: 1.2rem !important; }
  .ps-md-14 {
    padding-left: 1.4rem !important; }
  .ps-md-16 {
    padding-left: 1.6rem !important; }
  .ps-md-18 {
    padding-left: 1.8rem !important; }
  .ps-md-20 {
    padding-left: 2rem !important; }
  .ps-md-24 {
    padding-left: 2.4rem !important; }
  .ps-md-32 {
    padding-left: 3.2rem !important; }
  .ps-md-40 {
    padding-left: 4rem !important; }
  .ps-md-42 {
    padding-left: 4.2rem !important; }
  .ps-md-48 {
    padding-left: 4.8rem !important; }
  .ps-md-64 {
    padding-left: 6.4rem !important; }
  .ps-md-112 {
    padding-left: 11.2rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-6 {
    gap: 0.6rem !important; }
  .gap-md-8 {
    gap: 0.8rem !important; }
  .gap-md-10 {
    gap: 1rem !important; }
  .gap-md-12 {
    gap: 1.2rem !important; }
  .gap-md-14 {
    gap: 1.4rem !important; }
  .gap-md-16 {
    gap: 1.6rem !important; }
  .gap-md-18 {
    gap: 1.8rem !important; }
  .gap-md-20 {
    gap: 2rem !important; }
  .gap-md-24 {
    gap: 2.4rem !important; }
  .gap-md-32 {
    gap: 3.2rem !important; }
  .gap-md-40 {
    gap: 4rem !important; }
  .gap-md-42 {
    gap: 4.2rem !important; }
  .gap-md-48 {
    gap: 4.8rem !important; }
  .gap-md-64 {
    gap: 6.4rem !important; }
  .gap-md-112 {
    gap: 11.2rem !important; }
  .fs-md-10 {
    font-size: 1rem !important; }
  .fs-md-12 {
    font-size: 1.2rem !important; }
  .fs-md-14 {
    font-size: calc(1.265rem + 0.18vw) !important; }
  .fs-md-16 {
    font-size: calc(1.285rem + 0.42vw) !important; }
  .fs-md-18 {
    font-size: calc(1.305rem + 0.66vw) !important; }
  .fs-md-20 {
    font-size: calc(1.325rem + 0.9vw) !important; }
  .fs-md-24 {
    font-size: calc(1.365rem + 1.38vw) !important; }
  .fs-md-28 {
    font-size: calc(1.405rem + 1.86vw) !important; }
  .fs-md-32 {
    font-size: calc(1.445rem + 2.34vw) !important; }
  .fs-md-64 {
    font-size: calc(1.765rem + 6.18vw) !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .rounded-md {
    border-radius: 1.6rem !important; }
  .rounded-md-0 {
    border-radius: 0 !important; }
  .rounded-md-1 {
    border-radius: 0.8rem !important; }
  .rounded-md-2 {
    border-radius: 1.6rem !important; }
  .rounded-md-3 {
    border-radius: 2.4rem !important; }
  .rounded-md-4 {
    border-radius: 3.2rem !important; }
  .rounded-md-circle {
    border-radius: 50% !important; }
  .rounded-md-pill {
    border-radius: 5rem !important; }
  .rounded-top-md {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-md-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-md-1 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-md-2 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-md-3 {
    border-top-left-radius: 2.4rem !important;
    border-top-right-radius: 2.4rem !important; }
  .rounded-top-md-4 {
    border-top-left-radius: 3.2rem !important;
    border-top-right-radius: 3.2rem !important; }
  .rounded-top-md-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-md-pill {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important; }
  .rounded-end-md {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-md-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .rounded-end-md-1 {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important; }
  .rounded-end-md-2 {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-md-3 {
    border-top-right-radius: 2.4rem !important;
    border-bottom-right-radius: 2.4rem !important; }
  .rounded-end-md-4 {
    border-top-right-radius: 3.2rem !important;
    border-bottom-right-radius: 3.2rem !important; }
  .rounded-end-md-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  .rounded-end-md-pill {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important; }
  .rounded-bottom-md {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-md-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rounded-bottom-md-1 {
    border-bottom-right-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important; }
  .rounded-bottom-md-2 {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-md-3 {
    border-bottom-right-radius: 2.4rem !important;
    border-bottom-left-radius: 2.4rem !important; }
  .rounded-bottom-md-4 {
    border-bottom-right-radius: 3.2rem !important;
    border-bottom-left-radius: 3.2rem !important; }
  .rounded-bottom-md-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  .rounded-bottom-md-pill {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important; }
  .rounded-start-md {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-md-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }
  .rounded-start-md-1 {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important; }
  .rounded-start-md-2 {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-md-3 {
    border-bottom-left-radius: 2.4rem !important;
    border-top-left-radius: 2.4rem !important; }
  .rounded-start-md-4 {
    border-bottom-left-radius: 3.2rem !important;
    border-top-left-radius: 3.2rem !important; }
  .rounded-start-md-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important; }
  .rounded-start-md-pill {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important; }
  .z-index-md-1 {
    z-index: 1 !important; }
  .z-index-md-2 {
    z-index: 2 !important; }
  .z-index-md-3 {
    z-index: 3 !important; }
  .z-index-md-4 {
    z-index: 4 !important; }
  .z-index-md-5 {
    z-index: 5 !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .position-lg-static {
    position: static !important; }
  .position-lg-relative {
    position: relative !important; }
  .position-lg-absolute {
    position: absolute !important; }
  .position-lg-fixed {
    position: fixed !important; }
  .position-lg-sticky {
    position: sticky !important; }
  .w-lg-20 {
    width: 20% !important; }
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-70 {
    width: 70% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-80 {
    width: 80% !important; }
  .w-lg-90 {
    width: 90% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .h-lg-20 {
    height: 20% !important; }
  .h-lg-25 {
    height: 25% !important; }
  .h-lg-50 {
    height: 50% !important; }
  .h-lg-70 {
    height: 70% !important; }
  .h-lg-75 {
    height: 75% !important; }
  .h-lg-80 {
    height: 80% !important; }
  .h-lg-90 {
    height: 90% !important; }
  .h-lg-100 {
    height: 100% !important; }
  .h-lg-auto {
    height: auto !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-6 {
    margin: 0.6rem !important; }
  .m-lg-8 {
    margin: 0.8rem !important; }
  .m-lg-10 {
    margin: 1rem !important; }
  .m-lg-12 {
    margin: 1.2rem !important; }
  .m-lg-14 {
    margin: 1.4rem !important; }
  .m-lg-16 {
    margin: 1.6rem !important; }
  .m-lg-18 {
    margin: 1.8rem !important; }
  .m-lg-20 {
    margin: 2rem !important; }
  .m-lg-24 {
    margin: 2.4rem !important; }
  .m-lg-32 {
    margin: 3.2rem !important; }
  .m-lg-40 {
    margin: 4rem !important; }
  .m-lg-42 {
    margin: 4.2rem !important; }
  .m-lg-48 {
    margin: 4.8rem !important; }
  .m-lg-64 {
    margin: 6.4rem !important; }
  .m-lg-112 {
    margin: 11.2rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-lg-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-lg-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-lg-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-lg-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-lg-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-lg-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-lg-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-lg-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-lg-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-lg-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-lg-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-lg-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-lg-112 {
    margin-right: 11.2rem !important;
    margin-left: 11.2rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-lg-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-lg-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-lg-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-lg-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-lg-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-lg-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-lg-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-lg-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-lg-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-lg-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-lg-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-lg-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-lg-112 {
    margin-top: 11.2rem !important;
    margin-bottom: 11.2rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-6 {
    margin-top: 0.6rem !important; }
  .mt-lg-8 {
    margin-top: 0.8rem !important; }
  .mt-lg-10 {
    margin-top: 1rem !important; }
  .mt-lg-12 {
    margin-top: 1.2rem !important; }
  .mt-lg-14 {
    margin-top: 1.4rem !important; }
  .mt-lg-16 {
    margin-top: 1.6rem !important; }
  .mt-lg-18 {
    margin-top: 1.8rem !important; }
  .mt-lg-20 {
    margin-top: 2rem !important; }
  .mt-lg-24 {
    margin-top: 2.4rem !important; }
  .mt-lg-32 {
    margin-top: 3.2rem !important; }
  .mt-lg-40 {
    margin-top: 4rem !important; }
  .mt-lg-42 {
    margin-top: 4.2rem !important; }
  .mt-lg-48 {
    margin-top: 4.8rem !important; }
  .mt-lg-64 {
    margin-top: 6.4rem !important; }
  .mt-lg-112 {
    margin-top: 11.2rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-6 {
    margin-right: 0.6rem !important; }
  .me-lg-8 {
    margin-right: 0.8rem !important; }
  .me-lg-10 {
    margin-right: 1rem !important; }
  .me-lg-12 {
    margin-right: 1.2rem !important; }
  .me-lg-14 {
    margin-right: 1.4rem !important; }
  .me-lg-16 {
    margin-right: 1.6rem !important; }
  .me-lg-18 {
    margin-right: 1.8rem !important; }
  .me-lg-20 {
    margin-right: 2rem !important; }
  .me-lg-24 {
    margin-right: 2.4rem !important; }
  .me-lg-32 {
    margin-right: 3.2rem !important; }
  .me-lg-40 {
    margin-right: 4rem !important; }
  .me-lg-42 {
    margin-right: 4.2rem !important; }
  .me-lg-48 {
    margin-right: 4.8rem !important; }
  .me-lg-64 {
    margin-right: 6.4rem !important; }
  .me-lg-112 {
    margin-right: 11.2rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-6 {
    margin-bottom: 0.6rem !important; }
  .mb-lg-8 {
    margin-bottom: 0.8rem !important; }
  .mb-lg-10 {
    margin-bottom: 1rem !important; }
  .mb-lg-12 {
    margin-bottom: 1.2rem !important; }
  .mb-lg-14 {
    margin-bottom: 1.4rem !important; }
  .mb-lg-16 {
    margin-bottom: 1.6rem !important; }
  .mb-lg-18 {
    margin-bottom: 1.8rem !important; }
  .mb-lg-20 {
    margin-bottom: 2rem !important; }
  .mb-lg-24 {
    margin-bottom: 2.4rem !important; }
  .mb-lg-32 {
    margin-bottom: 3.2rem !important; }
  .mb-lg-40 {
    margin-bottom: 4rem !important; }
  .mb-lg-42 {
    margin-bottom: 4.2rem !important; }
  .mb-lg-48 {
    margin-bottom: 4.8rem !important; }
  .mb-lg-64 {
    margin-bottom: 6.4rem !important; }
  .mb-lg-112 {
    margin-bottom: 11.2rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-6 {
    margin-left: 0.6rem !important; }
  .ms-lg-8 {
    margin-left: 0.8rem !important; }
  .ms-lg-10 {
    margin-left: 1rem !important; }
  .ms-lg-12 {
    margin-left: 1.2rem !important; }
  .ms-lg-14 {
    margin-left: 1.4rem !important; }
  .ms-lg-16 {
    margin-left: 1.6rem !important; }
  .ms-lg-18 {
    margin-left: 1.8rem !important; }
  .ms-lg-20 {
    margin-left: 2rem !important; }
  .ms-lg-24 {
    margin-left: 2.4rem !important; }
  .ms-lg-32 {
    margin-left: 3.2rem !important; }
  .ms-lg-40 {
    margin-left: 4rem !important; }
  .ms-lg-42 {
    margin-left: 4.2rem !important; }
  .ms-lg-48 {
    margin-left: 4.8rem !important; }
  .ms-lg-64 {
    margin-left: 6.4rem !important; }
  .ms-lg-112 {
    margin-left: 11.2rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-6 {
    padding: 0.6rem !important; }
  .p-lg-8 {
    padding: 0.8rem !important; }
  .p-lg-10 {
    padding: 1rem !important; }
  .p-lg-12 {
    padding: 1.2rem !important; }
  .p-lg-14 {
    padding: 1.4rem !important; }
  .p-lg-16 {
    padding: 1.6rem !important; }
  .p-lg-18 {
    padding: 1.8rem !important; }
  .p-lg-20 {
    padding: 2rem !important; }
  .p-lg-24 {
    padding: 2.4rem !important; }
  .p-lg-32 {
    padding: 3.2rem !important; }
  .p-lg-40 {
    padding: 4rem !important; }
  .p-lg-42 {
    padding: 4.2rem !important; }
  .p-lg-48 {
    padding: 4.8rem !important; }
  .p-lg-64 {
    padding: 6.4rem !important; }
  .p-lg-112 {
    padding: 11.2rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-lg-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-lg-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-lg-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-lg-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-lg-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-lg-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-lg-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-lg-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-lg-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-lg-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-lg-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-lg-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-lg-112 {
    padding-right: 11.2rem !important;
    padding-left: 11.2rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-lg-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-lg-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-lg-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-lg-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-lg-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-lg-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-lg-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-lg-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-lg-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-lg-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-lg-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-lg-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-lg-112 {
    padding-top: 11.2rem !important;
    padding-bottom: 11.2rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-6 {
    padding-top: 0.6rem !important; }
  .pt-lg-8 {
    padding-top: 0.8rem !important; }
  .pt-lg-10 {
    padding-top: 1rem !important; }
  .pt-lg-12 {
    padding-top: 1.2rem !important; }
  .pt-lg-14 {
    padding-top: 1.4rem !important; }
  .pt-lg-16 {
    padding-top: 1.6rem !important; }
  .pt-lg-18 {
    padding-top: 1.8rem !important; }
  .pt-lg-20 {
    padding-top: 2rem !important; }
  .pt-lg-24 {
    padding-top: 2.4rem !important; }
  .pt-lg-32 {
    padding-top: 3.2rem !important; }
  .pt-lg-40 {
    padding-top: 4rem !important; }
  .pt-lg-42 {
    padding-top: 4.2rem !important; }
  .pt-lg-48 {
    padding-top: 4.8rem !important; }
  .pt-lg-64 {
    padding-top: 6.4rem !important; }
  .pt-lg-112 {
    padding-top: 11.2rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-6 {
    padding-right: 0.6rem !important; }
  .pe-lg-8 {
    padding-right: 0.8rem !important; }
  .pe-lg-10 {
    padding-right: 1rem !important; }
  .pe-lg-12 {
    padding-right: 1.2rem !important; }
  .pe-lg-14 {
    padding-right: 1.4rem !important; }
  .pe-lg-16 {
    padding-right: 1.6rem !important; }
  .pe-lg-18 {
    padding-right: 1.8rem !important; }
  .pe-lg-20 {
    padding-right: 2rem !important; }
  .pe-lg-24 {
    padding-right: 2.4rem !important; }
  .pe-lg-32 {
    padding-right: 3.2rem !important; }
  .pe-lg-40 {
    padding-right: 4rem !important; }
  .pe-lg-42 {
    padding-right: 4.2rem !important; }
  .pe-lg-48 {
    padding-right: 4.8rem !important; }
  .pe-lg-64 {
    padding-right: 6.4rem !important; }
  .pe-lg-112 {
    padding-right: 11.2rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-6 {
    padding-bottom: 0.6rem !important; }
  .pb-lg-8 {
    padding-bottom: 0.8rem !important; }
  .pb-lg-10 {
    padding-bottom: 1rem !important; }
  .pb-lg-12 {
    padding-bottom: 1.2rem !important; }
  .pb-lg-14 {
    padding-bottom: 1.4rem !important; }
  .pb-lg-16 {
    padding-bottom: 1.6rem !important; }
  .pb-lg-18 {
    padding-bottom: 1.8rem !important; }
  .pb-lg-20 {
    padding-bottom: 2rem !important; }
  .pb-lg-24 {
    padding-bottom: 2.4rem !important; }
  .pb-lg-32 {
    padding-bottom: 3.2rem !important; }
  .pb-lg-40 {
    padding-bottom: 4rem !important; }
  .pb-lg-42 {
    padding-bottom: 4.2rem !important; }
  .pb-lg-48 {
    padding-bottom: 4.8rem !important; }
  .pb-lg-64 {
    padding-bottom: 6.4rem !important; }
  .pb-lg-112 {
    padding-bottom: 11.2rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-6 {
    padding-left: 0.6rem !important; }
  .ps-lg-8 {
    padding-left: 0.8rem !important; }
  .ps-lg-10 {
    padding-left: 1rem !important; }
  .ps-lg-12 {
    padding-left: 1.2rem !important; }
  .ps-lg-14 {
    padding-left: 1.4rem !important; }
  .ps-lg-16 {
    padding-left: 1.6rem !important; }
  .ps-lg-18 {
    padding-left: 1.8rem !important; }
  .ps-lg-20 {
    padding-left: 2rem !important; }
  .ps-lg-24 {
    padding-left: 2.4rem !important; }
  .ps-lg-32 {
    padding-left: 3.2rem !important; }
  .ps-lg-40 {
    padding-left: 4rem !important; }
  .ps-lg-42 {
    padding-left: 4.2rem !important; }
  .ps-lg-48 {
    padding-left: 4.8rem !important; }
  .ps-lg-64 {
    padding-left: 6.4rem !important; }
  .ps-lg-112 {
    padding-left: 11.2rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-6 {
    gap: 0.6rem !important; }
  .gap-lg-8 {
    gap: 0.8rem !important; }
  .gap-lg-10 {
    gap: 1rem !important; }
  .gap-lg-12 {
    gap: 1.2rem !important; }
  .gap-lg-14 {
    gap: 1.4rem !important; }
  .gap-lg-16 {
    gap: 1.6rem !important; }
  .gap-lg-18 {
    gap: 1.8rem !important; }
  .gap-lg-20 {
    gap: 2rem !important; }
  .gap-lg-24 {
    gap: 2.4rem !important; }
  .gap-lg-32 {
    gap: 3.2rem !important; }
  .gap-lg-40 {
    gap: 4rem !important; }
  .gap-lg-42 {
    gap: 4.2rem !important; }
  .gap-lg-48 {
    gap: 4.8rem !important; }
  .gap-lg-64 {
    gap: 6.4rem !important; }
  .gap-lg-112 {
    gap: 11.2rem !important; }
  .fs-lg-10 {
    font-size: 1rem !important; }
  .fs-lg-12 {
    font-size: 1.2rem !important; }
  .fs-lg-14 {
    font-size: calc(1.265rem + 0.18vw) !important; }
  .fs-lg-16 {
    font-size: calc(1.285rem + 0.42vw) !important; }
  .fs-lg-18 {
    font-size: calc(1.305rem + 0.66vw) !important; }
  .fs-lg-20 {
    font-size: calc(1.325rem + 0.9vw) !important; }
  .fs-lg-24 {
    font-size: calc(1.365rem + 1.38vw) !important; }
  .fs-lg-28 {
    font-size: calc(1.405rem + 1.86vw) !important; }
  .fs-lg-32 {
    font-size: calc(1.445rem + 2.34vw) !important; }
  .fs-lg-64 {
    font-size: calc(1.765rem + 6.18vw) !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .rounded-lg {
    border-radius: 1.6rem !important; }
  .rounded-lg-0 {
    border-radius: 0 !important; }
  .rounded-lg-1 {
    border-radius: 0.8rem !important; }
  .rounded-lg-2 {
    border-radius: 1.6rem !important; }
  .rounded-lg-3 {
    border-radius: 2.4rem !important; }
  .rounded-lg-4 {
    border-radius: 3.2rem !important; }
  .rounded-lg-circle {
    border-radius: 50% !important; }
  .rounded-lg-pill {
    border-radius: 5rem !important; }
  .rounded-top-lg {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-lg-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-lg-1 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-lg-2 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-lg-3 {
    border-top-left-radius: 2.4rem !important;
    border-top-right-radius: 2.4rem !important; }
  .rounded-top-lg-4 {
    border-top-left-radius: 3.2rem !important;
    border-top-right-radius: 3.2rem !important; }
  .rounded-top-lg-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-lg-pill {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important; }
  .rounded-end-lg {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-lg-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .rounded-end-lg-1 {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important; }
  .rounded-end-lg-2 {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-lg-3 {
    border-top-right-radius: 2.4rem !important;
    border-bottom-right-radius: 2.4rem !important; }
  .rounded-end-lg-4 {
    border-top-right-radius: 3.2rem !important;
    border-bottom-right-radius: 3.2rem !important; }
  .rounded-end-lg-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  .rounded-end-lg-pill {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important; }
  .rounded-bottom-lg {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-lg-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rounded-bottom-lg-1 {
    border-bottom-right-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important; }
  .rounded-bottom-lg-2 {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-lg-3 {
    border-bottom-right-radius: 2.4rem !important;
    border-bottom-left-radius: 2.4rem !important; }
  .rounded-bottom-lg-4 {
    border-bottom-right-radius: 3.2rem !important;
    border-bottom-left-radius: 3.2rem !important; }
  .rounded-bottom-lg-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  .rounded-bottom-lg-pill {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important; }
  .rounded-start-lg {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-lg-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }
  .rounded-start-lg-1 {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important; }
  .rounded-start-lg-2 {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-lg-3 {
    border-bottom-left-radius: 2.4rem !important;
    border-top-left-radius: 2.4rem !important; }
  .rounded-start-lg-4 {
    border-bottom-left-radius: 3.2rem !important;
    border-top-left-radius: 3.2rem !important; }
  .rounded-start-lg-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important; }
  .rounded-start-lg-pill {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important; }
  .z-index-lg-1 {
    z-index: 1 !important; }
  .z-index-lg-2 {
    z-index: 2 !important; }
  .z-index-lg-3 {
    z-index: 3 !important; }
  .z-index-lg-4 {
    z-index: 4 !important; }
  .z-index-lg-5 {
    z-index: 5 !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .position-xl-static {
    position: static !important; }
  .position-xl-relative {
    position: relative !important; }
  .position-xl-absolute {
    position: absolute !important; }
  .position-xl-fixed {
    position: fixed !important; }
  .position-xl-sticky {
    position: sticky !important; }
  .w-xl-20 {
    width: 20% !important; }
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-70 {
    width: 70% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-80 {
    width: 80% !important; }
  .w-xl-90 {
    width: 90% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .h-xl-20 {
    height: 20% !important; }
  .h-xl-25 {
    height: 25% !important; }
  .h-xl-50 {
    height: 50% !important; }
  .h-xl-70 {
    height: 70% !important; }
  .h-xl-75 {
    height: 75% !important; }
  .h-xl-80 {
    height: 80% !important; }
  .h-xl-90 {
    height: 90% !important; }
  .h-xl-100 {
    height: 100% !important; }
  .h-xl-auto {
    height: auto !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-6 {
    margin: 0.6rem !important; }
  .m-xl-8 {
    margin: 0.8rem !important; }
  .m-xl-10 {
    margin: 1rem !important; }
  .m-xl-12 {
    margin: 1.2rem !important; }
  .m-xl-14 {
    margin: 1.4rem !important; }
  .m-xl-16 {
    margin: 1.6rem !important; }
  .m-xl-18 {
    margin: 1.8rem !important; }
  .m-xl-20 {
    margin: 2rem !important; }
  .m-xl-24 {
    margin: 2.4rem !important; }
  .m-xl-32 {
    margin: 3.2rem !important; }
  .m-xl-40 {
    margin: 4rem !important; }
  .m-xl-42 {
    margin: 4.2rem !important; }
  .m-xl-48 {
    margin: 4.8rem !important; }
  .m-xl-64 {
    margin: 6.4rem !important; }
  .m-xl-112 {
    margin: 11.2rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-xl-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-xl-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-xl-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-xl-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-xl-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-xl-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xl-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-xl-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-xl-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xl-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-xl-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-xl-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-xl-112 {
    margin-right: 11.2rem !important;
    margin-left: 11.2rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-xl-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-xl-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-xl-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-xl-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-xl-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-xl-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xl-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-xl-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-xl-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xl-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-xl-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-xl-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-xl-112 {
    margin-top: 11.2rem !important;
    margin-bottom: 11.2rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-6 {
    margin-top: 0.6rem !important; }
  .mt-xl-8 {
    margin-top: 0.8rem !important; }
  .mt-xl-10 {
    margin-top: 1rem !important; }
  .mt-xl-12 {
    margin-top: 1.2rem !important; }
  .mt-xl-14 {
    margin-top: 1.4rem !important; }
  .mt-xl-16 {
    margin-top: 1.6rem !important; }
  .mt-xl-18 {
    margin-top: 1.8rem !important; }
  .mt-xl-20 {
    margin-top: 2rem !important; }
  .mt-xl-24 {
    margin-top: 2.4rem !important; }
  .mt-xl-32 {
    margin-top: 3.2rem !important; }
  .mt-xl-40 {
    margin-top: 4rem !important; }
  .mt-xl-42 {
    margin-top: 4.2rem !important; }
  .mt-xl-48 {
    margin-top: 4.8rem !important; }
  .mt-xl-64 {
    margin-top: 6.4rem !important; }
  .mt-xl-112 {
    margin-top: 11.2rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-6 {
    margin-right: 0.6rem !important; }
  .me-xl-8 {
    margin-right: 0.8rem !important; }
  .me-xl-10 {
    margin-right: 1rem !important; }
  .me-xl-12 {
    margin-right: 1.2rem !important; }
  .me-xl-14 {
    margin-right: 1.4rem !important; }
  .me-xl-16 {
    margin-right: 1.6rem !important; }
  .me-xl-18 {
    margin-right: 1.8rem !important; }
  .me-xl-20 {
    margin-right: 2rem !important; }
  .me-xl-24 {
    margin-right: 2.4rem !important; }
  .me-xl-32 {
    margin-right: 3.2rem !important; }
  .me-xl-40 {
    margin-right: 4rem !important; }
  .me-xl-42 {
    margin-right: 4.2rem !important; }
  .me-xl-48 {
    margin-right: 4.8rem !important; }
  .me-xl-64 {
    margin-right: 6.4rem !important; }
  .me-xl-112 {
    margin-right: 11.2rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-6 {
    margin-bottom: 0.6rem !important; }
  .mb-xl-8 {
    margin-bottom: 0.8rem !important; }
  .mb-xl-10 {
    margin-bottom: 1rem !important; }
  .mb-xl-12 {
    margin-bottom: 1.2rem !important; }
  .mb-xl-14 {
    margin-bottom: 1.4rem !important; }
  .mb-xl-16 {
    margin-bottom: 1.6rem !important; }
  .mb-xl-18 {
    margin-bottom: 1.8rem !important; }
  .mb-xl-20 {
    margin-bottom: 2rem !important; }
  .mb-xl-24 {
    margin-bottom: 2.4rem !important; }
  .mb-xl-32 {
    margin-bottom: 3.2rem !important; }
  .mb-xl-40 {
    margin-bottom: 4rem !important; }
  .mb-xl-42 {
    margin-bottom: 4.2rem !important; }
  .mb-xl-48 {
    margin-bottom: 4.8rem !important; }
  .mb-xl-64 {
    margin-bottom: 6.4rem !important; }
  .mb-xl-112 {
    margin-bottom: 11.2rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-6 {
    margin-left: 0.6rem !important; }
  .ms-xl-8 {
    margin-left: 0.8rem !important; }
  .ms-xl-10 {
    margin-left: 1rem !important; }
  .ms-xl-12 {
    margin-left: 1.2rem !important; }
  .ms-xl-14 {
    margin-left: 1.4rem !important; }
  .ms-xl-16 {
    margin-left: 1.6rem !important; }
  .ms-xl-18 {
    margin-left: 1.8rem !important; }
  .ms-xl-20 {
    margin-left: 2rem !important; }
  .ms-xl-24 {
    margin-left: 2.4rem !important; }
  .ms-xl-32 {
    margin-left: 3.2rem !important; }
  .ms-xl-40 {
    margin-left: 4rem !important; }
  .ms-xl-42 {
    margin-left: 4.2rem !important; }
  .ms-xl-48 {
    margin-left: 4.8rem !important; }
  .ms-xl-64 {
    margin-left: 6.4rem !important; }
  .ms-xl-112 {
    margin-left: 11.2rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-6 {
    padding: 0.6rem !important; }
  .p-xl-8 {
    padding: 0.8rem !important; }
  .p-xl-10 {
    padding: 1rem !important; }
  .p-xl-12 {
    padding: 1.2rem !important; }
  .p-xl-14 {
    padding: 1.4rem !important; }
  .p-xl-16 {
    padding: 1.6rem !important; }
  .p-xl-18 {
    padding: 1.8rem !important; }
  .p-xl-20 {
    padding: 2rem !important; }
  .p-xl-24 {
    padding: 2.4rem !important; }
  .p-xl-32 {
    padding: 3.2rem !important; }
  .p-xl-40 {
    padding: 4rem !important; }
  .p-xl-42 {
    padding: 4.2rem !important; }
  .p-xl-48 {
    padding: 4.8rem !important; }
  .p-xl-64 {
    padding: 6.4rem !important; }
  .p-xl-112 {
    padding: 11.2rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-xl-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-xl-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-xl-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-xl-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-xl-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-xl-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xl-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-xl-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-xl-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xl-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-xl-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-xl-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-xl-112 {
    padding-right: 11.2rem !important;
    padding-left: 11.2rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-xl-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-xl-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-xl-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-xl-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-xl-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-xl-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xl-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-xl-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-xl-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xl-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-xl-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-xl-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-xl-112 {
    padding-top: 11.2rem !important;
    padding-bottom: 11.2rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-6 {
    padding-top: 0.6rem !important; }
  .pt-xl-8 {
    padding-top: 0.8rem !important; }
  .pt-xl-10 {
    padding-top: 1rem !important; }
  .pt-xl-12 {
    padding-top: 1.2rem !important; }
  .pt-xl-14 {
    padding-top: 1.4rem !important; }
  .pt-xl-16 {
    padding-top: 1.6rem !important; }
  .pt-xl-18 {
    padding-top: 1.8rem !important; }
  .pt-xl-20 {
    padding-top: 2rem !important; }
  .pt-xl-24 {
    padding-top: 2.4rem !important; }
  .pt-xl-32 {
    padding-top: 3.2rem !important; }
  .pt-xl-40 {
    padding-top: 4rem !important; }
  .pt-xl-42 {
    padding-top: 4.2rem !important; }
  .pt-xl-48 {
    padding-top: 4.8rem !important; }
  .pt-xl-64 {
    padding-top: 6.4rem !important; }
  .pt-xl-112 {
    padding-top: 11.2rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-6 {
    padding-right: 0.6rem !important; }
  .pe-xl-8 {
    padding-right: 0.8rem !important; }
  .pe-xl-10 {
    padding-right: 1rem !important; }
  .pe-xl-12 {
    padding-right: 1.2rem !important; }
  .pe-xl-14 {
    padding-right: 1.4rem !important; }
  .pe-xl-16 {
    padding-right: 1.6rem !important; }
  .pe-xl-18 {
    padding-right: 1.8rem !important; }
  .pe-xl-20 {
    padding-right: 2rem !important; }
  .pe-xl-24 {
    padding-right: 2.4rem !important; }
  .pe-xl-32 {
    padding-right: 3.2rem !important; }
  .pe-xl-40 {
    padding-right: 4rem !important; }
  .pe-xl-42 {
    padding-right: 4.2rem !important; }
  .pe-xl-48 {
    padding-right: 4.8rem !important; }
  .pe-xl-64 {
    padding-right: 6.4rem !important; }
  .pe-xl-112 {
    padding-right: 11.2rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-6 {
    padding-bottom: 0.6rem !important; }
  .pb-xl-8 {
    padding-bottom: 0.8rem !important; }
  .pb-xl-10 {
    padding-bottom: 1rem !important; }
  .pb-xl-12 {
    padding-bottom: 1.2rem !important; }
  .pb-xl-14 {
    padding-bottom: 1.4rem !important; }
  .pb-xl-16 {
    padding-bottom: 1.6rem !important; }
  .pb-xl-18 {
    padding-bottom: 1.8rem !important; }
  .pb-xl-20 {
    padding-bottom: 2rem !important; }
  .pb-xl-24 {
    padding-bottom: 2.4rem !important; }
  .pb-xl-32 {
    padding-bottom: 3.2rem !important; }
  .pb-xl-40 {
    padding-bottom: 4rem !important; }
  .pb-xl-42 {
    padding-bottom: 4.2rem !important; }
  .pb-xl-48 {
    padding-bottom: 4.8rem !important; }
  .pb-xl-64 {
    padding-bottom: 6.4rem !important; }
  .pb-xl-112 {
    padding-bottom: 11.2rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-6 {
    padding-left: 0.6rem !important; }
  .ps-xl-8 {
    padding-left: 0.8rem !important; }
  .ps-xl-10 {
    padding-left: 1rem !important; }
  .ps-xl-12 {
    padding-left: 1.2rem !important; }
  .ps-xl-14 {
    padding-left: 1.4rem !important; }
  .ps-xl-16 {
    padding-left: 1.6rem !important; }
  .ps-xl-18 {
    padding-left: 1.8rem !important; }
  .ps-xl-20 {
    padding-left: 2rem !important; }
  .ps-xl-24 {
    padding-left: 2.4rem !important; }
  .ps-xl-32 {
    padding-left: 3.2rem !important; }
  .ps-xl-40 {
    padding-left: 4rem !important; }
  .ps-xl-42 {
    padding-left: 4.2rem !important; }
  .ps-xl-48 {
    padding-left: 4.8rem !important; }
  .ps-xl-64 {
    padding-left: 6.4rem !important; }
  .ps-xl-112 {
    padding-left: 11.2rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-6 {
    gap: 0.6rem !important; }
  .gap-xl-8 {
    gap: 0.8rem !important; }
  .gap-xl-10 {
    gap: 1rem !important; }
  .gap-xl-12 {
    gap: 1.2rem !important; }
  .gap-xl-14 {
    gap: 1.4rem !important; }
  .gap-xl-16 {
    gap: 1.6rem !important; }
  .gap-xl-18 {
    gap: 1.8rem !important; }
  .gap-xl-20 {
    gap: 2rem !important; }
  .gap-xl-24 {
    gap: 2.4rem !important; }
  .gap-xl-32 {
    gap: 3.2rem !important; }
  .gap-xl-40 {
    gap: 4rem !important; }
  .gap-xl-42 {
    gap: 4.2rem !important; }
  .gap-xl-48 {
    gap: 4.8rem !important; }
  .gap-xl-64 {
    gap: 6.4rem !important; }
  .gap-xl-112 {
    gap: 11.2rem !important; }
  .fs-xl-10 {
    font-size: 1rem !important; }
  .fs-xl-12 {
    font-size: 1.2rem !important; }
  .fs-xl-14 {
    font-size: calc(1.265rem + 0.18vw) !important; }
  .fs-xl-16 {
    font-size: calc(1.285rem + 0.42vw) !important; }
  .fs-xl-18 {
    font-size: calc(1.305rem + 0.66vw) !important; }
  .fs-xl-20 {
    font-size: calc(1.325rem + 0.9vw) !important; }
  .fs-xl-24 {
    font-size: calc(1.365rem + 1.38vw) !important; }
  .fs-xl-28 {
    font-size: calc(1.405rem + 1.86vw) !important; }
  .fs-xl-32 {
    font-size: calc(1.445rem + 2.34vw) !important; }
  .fs-xl-64 {
    font-size: calc(1.765rem + 6.18vw) !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; }
  .rounded-xl {
    border-radius: 1.6rem !important; }
  .rounded-xl-0 {
    border-radius: 0 !important; }
  .rounded-xl-1 {
    border-radius: 0.8rem !important; }
  .rounded-xl-2 {
    border-radius: 1.6rem !important; }
  .rounded-xl-3 {
    border-radius: 2.4rem !important; }
  .rounded-xl-4 {
    border-radius: 3.2rem !important; }
  .rounded-xl-circle {
    border-radius: 50% !important; }
  .rounded-xl-pill {
    border-radius: 5rem !important; }
  .rounded-top-xl {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-xl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-xl-1 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xl-2 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-xl-3 {
    border-top-left-radius: 2.4rem !important;
    border-top-right-radius: 2.4rem !important; }
  .rounded-top-xl-4 {
    border-top-left-radius: 3.2rem !important;
    border-top-right-radius: 3.2rem !important; }
  .rounded-top-xl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-xl-pill {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important; }
  .rounded-end-xl {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-xl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .rounded-end-xl-1 {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important; }
  .rounded-end-xl-2 {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-xl-3 {
    border-top-right-radius: 2.4rem !important;
    border-bottom-right-radius: 2.4rem !important; }
  .rounded-end-xl-4 {
    border-top-right-radius: 3.2rem !important;
    border-bottom-right-radius: 3.2rem !important; }
  .rounded-end-xl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  .rounded-end-xl-pill {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important; }
  .rounded-bottom-xl {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-xl-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rounded-bottom-xl-1 {
    border-bottom-right-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important; }
  .rounded-bottom-xl-2 {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-xl-3 {
    border-bottom-right-radius: 2.4rem !important;
    border-bottom-left-radius: 2.4rem !important; }
  .rounded-bottom-xl-4 {
    border-bottom-right-radius: 3.2rem !important;
    border-bottom-left-radius: 3.2rem !important; }
  .rounded-bottom-xl-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  .rounded-bottom-xl-pill {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important; }
  .rounded-start-xl {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-xl-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }
  .rounded-start-xl-1 {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important; }
  .rounded-start-xl-2 {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-xl-3 {
    border-bottom-left-radius: 2.4rem !important;
    border-top-left-radius: 2.4rem !important; }
  .rounded-start-xl-4 {
    border-bottom-left-radius: 3.2rem !important;
    border-top-left-radius: 3.2rem !important; }
  .rounded-start-xl-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important; }
  .rounded-start-xl-pill {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important; }
  .z-index-xl-1 {
    z-index: 1 !important; }
  .z-index-xl-2 {
    z-index: 2 !important; }
  .z-index-xl-3 {
    z-index: 3 !important; }
  .z-index-xl-4 {
    z-index: 4 !important; }
  .z-index-xl-5 {
    z-index: 5 !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .position-xxl-static {
    position: static !important; }
  .position-xxl-relative {
    position: relative !important; }
  .position-xxl-absolute {
    position: absolute !important; }
  .position-xxl-fixed {
    position: fixed !important; }
  .position-xxl-sticky {
    position: sticky !important; }
  .w-xxl-20 {
    width: 20% !important; }
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-70 {
    width: 70% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-80 {
    width: 80% !important; }
  .w-xxl-90 {
    width: 90% !important; }
  .w-xxl-100 {
    width: 100% !important; }
  .w-xxl-auto {
    width: auto !important; }
  .h-xxl-20 {
    height: 20% !important; }
  .h-xxl-25 {
    height: 25% !important; }
  .h-xxl-50 {
    height: 50% !important; }
  .h-xxl-70 {
    height: 70% !important; }
  .h-xxl-75 {
    height: 75% !important; }
  .h-xxl-80 {
    height: 80% !important; }
  .h-xxl-90 {
    height: 90% !important; }
  .h-xxl-100 {
    height: 100% !important; }
  .h-xxl-auto {
    height: auto !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-6 {
    margin: 0.6rem !important; }
  .m-xxl-8 {
    margin: 0.8rem !important; }
  .m-xxl-10 {
    margin: 1rem !important; }
  .m-xxl-12 {
    margin: 1.2rem !important; }
  .m-xxl-14 {
    margin: 1.4rem !important; }
  .m-xxl-16 {
    margin: 1.6rem !important; }
  .m-xxl-18 {
    margin: 1.8rem !important; }
  .m-xxl-20 {
    margin: 2rem !important; }
  .m-xxl-24 {
    margin: 2.4rem !important; }
  .m-xxl-32 {
    margin: 3.2rem !important; }
  .m-xxl-40 {
    margin: 4rem !important; }
  .m-xxl-42 {
    margin: 4.2rem !important; }
  .m-xxl-48 {
    margin: 4.8rem !important; }
  .m-xxl-64 {
    margin: 6.4rem !important; }
  .m-xxl-112 {
    margin: 11.2rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-xxl-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-xxl-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-xxl-14 {
    margin-right: 1.4rem !important;
    margin-left: 1.4rem !important; }
  .mx-xxl-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-xxl-18 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important; }
  .mx-xxl-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xxl-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-xxl-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-xxl-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xxl-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-xxl-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-xxl-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-xxl-112 {
    margin-right: 11.2rem !important;
    margin-left: 11.2rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-xxl-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-xxl-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-xxl-14 {
    margin-top: 1.4rem !important;
    margin-bottom: 1.4rem !important; }
  .my-xxl-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-xxl-18 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important; }
  .my-xxl-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xxl-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-xxl-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-xxl-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xxl-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-xxl-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-xxl-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-xxl-112 {
    margin-top: 11.2rem !important;
    margin-bottom: 11.2rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-6 {
    margin-top: 0.6rem !important; }
  .mt-xxl-8 {
    margin-top: 0.8rem !important; }
  .mt-xxl-10 {
    margin-top: 1rem !important; }
  .mt-xxl-12 {
    margin-top: 1.2rem !important; }
  .mt-xxl-14 {
    margin-top: 1.4rem !important; }
  .mt-xxl-16 {
    margin-top: 1.6rem !important; }
  .mt-xxl-18 {
    margin-top: 1.8rem !important; }
  .mt-xxl-20 {
    margin-top: 2rem !important; }
  .mt-xxl-24 {
    margin-top: 2.4rem !important; }
  .mt-xxl-32 {
    margin-top: 3.2rem !important; }
  .mt-xxl-40 {
    margin-top: 4rem !important; }
  .mt-xxl-42 {
    margin-top: 4.2rem !important; }
  .mt-xxl-48 {
    margin-top: 4.8rem !important; }
  .mt-xxl-64 {
    margin-top: 6.4rem !important; }
  .mt-xxl-112 {
    margin-top: 11.2rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-6 {
    margin-right: 0.6rem !important; }
  .me-xxl-8 {
    margin-right: 0.8rem !important; }
  .me-xxl-10 {
    margin-right: 1rem !important; }
  .me-xxl-12 {
    margin-right: 1.2rem !important; }
  .me-xxl-14 {
    margin-right: 1.4rem !important; }
  .me-xxl-16 {
    margin-right: 1.6rem !important; }
  .me-xxl-18 {
    margin-right: 1.8rem !important; }
  .me-xxl-20 {
    margin-right: 2rem !important; }
  .me-xxl-24 {
    margin-right: 2.4rem !important; }
  .me-xxl-32 {
    margin-right: 3.2rem !important; }
  .me-xxl-40 {
    margin-right: 4rem !important; }
  .me-xxl-42 {
    margin-right: 4.2rem !important; }
  .me-xxl-48 {
    margin-right: 4.8rem !important; }
  .me-xxl-64 {
    margin-right: 6.4rem !important; }
  .me-xxl-112 {
    margin-right: 11.2rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-6 {
    margin-bottom: 0.6rem !important; }
  .mb-xxl-8 {
    margin-bottom: 0.8rem !important; }
  .mb-xxl-10 {
    margin-bottom: 1rem !important; }
  .mb-xxl-12 {
    margin-bottom: 1.2rem !important; }
  .mb-xxl-14 {
    margin-bottom: 1.4rem !important; }
  .mb-xxl-16 {
    margin-bottom: 1.6rem !important; }
  .mb-xxl-18 {
    margin-bottom: 1.8rem !important; }
  .mb-xxl-20 {
    margin-bottom: 2rem !important; }
  .mb-xxl-24 {
    margin-bottom: 2.4rem !important; }
  .mb-xxl-32 {
    margin-bottom: 3.2rem !important; }
  .mb-xxl-40 {
    margin-bottom: 4rem !important; }
  .mb-xxl-42 {
    margin-bottom: 4.2rem !important; }
  .mb-xxl-48 {
    margin-bottom: 4.8rem !important; }
  .mb-xxl-64 {
    margin-bottom: 6.4rem !important; }
  .mb-xxl-112 {
    margin-bottom: 11.2rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-6 {
    margin-left: 0.6rem !important; }
  .ms-xxl-8 {
    margin-left: 0.8rem !important; }
  .ms-xxl-10 {
    margin-left: 1rem !important; }
  .ms-xxl-12 {
    margin-left: 1.2rem !important; }
  .ms-xxl-14 {
    margin-left: 1.4rem !important; }
  .ms-xxl-16 {
    margin-left: 1.6rem !important; }
  .ms-xxl-18 {
    margin-left: 1.8rem !important; }
  .ms-xxl-20 {
    margin-left: 2rem !important; }
  .ms-xxl-24 {
    margin-left: 2.4rem !important; }
  .ms-xxl-32 {
    margin-left: 3.2rem !important; }
  .ms-xxl-40 {
    margin-left: 4rem !important; }
  .ms-xxl-42 {
    margin-left: 4.2rem !important; }
  .ms-xxl-48 {
    margin-left: 4.8rem !important; }
  .ms-xxl-64 {
    margin-left: 6.4rem !important; }
  .ms-xxl-112 {
    margin-left: 11.2rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-6 {
    padding: 0.6rem !important; }
  .p-xxl-8 {
    padding: 0.8rem !important; }
  .p-xxl-10 {
    padding: 1rem !important; }
  .p-xxl-12 {
    padding: 1.2rem !important; }
  .p-xxl-14 {
    padding: 1.4rem !important; }
  .p-xxl-16 {
    padding: 1.6rem !important; }
  .p-xxl-18 {
    padding: 1.8rem !important; }
  .p-xxl-20 {
    padding: 2rem !important; }
  .p-xxl-24 {
    padding: 2.4rem !important; }
  .p-xxl-32 {
    padding: 3.2rem !important; }
  .p-xxl-40 {
    padding: 4rem !important; }
  .p-xxl-42 {
    padding: 4.2rem !important; }
  .p-xxl-48 {
    padding: 4.8rem !important; }
  .p-xxl-64 {
    padding: 6.4rem !important; }
  .p-xxl-112 {
    padding: 11.2rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-xxl-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-xxl-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-xxl-14 {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important; }
  .px-xxl-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-xxl-18 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important; }
  .px-xxl-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xxl-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-xxl-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-xxl-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xxl-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-xxl-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-xxl-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-xxl-112 {
    padding-right: 11.2rem !important;
    padding-left: 11.2rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-xxl-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-xxl-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-xxl-14 {
    padding-top: 1.4rem !important;
    padding-bottom: 1.4rem !important; }
  .py-xxl-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-xxl-18 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important; }
  .py-xxl-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xxl-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-xxl-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-xxl-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xxl-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-xxl-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-xxl-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-xxl-112 {
    padding-top: 11.2rem !important;
    padding-bottom: 11.2rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-6 {
    padding-top: 0.6rem !important; }
  .pt-xxl-8 {
    padding-top: 0.8rem !important; }
  .pt-xxl-10 {
    padding-top: 1rem !important; }
  .pt-xxl-12 {
    padding-top: 1.2rem !important; }
  .pt-xxl-14 {
    padding-top: 1.4rem !important; }
  .pt-xxl-16 {
    padding-top: 1.6rem !important; }
  .pt-xxl-18 {
    padding-top: 1.8rem !important; }
  .pt-xxl-20 {
    padding-top: 2rem !important; }
  .pt-xxl-24 {
    padding-top: 2.4rem !important; }
  .pt-xxl-32 {
    padding-top: 3.2rem !important; }
  .pt-xxl-40 {
    padding-top: 4rem !important; }
  .pt-xxl-42 {
    padding-top: 4.2rem !important; }
  .pt-xxl-48 {
    padding-top: 4.8rem !important; }
  .pt-xxl-64 {
    padding-top: 6.4rem !important; }
  .pt-xxl-112 {
    padding-top: 11.2rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-6 {
    padding-right: 0.6rem !important; }
  .pe-xxl-8 {
    padding-right: 0.8rem !important; }
  .pe-xxl-10 {
    padding-right: 1rem !important; }
  .pe-xxl-12 {
    padding-right: 1.2rem !important; }
  .pe-xxl-14 {
    padding-right: 1.4rem !important; }
  .pe-xxl-16 {
    padding-right: 1.6rem !important; }
  .pe-xxl-18 {
    padding-right: 1.8rem !important; }
  .pe-xxl-20 {
    padding-right: 2rem !important; }
  .pe-xxl-24 {
    padding-right: 2.4rem !important; }
  .pe-xxl-32 {
    padding-right: 3.2rem !important; }
  .pe-xxl-40 {
    padding-right: 4rem !important; }
  .pe-xxl-42 {
    padding-right: 4.2rem !important; }
  .pe-xxl-48 {
    padding-right: 4.8rem !important; }
  .pe-xxl-64 {
    padding-right: 6.4rem !important; }
  .pe-xxl-112 {
    padding-right: 11.2rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-6 {
    padding-bottom: 0.6rem !important; }
  .pb-xxl-8 {
    padding-bottom: 0.8rem !important; }
  .pb-xxl-10 {
    padding-bottom: 1rem !important; }
  .pb-xxl-12 {
    padding-bottom: 1.2rem !important; }
  .pb-xxl-14 {
    padding-bottom: 1.4rem !important; }
  .pb-xxl-16 {
    padding-bottom: 1.6rem !important; }
  .pb-xxl-18 {
    padding-bottom: 1.8rem !important; }
  .pb-xxl-20 {
    padding-bottom: 2rem !important; }
  .pb-xxl-24 {
    padding-bottom: 2.4rem !important; }
  .pb-xxl-32 {
    padding-bottom: 3.2rem !important; }
  .pb-xxl-40 {
    padding-bottom: 4rem !important; }
  .pb-xxl-42 {
    padding-bottom: 4.2rem !important; }
  .pb-xxl-48 {
    padding-bottom: 4.8rem !important; }
  .pb-xxl-64 {
    padding-bottom: 6.4rem !important; }
  .pb-xxl-112 {
    padding-bottom: 11.2rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-6 {
    padding-left: 0.6rem !important; }
  .ps-xxl-8 {
    padding-left: 0.8rem !important; }
  .ps-xxl-10 {
    padding-left: 1rem !important; }
  .ps-xxl-12 {
    padding-left: 1.2rem !important; }
  .ps-xxl-14 {
    padding-left: 1.4rem !important; }
  .ps-xxl-16 {
    padding-left: 1.6rem !important; }
  .ps-xxl-18 {
    padding-left: 1.8rem !important; }
  .ps-xxl-20 {
    padding-left: 2rem !important; }
  .ps-xxl-24 {
    padding-left: 2.4rem !important; }
  .ps-xxl-32 {
    padding-left: 3.2rem !important; }
  .ps-xxl-40 {
    padding-left: 4rem !important; }
  .ps-xxl-42 {
    padding-left: 4.2rem !important; }
  .ps-xxl-48 {
    padding-left: 4.8rem !important; }
  .ps-xxl-64 {
    padding-left: 6.4rem !important; }
  .ps-xxl-112 {
    padding-left: 11.2rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-6 {
    gap: 0.6rem !important; }
  .gap-xxl-8 {
    gap: 0.8rem !important; }
  .gap-xxl-10 {
    gap: 1rem !important; }
  .gap-xxl-12 {
    gap: 1.2rem !important; }
  .gap-xxl-14 {
    gap: 1.4rem !important; }
  .gap-xxl-16 {
    gap: 1.6rem !important; }
  .gap-xxl-18 {
    gap: 1.8rem !important; }
  .gap-xxl-20 {
    gap: 2rem !important; }
  .gap-xxl-24 {
    gap: 2.4rem !important; }
  .gap-xxl-32 {
    gap: 3.2rem !important; }
  .gap-xxl-40 {
    gap: 4rem !important; }
  .gap-xxl-42 {
    gap: 4.2rem !important; }
  .gap-xxl-48 {
    gap: 4.8rem !important; }
  .gap-xxl-64 {
    gap: 6.4rem !important; }
  .gap-xxl-112 {
    gap: 11.2rem !important; }
  .fs-xxl-10 {
    font-size: 1rem !important; }
  .fs-xxl-12 {
    font-size: 1.2rem !important; }
  .fs-xxl-14 {
    font-size: calc(1.265rem + 0.18vw) !important; }
  .fs-xxl-16 {
    font-size: calc(1.285rem + 0.42vw) !important; }
  .fs-xxl-18 {
    font-size: calc(1.305rem + 0.66vw) !important; }
  .fs-xxl-20 {
    font-size: calc(1.325rem + 0.9vw) !important; }
  .fs-xxl-24 {
    font-size: calc(1.365rem + 1.38vw) !important; }
  .fs-xxl-28 {
    font-size: calc(1.405rem + 1.86vw) !important; }
  .fs-xxl-32 {
    font-size: calc(1.445rem + 2.34vw) !important; }
  .fs-xxl-64 {
    font-size: calc(1.765rem + 6.18vw) !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; }
  .rounded-xxl {
    border-radius: 1.6rem !important; }
  .rounded-xxl-0 {
    border-radius: 0 !important; }
  .rounded-xxl-1 {
    border-radius: 0.8rem !important; }
  .rounded-xxl-2 {
    border-radius: 1.6rem !important; }
  .rounded-xxl-3 {
    border-radius: 2.4rem !important; }
  .rounded-xxl-4 {
    border-radius: 3.2rem !important; }
  .rounded-xxl-circle {
    border-radius: 50% !important; }
  .rounded-xxl-pill {
    border-radius: 5rem !important; }
  .rounded-top-xxl {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-xxl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-xxl-1 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xxl-2 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-xxl-3 {
    border-top-left-radius: 2.4rem !important;
    border-top-right-radius: 2.4rem !important; }
  .rounded-top-xxl-4 {
    border-top-left-radius: 3.2rem !important;
    border-top-right-radius: 3.2rem !important; }
  .rounded-top-xxl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-xxl-pill {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important; }
  .rounded-end-xxl {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-xxl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .rounded-end-xxl-1 {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important; }
  .rounded-end-xxl-2 {
    border-top-right-radius: 1.6rem !important;
    border-bottom-right-radius: 1.6rem !important; }
  .rounded-end-xxl-3 {
    border-top-right-radius: 2.4rem !important;
    border-bottom-right-radius: 2.4rem !important; }
  .rounded-end-xxl-4 {
    border-top-right-radius: 3.2rem !important;
    border-bottom-right-radius: 3.2rem !important; }
  .rounded-end-xxl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important; }
  .rounded-end-xxl-pill {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important; }
  .rounded-bottom-xxl {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-xxl-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .rounded-bottom-xxl-1 {
    border-bottom-right-radius: 0.8rem !important;
    border-bottom-left-radius: 0.8rem !important; }
  .rounded-bottom-xxl-2 {
    border-bottom-right-radius: 1.6rem !important;
    border-bottom-left-radius: 1.6rem !important; }
  .rounded-bottom-xxl-3 {
    border-bottom-right-radius: 2.4rem !important;
    border-bottom-left-radius: 2.4rem !important; }
  .rounded-bottom-xxl-4 {
    border-bottom-right-radius: 3.2rem !important;
    border-bottom-left-radius: 3.2rem !important; }
  .rounded-bottom-xxl-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important; }
  .rounded-bottom-xxl-pill {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important; }
  .rounded-start-xxl {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-xxl-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important; }
  .rounded-start-xxl-1 {
    border-bottom-left-radius: 0.8rem !important;
    border-top-left-radius: 0.8rem !important; }
  .rounded-start-xxl-2 {
    border-bottom-left-radius: 1.6rem !important;
    border-top-left-radius: 1.6rem !important; }
  .rounded-start-xxl-3 {
    border-bottom-left-radius: 2.4rem !important;
    border-top-left-radius: 2.4rem !important; }
  .rounded-start-xxl-4 {
    border-bottom-left-radius: 3.2rem !important;
    border-top-left-radius: 3.2rem !important; }
  .rounded-start-xxl-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important; }
  .rounded-start-xxl-pill {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important; }
  .z-index-xxl-1 {
    z-index: 1 !important; }
  .z-index-xxl-2 {
    z-index: 2 !important; }
  .z-index-xxl-3 {
    z-index: 3 !important; }
  .z-index-xxl-4 {
    z-index: 4 !important; }
  .z-index-xxl-5 {
    z-index: 5 !important; } }

@media (min-width: 1200px) {
  .fs-14, .dashboard-login #zone-quizz .quizz-39 .draggable p,
  .dashboard-login #zone-quizz .quizz-40 .draggable p {
    font-size: 1.4rem !important; }
  .fs-16 {
    font-size: 1.6rem !important; }
  .fs-18 {
    font-size: 1.8rem !important; }
  .fs-20 {
    font-size: 2rem !important; }
  .fs-24 {
    font-size: 2.4rem !important; }
  .fs-28 {
    font-size: 2.8rem !important; }
  .fs-32 {
    font-size: 3.2rem !important; }
  .fs-64 {
    font-size: 6.4rem !important; }
  .fs-sm-14 {
    font-size: 1.4rem !important; }
  .fs-sm-16 {
    font-size: 1.6rem !important; }
  .fs-sm-18 {
    font-size: 1.8rem !important; }
  .fs-sm-20 {
    font-size: 2rem !important; }
  .fs-sm-24 {
    font-size: 2.4rem !important; }
  .fs-sm-28 {
    font-size: 2.8rem !important; }
  .fs-sm-32 {
    font-size: 3.2rem !important; }
  .fs-sm-64 {
    font-size: 6.4rem !important; }
  .fs-md-14 {
    font-size: 1.4rem !important; }
  .fs-md-16 {
    font-size: 1.6rem !important; }
  .fs-md-18 {
    font-size: 1.8rem !important; }
  .fs-md-20 {
    font-size: 2rem !important; }
  .fs-md-24 {
    font-size: 2.4rem !important; }
  .fs-md-28 {
    font-size: 2.8rem !important; }
  .fs-md-32 {
    font-size: 3.2rem !important; }
  .fs-md-64 {
    font-size: 6.4rem !important; }
  .fs-lg-14 {
    font-size: 1.4rem !important; }
  .fs-lg-16 {
    font-size: 1.6rem !important; }
  .fs-lg-18 {
    font-size: 1.8rem !important; }
  .fs-lg-20 {
    font-size: 2rem !important; }
  .fs-lg-24 {
    font-size: 2.4rem !important; }
  .fs-lg-28 {
    font-size: 2.8rem !important; }
  .fs-lg-32 {
    font-size: 3.2rem !important; }
  .fs-lg-64 {
    font-size: 6.4rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.6rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1340px; } }

.row {
  --bs-gutter-x: 1.6rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

.g-8,
.gx-8 {
  --bs-gutter-x: 8rem; }

.g-8,
.gy-8 {
  --bs-gutter-y: 8rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 8rem; }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 8rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 8rem; }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 8rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 8rem; }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 8rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 8rem; }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 8rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 8rem; }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 8rem; } }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 2.4rem;
  --bs-alert-padding-y: 2.4rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.8rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 7.2rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 3rem 2.4rem; }

.alert-primary {
  --bs-alert-color: #5e3f01;
  --bs-alert-bg: #eb9e03;
  --bs-alert-border-color: #eb9e03; }
  .alert-primary .alert-link {
    color: #4b3201; }

.alert-primary-dark {
  --bs-alert-color: #503600;
  --bs-alert-bg: #c98600;
  --bs-alert-border-color: #c98600; }
  .alert-primary-dark .alert-link {
    color: #402b00; }

.alert-secondary {
  --bs-alert-color: #3d3d3d;
  --bs-alert-bg: #989898;
  --bs-alert-border-color: #989898; }
  .alert-secondary .alert-link {
    color: #313131; }

.alert-success {
  --bs-alert-color: #28482f;
  --bs-alert-bg: #64b375;
  --bs-alert-border-color: #64b375; }
  .alert-success .alert-link {
    color: #203a26; }

.alert-info {
  --bs-alert-color: #a1b1c4;
  --bs-alert-bg: #133c6b;
  --bs-alert-border-color: #133c6b; }
  .alert-info .alert-link {
    color: #818e9d; }

.alert-warning {
  --bs-alert-color: #4d4430;
  --bs-alert-bg: #c0ab77;
  --bs-alert-border-color: #c0ab77; }
  .alert-warning .alert-link {
    color: #3e3626; }

.alert-danger {
  --bs-alert-color: #5a2525;
  --bs-alert-bg: #e05c5c;
  --bs-alert-border-color: #e05c5c; }
  .alert-danger .alert-link {
    color: #481e1e; }

.alert-light {
  --bs-alert-color: #646362;
  --bs-alert-bg: #f9f7f4;
  --bs-alert-border-color: #f9f7f4; }
  .alert-light .alert-link {
    color: #504f4e; }

.alert-dark {
  --bs-alert-color: #a3a3a3;
  --bs-alert-bg: #1a1a1a;
  --bs-alert-border-color: #1a1a1a; }
  .alert-dark .alert-link {
    color: #828282; }

.alert-white {
  --bs-alert-color: #666666;
  --bs-alert-bg: white;
  --bs-alert-border-color: white; }
  .alert-white .alert-link {
    color: #525252; }

.badge {
  --bs-badge-padding-x: 1.6rem;
  --bs-badge-padding-y: 0.6rem;
  --bs-badge-font-size: 1.2rem;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 5rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.form-label {
  margin-bottom: 0.5rem;
  font-size: calc(1.265rem + 0.18vw);
  font-weight: 500;
  color: #414141; }
  @media (min-width: 1200px) {
    .form-label {
      font-size: 1.4rem; } }

.col-form-label {
  padding-top: calc(1.6rem + 1px);
  padding-bottom: calc(1.6rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
  color: #414141; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control, .chzn-container .chzn-drop .chzn-search input[type="text"] {
  display: block;
  width: 100%;
  padding: 1.6rem 1.6rem;
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #F9F7F4;
  background-clip: padding-box;
  border: 1px solid #F9F7F4;
  appearance: none;
  border-radius: 0.8rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (min-width: 1200px) {
    .form-control, .chzn-container .chzn-drop .chzn-search input[type="text"] {
      font-size: 1.6rem; } }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .chzn-container .chzn-drop .chzn-search input[type="text"] {
      transition: none; } }
  .form-control[type="file"], .chzn-container .chzn-drop .chzn-search input[type="file"][type="text"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]), .chzn-container .chzn-drop .chzn-search input[type="file"]:not(:disabled):not([readonly])[type="text"] {
      cursor: pointer; }
  .form-control:focus, .chzn-container .chzn-drop .chzn-search input:focus[type="text"] {
    color: #000;
    background-color: #F9F7F4;
    border-color: #f5cf81;
    outline: 0;
    box-shadow: none, 0 0 0 0.25rem rgba(235, 158, 3, 0.25); }
  .form-control::-webkit-date-and-time-value, .chzn-container .chzn-drop .chzn-search input[type="text"]::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder, .chzn-container .chzn-drop .chzn-search input[type="text"]::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .chzn-container .chzn-drop .chzn-search input:disabled[type="text"] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button, .chzn-container .chzn-drop .chzn-search input[type="text"]::file-selector-button {
    padding: 1.6rem 1.6rem;
    margin: -1.6rem -1.6rem;
    margin-inline-end: 1.6rem;
    color: #000;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button, .chzn-container .chzn-drop .chzn-search input[type="text"]::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button, .chzn-container .chzn-drop .chzn-search input[type="text"]:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1.6rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.8rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 2.4rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 3.2rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 3.2rem + 2px);
  padding: 1.6rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 0.8rem; }
  .form-control-color::-webkit-color-swatch {
    border-radius: 0.8rem; }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + 2px); }

.form-select {
  display: block;
  width: 100%;
  padding: 1.2rem 6rem 1.2rem 2rem;
  -moz-padding-start: calc(2rem - 3px);
  font-family: Inter, "Open Sans", sans-serif;
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #F9F7F4;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 2rem center;
  background-size: 16px 12px;
  border: 1px solid #F9F7F4;
  border-radius: 0.8rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (min-width: 1200px) {
    .form-select {
      font-size: 1.6rem; } }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #f5cf81;
    outline: 0;
    box-shadow: none, 0 0 0 0.25rem rgba(235, 158, 3, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 2rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.8rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 2.4rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input, .form-check input[type="radio"], .form-check input[type="checkbox"] {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input, .form-check-reverse input[type="radio"], .form-check-reverse input[type="checkbox"] {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input, input[type="radio"], input[type="checkbox"] {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #989898;
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"], input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"], input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active, input:active[type="radio"], input:active[type="checkbox"] {
    filter: brightness(90%); }
  .form-check-input:focus, input:focus[type="radio"], input:focus[type="checkbox"] {
    border-color: #f5cf81;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(235, 158, 3, 0.25); }
  .form-check-input:checked, input:checked[type="radio"], input:checked[type="checkbox"] {
    background-color: transaparent;
    border-color: transaparent; }
    .form-check-input:checked[type="checkbox"], input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23EB9E03' d='M17,5v10c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V5C2.9,3.9,3.9,3,4.9,3h10C16.1,3,17,3.9,17,5z'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"], input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23EB9E03'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate, input[type="checkbox"]:indeterminate {
    background-color: #EB9E03;
    border-color: #EB9E03;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled, input:disabled[type="radio"], input:disabled[type="checkbox"] {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, input[disabled][type="radio"] ~ .form-check-label, input[disabled][type="checkbox"] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label, input:disabled[type="radio"] ~ .form-check-label, input:disabled[type="checkbox"] ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input, .form-switch input[type="radio"], .form-switch input[type="checkbox"] {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input, .form-switch input[type="radio"], .form-switch input[type="checkbox"] {
        transition: none; } }
    .form-switch .form-check-input:focus, .form-switch input:focus[type="radio"], .form-switch input:focus[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f5cf81'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked, .form-switch input:checked[type="radio"], .form-switch input:checked[type="checkbox"] {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input, .form-switch.form-check-reverse input[type="radio"], .form-switch.form-check-reverse input[type="checkbox"] {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(235, 158, 3, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(235, 158, 3, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #EB9E03;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #f9e2b3; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #EB9E03;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #f9e2b3; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075); }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control, .chzn-container .chzn-drop .chzn-search .form-floating > input[type="text"],
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 1.6rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control, .chzn-container .chzn-drop .chzn-search .form-floating > input[type="text"],
  .form-floating > .form-control-plaintext {
    padding: 1rem 1.6rem; }
    .form-floating > .form-control::placeholder, .chzn-container .chzn-drop .chzn-search .form-floating > input[type="text"]::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .chzn-container .chzn-drop .chzn-search .form-floating > input:focus[type="text"], .form-floating > .form-control:not(:placeholder-shown), .chzn-container .chzn-drop .chzn-search .form-floating > input:not(:placeholder-shown)[type="text"],
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill, .chzn-container .chzn-drop .chzn-search .form-floating > input:-webkit-autofill[type="text"],
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label, .chzn-container .chzn-drop .chzn-search .form-floating > input:focus[type="text"] ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .chzn-container .chzn-drop .chzn-search .form-floating > input:not(:placeholder-shown)[type="text"] ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label, .chzn-container .chzn-drop .chzn-search .form-floating > input:-webkit-autofill[type="text"] ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .chzn-container .chzn-drop .chzn-search .input-group > input[type="text"],
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus, .chzn-container .chzn-drop .chzn-search .input-group > input:focus[type="text"],
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1.6rem 1.6rem;
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #F9F7F4;
  border-radius: 0.8rem; }
  @media (min-width: 1200px) {
    .input-group-text {
      font-size: 1.6rem; } }

.input-group-lg > .form-control, .chzn-container .chzn-drop .chzn-search .input-group-lg > input[type="text"],
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 2.4rem; }

.input-group-sm > .form-control, .chzn-container .chzn-drop .chzn-search .input-group-sm > input[type="text"],
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.8rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 8rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.chzn-container .chzn-drop .chzn-search .input-group:not(.has-validation) > .form-floating:not(:last-child) > input[type="text"],
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.chzn-container .chzn-drop .chzn-search .input-group.has-validation > .form-floating:nth-last-child(n + 3) > input[type="text"],
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control, .chzn-container .chzn-drop .chzn-search .input-group > .form-floating:not(:first-child) > input[type="text"],
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #64B375; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: calc(1.285rem + 0.42vw);
  color: #000;
  background-color: rgba(100, 179, 117, 0.9);
  border-radius: 1.6rem; }
  @media (min-width: 1200px) {
    .valid-tooltip {
      font-size: 1.6rem; } }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .chzn-container .chzn-drop .chzn-search input:valid[type="text"], .chzn-container .chzn-drop .chzn-search .was-validated input:valid[type="text"], .form-control.is-valid, .chzn-container .chzn-drop .chzn-search input.is-valid[type="text"] {
  border-color: #64B375;
  padding-right: calc(1.5em + 3.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2364B375' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.8rem) center;
  background-size: calc(0.75em + 1.6rem) calc(0.75em + 1.6rem); }
  .was-validated .form-control:valid:focus, .was-validated .chzn-container .chzn-drop .chzn-search input:valid:focus[type="text"], .chzn-container .chzn-drop .chzn-search .was-validated input:valid:focus[type="text"], .form-control.is-valid:focus, .chzn-container .chzn-drop .chzn-search input.is-valid:focus[type="text"] {
    border-color: #64B375;
    box-shadow: 0 0 0 0.25rem rgba(100, 179, 117, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 3.2rem);
  background-position: top calc(0.375em + 0.8rem) right calc(0.375em + 0.8rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #64B375; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 11rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2364B375' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 2rem center, center right 6rem;
    background-size: 16px 12px, calc(0.75em + 1.6rem) calc(0.75em + 1.6rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #64B375;
    box-shadow: 0 0 0 0.25rem rgba(100, 179, 117, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 3.2rem)); }

.was-validated .form-check-input:valid, .was-validated input:valid[type="radio"], .was-validated input:valid[type="checkbox"], .form-check-input.is-valid, input.is-valid[type="radio"], input.is-valid[type="checkbox"] {
  border-color: #64B375; }
  .was-validated .form-check-input:valid:checked, .was-validated input:valid:checked[type="radio"], .was-validated input:valid:checked[type="checkbox"], .form-check-input.is-valid:checked, input.is-valid:checked[type="radio"], input.is-valid:checked[type="checkbox"] {
    background-color: #64B375; }
  .was-validated .form-check-input:valid:focus, .was-validated input:valid:focus[type="radio"], .was-validated input:valid:focus[type="checkbox"], .form-check-input.is-valid:focus, input.is-valid:focus[type="radio"], input.is-valid:focus[type="checkbox"] {
    box-shadow: 0 0 0 0.25rem rgba(100, 179, 117, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .was-validated input:valid[type="radio"] ~ .form-check-label, .was-validated input:valid[type="checkbox"] ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label, input.is-valid[type="radio"] ~ .form-check-label, input.is-valid[type="checkbox"] ~ .form-check-label {
    color: #64B375; }

.form-check-inline .form-check-input ~ .valid-feedback, .form-check-inline input[type="radio"] ~ .valid-feedback, .form-check-inline input[type="checkbox"] ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .was-validated .chzn-container .chzn-drop .chzn-search .input-group > input:not(:focus):valid[type="text"], .chzn-container .chzn-drop .chzn-search .was-validated .input-group > input:not(:focus):valid[type="text"], .input-group > .form-control:not(:focus).is-valid, .chzn-container .chzn-drop .chzn-search .input-group > input:not(:focus).is-valid[type="text"], .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #E05C5C; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: calc(1.285rem + 0.42vw);
  color: #000;
  background-color: rgba(224, 92, 92, 0.9);
  border-radius: 1.6rem; }
  @media (min-width: 1200px) {
    .invalid-tooltip {
      font-size: 1.6rem; } }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .chzn-container .chzn-drop .chzn-search input:invalid[type="text"], .chzn-container .chzn-drop .chzn-search .was-validated input:invalid[type="text"], .form-control.is-invalid, .chzn-container .chzn-drop .chzn-search input.is-invalid[type="text"] {
  border-color: #E05C5C;
  padding-right: calc(1.5em + 3.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23E05C5C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E05C5C' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.8rem) center;
  background-size: calc(0.75em + 1.6rem) calc(0.75em + 1.6rem); }
  .was-validated .form-control:invalid:focus, .was-validated .chzn-container .chzn-drop .chzn-search input:invalid:focus[type="text"], .chzn-container .chzn-drop .chzn-search .was-validated input:invalid:focus[type="text"], .form-control.is-invalid:focus, .chzn-container .chzn-drop .chzn-search input.is-invalid:focus[type="text"] {
    border-color: #E05C5C;
    box-shadow: 0 0 0 0.25rem rgba(224, 92, 92, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 3.2rem);
  background-position: top calc(0.375em + 0.8rem) right calc(0.375em + 0.8rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #E05C5C; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 11rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23E05C5C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E05C5C' stroke='none'/%3e%3c/svg%3e");
    background-position: right 2rem center, center right 6rem;
    background-size: 16px 12px, calc(0.75em + 1.6rem) calc(0.75em + 1.6rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #E05C5C;
    box-shadow: 0 0 0 0.25rem rgba(224, 92, 92, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 3.2rem)); }

.was-validated .form-check-input:invalid, .was-validated input:invalid[type="radio"], .was-validated input:invalid[type="checkbox"], .form-check-input.is-invalid, input.is-invalid[type="radio"], input.is-invalid[type="checkbox"] {
  border-color: #E05C5C; }
  .was-validated .form-check-input:invalid:checked, .was-validated input:invalid:checked[type="radio"], .was-validated input:invalid:checked[type="checkbox"], .form-check-input.is-invalid:checked, input.is-invalid:checked[type="radio"], input.is-invalid:checked[type="checkbox"] {
    background-color: #E05C5C; }
  .was-validated .form-check-input:invalid:focus, .was-validated input:invalid:focus[type="radio"], .was-validated input:invalid:focus[type="checkbox"], .form-check-input.is-invalid:focus, input.is-invalid:focus[type="radio"], input.is-invalid:focus[type="checkbox"] {
    box-shadow: 0 0 0 0.25rem rgba(224, 92, 92, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .was-validated input:invalid[type="radio"] ~ .form-check-label, .was-validated input:invalid[type="checkbox"] ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, input.is-invalid[type="radio"] ~ .form-check-label, input.is-invalid[type="checkbox"] ~ .form-check-label {
    color: #E05C5C; }

.form-check-inline .form-check-input ~ .invalid-feedback, .form-check-inline input[type="radio"] ~ .invalid-feedback, .form-check-inline input[type="checkbox"] ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .chzn-container .chzn-drop .chzn-search .input-group > input:not(:focus):invalid[type="text"], .chzn-container .chzn-drop .chzn-search .was-validated .input-group > input:not(:focus):invalid[type="text"], .input-group > .form-control:not(:focus).is-invalid, .chzn-container .chzn-drop .chzn-search .input-group > input:not(:focus).is-invalid[type="text"], .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: 0.8rem; }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.8rem;
  padding-left: 1.8rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.8rem;
  padding-left: 1.8rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 2.4rem;
  --bs-nav-link-padding-y: 0.8rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: #373736;
  --bs-nav-link-hover-color: #000;
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 1.6rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: 0.8rem;
  --bs-nav-pills-link-active-color: #000;
  --bs-nav-pills-link-active-bg: #EB9E03; }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius); }
    .nav-pills .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.6125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.8rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.8rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      box-shadow: none;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      box-shadow: none;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      box-shadow: none;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      box-shadow: none;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      box-shadow: none;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.btn {
  --bs-btn-padding-x: 2.4rem;
  --bs-btn-padding-y: 1.2rem;
  --bs-btn-font-family: Urbanist, Open Sans, sans-serif;
  --bs-btn-font-size: calc(1.285rem + 0.42vw);
  --bs-btn-font-weight: 700;
  --bs-btn-line-height: 1.8rem;
  --bs-btn-color: #000;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.8rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0 10px 20px 0 rgba(235, 158, 3, 0.2);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (min-width: 1200px) {
    .btn {
      --bs-btn-font-size: 1.6rem; } }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    box-shadow: var(--bs-btn-active-shadow); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
    box-shadow: none; }

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #EB9E03;
  --bs-btn-border-color: #EB9E03;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #eead29;
  --bs-btn-hover-border-color: #eda81c;
  --bs-btn-focus-shadow-rgb: 200, 134, 3;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #efb135;
  --bs-btn-active-border-color: #eda81c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #EB9E03;
  --bs-btn-disabled-border-color: #EB9E03; }

.btn-primary-dark {
  --bs-btn-color: #000;
  --bs-btn-bg: #C98600;
  --bs-btn-border-color: #C98600;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d19826;
  --bs-btn-hover-border-color: #ce921a;
  --bs-btn-focus-shadow-rgb: 171, 114, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d49e33;
  --bs-btn-active-border-color: #ce921a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #C98600;
  --bs-btn-disabled-border-color: #C98600; }

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #989898;
  --bs-btn-border-color: #989898;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #a7a7a7;
  --bs-btn-hover-border-color: #a2a2a2;
  --bs-btn-focus-shadow-rgb: 129, 129, 129;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #adadad;
  --bs-btn-active-border-color: #a2a2a2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #989898;
  --bs-btn-disabled-border-color: #989898; }

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #64B375;
  --bs-btn-border-color: #64B375;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7bbe8a;
  --bs-btn-hover-border-color: #74bb83;
  --bs-btn-focus-shadow-rgb: 85, 152, 99;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #83c291;
  --bs-btn-active-border-color: #74bb83;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #64B375;
  --bs-btn-disabled-border-color: #64B375; }

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #133C6B;
  --bs-btn-border-color: #133C6B;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #10335b;
  --bs-btn-hover-border-color: #0f3056;
  --bs-btn-focus-shadow-rgb: 54, 89, 129;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0f3056;
  --bs-btn-active-border-color: #0e2d50;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #133C6B;
  --bs-btn-disabled-border-color: #133C6B; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #C0AB77;
  --bs-btn-border-color: #C0AB77;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c9b88b;
  --bs-btn-hover-border-color: #c6b385;
  --bs-btn-focus-shadow-rgb: 163, 145, 101;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cdbc92;
  --bs-btn-active-border-color: #c6b385;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #C0AB77;
  --bs-btn-disabled-border-color: #C0AB77; }

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #E05C5C;
  --bs-btn-border-color: #E05C5C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e57474;
  --bs-btn-hover-border-color: #e36c6c;
  --bs-btn-focus-shadow-rgb: 190, 78, 78;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e67d7d;
  --bs-btn-active-border-color: #e36c6c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #E05C5C;
  --bs-btn-disabled-border-color: #E05C5C; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #F9F7F4;
  --bs-btn-border-color: #F9F7F4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d4d2cf;
  --bs-btn-hover-border-color: #c7c6c3;
  --bs-btn-focus-shadow-rgb: 212, 210, 207;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c7c6c3;
  --bs-btn-active-border-color: #bbb9b7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F9F7F4;
  --bs-btn-disabled-border-color: #F9F7F4; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1A1A1A;
  --bs-btn-border-color: #1A1A1A;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3c3c3c;
  --bs-btn-hover-border-color: #313131;
  --bs-btn-focus-shadow-rgb: 60, 60, 60;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #484848;
  --bs-btn-active-border-color: #313131;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1A1A1A;
  --bs-btn-disabled-border-color: #1A1A1A; }

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff; }

.btn-outline-primary {
  --bs-btn-color: #EB9E03;
  --bs-btn-border-color: #EB9E03;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #EB9E03;
  --bs-btn-hover-border-color: #EB9E03;
  --bs-btn-focus-shadow-rgb: 235, 158, 3;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #EB9E03;
  --bs-btn-active-border-color: #EB9E03;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #EB9E03;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EB9E03;
  --bs-gradient: none; }

.btn-outline-primary-dark {
  --bs-btn-color: #C98600;
  --bs-btn-border-color: #C98600;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #C98600;
  --bs-btn-hover-border-color: #C98600;
  --bs-btn-focus-shadow-rgb: 201, 134, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #C98600;
  --bs-btn-active-border-color: #C98600;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #C98600;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #C98600;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #989898;
  --bs-btn-border-color: #989898;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #989898;
  --bs-btn-hover-border-color: #989898;
  --bs-btn-focus-shadow-rgb: 152, 152, 152;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #989898;
  --bs-btn-active-border-color: #989898;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #989898;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #989898;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #64B375;
  --bs-btn-border-color: #64B375;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #64B375;
  --bs-btn-hover-border-color: #64B375;
  --bs-btn-focus-shadow-rgb: 100, 179, 117;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #64B375;
  --bs-btn-active-border-color: #64B375;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #64B375;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #64B375;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #133C6B;
  --bs-btn-border-color: #133C6B;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #133C6B;
  --bs-btn-hover-border-color: #133C6B;
  --bs-btn-focus-shadow-rgb: 19, 60, 107;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #133C6B;
  --bs-btn-active-border-color: #133C6B;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #133C6B;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #133C6B;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #C0AB77;
  --bs-btn-border-color: #C0AB77;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #C0AB77;
  --bs-btn-hover-border-color: #C0AB77;
  --bs-btn-focus-shadow-rgb: 192, 171, 119;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #C0AB77;
  --bs-btn-active-border-color: #C0AB77;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #C0AB77;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #C0AB77;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #E05C5C;
  --bs-btn-border-color: #E05C5C;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #E05C5C;
  --bs-btn-hover-border-color: #E05C5C;
  --bs-btn-focus-shadow-rgb: 224, 92, 92;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #E05C5C;
  --bs-btn-active-border-color: #E05C5C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #E05C5C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #E05C5C;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #F9F7F4;
  --bs-btn-border-color: #F9F7F4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F9F7F4;
  --bs-btn-hover-border-color: #F9F7F4;
  --bs-btn-focus-shadow-rgb: 249, 247, 244;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F9F7F4;
  --bs-btn-active-border-color: #F9F7F4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F9F7F4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F9F7F4;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #1A1A1A;
  --bs-btn-border-color: #1A1A1A;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1A1A1A;
  --bs-btn-hover-border-color: #1A1A1A;
  --bs-btn-focus-shadow-rgb: 26, 26, 26;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1A1A1A;
  --bs-btn-active-border-color: #1A1A1A;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1A1A1A;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1A1A1A;
  --bs-gradient: none; }

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 200, 134, 3;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 1.6rem;
  --bs-btn-padding-x: 2.4rem;
  --bs-btn-font-size: calc(1.285rem + 0.42vw);
  --bs-btn-border-radius: 0.8rem; }
  @media (min-width: 1200px) {
    .btn-lg, .btn-group-lg > .btn {
      --bs-btn-font-size: 1.6rem; } }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.8rem; }

.card {
  --bs-card-spacer-y: 3.2rem;
  --bs-card-spacer-x: 3.2rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 1.6rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 1.6rem;
  --bs-card-cap-padding-y: 1.6rem;
  --bs-card-cap-padding-x: 3.2rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.badge {
  --bs-badge-padding-x: 1.6rem;
  --bs-badge-padding-y: 0.6rem;
  --bs-badge-font-size: 1.2rem;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 5rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 1.6rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #000;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #EB9E03;
  --bs-list-group-active-border-color: #EB9E03;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #8d5f02;
  background-color: #fbeccd; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #8d5f02;
    background-color: #e2d4b9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #8d5f02;
    border-color: #8d5f02; }

.list-group-item-primary-dark {
  color: #795000;
  background-color: #f4e7cc; }
  .list-group-item-primary-dark.list-group-item-action:hover, .list-group-item-primary-dark.list-group-item-action:focus {
    color: #795000;
    background-color: #dcd0b8; }
  .list-group-item-primary-dark.list-group-item-action.active {
    color: #fff;
    background-color: #795000;
    border-color: #795000; }

.list-group-item-secondary {
  color: #5b5b5b;
  background-color: #eaeaea; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #5b5b5b;
    background-color: lightgray; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #5b5b5b;
    border-color: #5b5b5b; }

.list-group-item-success {
  color: #3c6b46;
  background-color: #e0f0e3; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #3c6b46;
    background-color: #cad8cc; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #3c6b46;
    border-color: #3c6b46; }

.list-group-item-info {
  color: #0b2440;
  background-color: #d0d8e1; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0b2440;
    background-color: #bbc2cb; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0b2440;
    border-color: #0b2440; }

.list-group-item-warning {
  color: #736747;
  background-color: #f2eee4; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #736747;
    background-color: #dad6cd; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #736747;
    border-color: #736747; }

.list-group-item-danger {
  color: #863737;
  background-color: #f9dede; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #863737;
    background-color: #e0c8c8; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #863737;
    border-color: #863737; }

.list-group-item-light {
  color: #646362;
  background-color: #fefdfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #646362;
    background-color: #e5e4e4; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #646362;
    border-color: #646362; }

.list-group-item-dark {
  color: #101010;
  background-color: #d1d1d1; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #101010;
    background-color: #bcbcbc; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #101010;
    border-color: #101010; }

.list-group-item-white {
  color: #666666;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #666666;
    background-color: #e6e6e6; }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 1.6rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(235, 158, 3, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 100%;
  --bs-dropdown-padding-x: 2.4rem;
  --bs-dropdown-padding-y: 2.4rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: calc(1.285rem + 0.42vw);
  --bs-dropdown-color: #989898;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 1.6rem;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: 0;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #EB9E03;
  --bs-dropdown-link-hover-bg: #F9F7F4;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #EB9E03;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #989898;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 2.4rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow); }
  @media (min-width: 1200px) {
    .dropdown-menu {
      --bs-dropdown-font-size: 1.6rem; } }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #373736;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #EB9E03;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #F9F7F4;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: none;
  --bs-accordion-border-radius: 0.8rem;
  --bs-accordion-inner-border-radius: 0.8rem;
  --bs-accordion-btn-padding-x: 2.4rem;
  --bs-accordion-btn-padding-y: 2.4rem;
  --bs-accordion-btn-color: #000;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-accordion-btn-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 2.4rem;
  --bs-accordion-body-padding-y: 2.4rem;
  --bs-accordion-active-color: var(--bs-accordion-btn-color);
  --bs-accordion-active-bg: var(--bs-accordion-btn-bg); }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.6125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.8rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.8rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      box-shadow: none;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      box-shadow: none;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      box-shadow: none;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      box-shadow: none;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      box-shadow: none;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.table, table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: #fff;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > *, table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 0;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody, table > tbody {
    vertical-align: inherit; }
  .table > thead, table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: 0 solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 0 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 0; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > *, table > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #fbeccd;
  --bs-table-border-color: #e2d4b9;
  --bs-table-striped-bg: #eee0c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2d4b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8dabe;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #eaeaea;
  --bs-table-border-color: lightgray;
  --bs-table-striped-bg: #dedede;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: lightgray;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d8d8d8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #e0f0e3;
  --bs-table-border-color: #cad8cc;
  --bs-table-striped-bg: #d5e4d8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cad8cc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfded2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #d0d8e1;
  --bs-table-border-color: #bbc2cb;
  --bs-table-striped-bg: #c6cdd6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbc2cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0c8d0;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #f2eee4;
  --bs-table-border-color: #dad6cd;
  --bs-table-striped-bg: #e6e2d9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dad6cd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e0dcd3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f9dede;
  --bs-table-border-color: #e0c8c8;
  --bs-table-striped-bg: #edd3d3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0c8c8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6cdcd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #F9F7F4;
  --bs-table-border-color: #e0dedc;
  --bs-table-striped-bg: #edebe8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0dedc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6e4e2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #1A1A1A;
  --bs-table-border-color: #313131;
  --bs-table-striped-bg: #252525;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #313131;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #2b2b2b;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05); }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y)); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 1.6rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(235, 158, 3, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #EB9E03;
  --bs-pagination-active-border-color: #EB9E03;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 2.4rem; }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.8rem; }

.link-primary {
  color: #EB9E03 !important; }
  .link-primary:hover, .link-primary:focus {
    color: #efb135 !important; }

.link-primary-dark {
  color: #C98600 !important; }
  .link-primary-dark:hover, .link-primary-dark:focus {
    color: #d49e33 !important; }

.link-secondary {
  color: #989898 !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #adadad !important; }

.link-success {
  color: #64B375 !important; }
  .link-success:hover, .link-success:focus {
    color: #83c291 !important; }

.link-info {
  color: #133C6B !important; }
  .link-info:hover, .link-info:focus {
    color: #0f3056 !important; }

.link-warning {
  color: #C0AB77 !important; }
  .link-warning:hover, .link-warning:focus {
    color: #cdbc92 !important; }

.link-danger {
  color: #E05C5C !important; }
  .link-danger:hover, .link-danger:focus {
    color: #e67d7d !important; }

.link-light {
  color: #F9F7F4 !important; }
  .link-light:hover, .link-light:focus {
    color: #faf9f6 !important; }

.link-dark {
  color: #1A1A1A !important; }
  .link-dark:hover, .link-dark:focus {
    color: #151515 !important; }

.link-white {
  color: #fff !important; }
  .link-white:hover, .link-white:focus {
    color: white !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

/* ====== obligatoire
*/
/* ====== optionnel
*/
/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0; }

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer; }

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor; }

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor; }

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0); }

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 * 1. Required for `span` with background image
 * 2. Required for `image`
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  /* 1 */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  /* 2 */
  object-fit: scale-down;
  max-width: none; }

/* Style modifiers
 ========================================================================== */
/*
 * Link
 * 1. Allow text within link
 */
.uk-icon-link {
  color: #999;
  /* 1 */
  text-decoration: none !important; }

.uk-icon-link:hover {
  color: #666; }

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959; }

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover */
.uk-icon-button:hover {
  background-color: #ebebeb;
  color: #666; }

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666; }

/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999; }

/* Hover */
.uk-close:hover {
  color: #666; }

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px; } }

/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1; }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden; }

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. `!important` is needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: 100% !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform; }

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0); }

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px; }

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none; }

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0); }

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 20px 20px; }

.uk-modal-header {
  display: flow-root;
  padding: 10px 20px;
  background: #f8f8f8; }

.uk-modal-footer {
  display: flow-root;
  padding: 10px 20px;
  background: #f8f8f8; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal-body {
    padding: 30px 30px; }
  .uk-modal-header {
    padding: 15px 30px; }
  .uk-modal-footer {
    padding: 15px 30px; } }

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0; }

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.5; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*='uk-modal-close-'] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px; }

/*
 * Remove margin from adjacent element
 */
[class*='uk-modal-close-']:first-child + * {
  margin-top: 0; }

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white; }

.uk-modal-close-outside:hover {
  color: #fff; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%); } }

/*
 * Full
 */
/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom; }

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1; }

/*
 * Focus
 */
.uk-lightbox :focus {
  outline-color: rgba(255, 255, 255, 0.7); }

.uk-lightbox :focus-visible {
  outline-color: rgba(255, 255, 255, 0.7); }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden; }

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity; }

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh; }

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto; }

.uk-lightbox-items > .uk-active {
  display: flex; }

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7); }

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7); }

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7); }

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff; }

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover */
.uk-lightbox-button:hover {
  color: #fff; }

/* OnClick */
/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none; }

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%; }

/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slideshow {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Items
 ========================================================================== */
/*
 * 1. Create position and stacking context
 * 2. Reset list
 * 3. Clip child elements
 * 4. Prevent displaying the callout information on iOS.
 * 5. Disable horizontal panning gestures
 */
.uk-slideshow-items {
  /* 1 */
  position: relative;
  z-index: 0;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  overflow: hidden;
  /* 4 */
  -webkit-touch-callout: none;
  /* 5 */
  touch-action: pan-y; }

/* Item
 ========================================================================== */
/*
 * 1. Position items above each other
 * 2. Take the full width
 * 3. Clip child elements, e.g. for `uk-cover`
 * 4. Optimize animation
 */
.uk-slideshow-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  right: 0;
  bottom: 0;
  /* 3 */
  overflow: hidden;
  /* 4 */
  will-change: transform, opacity; }

/*
 * Hide not active items
 */
.uk-slideshow-items > :not(.uk-active) {
  display: none; }

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slider {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Container
 ========================================================================== */
/*
 * Clip child elements
 */
.uk-slider-container {
  overflow: hidden; }

/*
 * Widen container to prevent box-shadows from clipping, `large-box-shadow`
 */
.uk-slider-container-offset {
  margin: -11px -25px -39px -25px;
  padding: 11px 25px 39px 25px; }

/* Items
 ========================================================================== */
/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 * 3. Disable horizontal panning gestures
 */
.uk-slider-items {
  /* 1 */
  will-change: transform;
  /* 2 */
  position: relative;
  /* 3 */
  touch-action: pan-y; }

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */
.uk-slider-items:not(.uk-grid) {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 2 */
  -webkit-touch-callout: none; }

.uk-slider-items.uk-grid {
  flex-wrap: nowrap; }

/* Item
 ========================================================================== */
/*
 * 1. Let items take content dimensions (0 0 auto)
 *    `max-width` needed to keep image responsiveness and prevent content overflow
 * 3. Create position context
 */
.uk-slider-items > * {
  /* 1 */
  flex: none;
  max-width: 100%;
  /* 3 */
  position: relative; }

/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. Create position context so it's t the same like when fixed.
 * 2. Create stacking context already when not sticky to have the same context
*     for position set to `sticky` and `relative`
 * 2. More robust if padding and border are used and the sticky height is transitioned
 */
.uk-sticky {
  /* 1 */
  position: relative;
  /* 2 */
  z-index: 980;
  /* 3 */
  box-sizing: border-box; }

/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */
.uk-sticky-fixed {
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/*
 * Faster animations
 */
.uk-sticky[class*='uk-animation-'] {
  animation-duration: 0.2s; }

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s; }

/*
 * Placeholder
 * Make content clickable for sticky cover and reveal effects
 */
.uk-sticky-placeholder {
  pointer-events: none; }

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto; }

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  /* 2 */
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #222;
  /* 3 */
  overflow-y: auto; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 30px 30px; } }

/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px; } }

/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0; }

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0; }

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out; }

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right; }

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out; }

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0; }

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0; }

.uk-open > .uk-offcanvas-reveal {
  width: 270px; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px; } }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 5px;
  right: 5px;
  padding: 5px; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-close {
    top: 10px;
    right: 10px; } }

/*
 * Remove margin from adjacent element
 */
.uk-offcanvas-close:first-child + * {
  margin-top: 0; }

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none; }

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear; }

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1; }

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 * 1. `clip` is needed for `position: sticky` elements to keep their position
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden;
  /* 1 */
  overflow-x: clip; }

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%; }

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px; }

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px; }
  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px; } }

/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px; }

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px; }

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px; }

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px; }

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0; } }

/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer; }

* + .uk-notification-message {
  margin-top: 10px; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px; }

.uk-notification-message:hover .uk-notification-close {
  display: block; }

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #1e87f0; }

/*
 * Success
 */
.uk-notification-message-success {
  color: #32d296; }

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #faa05a; }

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #f0506e; }

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  --uk-position-offset: 1.5rem;
  --uk-position-viewport-offset: 10;
  /* 3 */
  top: 0;
  /* 4 */
  box-sizing: border-box;
  max-width: 280px;
  padding: 2.4rem 2.4rem;
  /* 5 */
  background: #fff;
  border-radius: 1.6rem;
  color: #000;
  font-size: 1.6rem; }

/* Show */
.uk-tooltip.uk-active {
  display: block; }

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*='uk-animation-'] {
  animation: 0.5s ease-out both; }

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear; }

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade, uk-scale-up; }

.uk-animation-scale-down {
  animation-name: uk-fade, uk-scale-down; }

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade, uk-slide-top; }

.uk-animation-slide-bottom {
  animation-name: uk-fade, uk-slide-bottom; }

.uk-animation-slide-left {
  animation-name: uk-fade, uk-slide-left; }

.uk-animation-slide-right {
  animation-name: uk-fade, uk-slide-right; }

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade, uk-slide-top-small; }

.uk-animation-slide-bottom-small {
  animation-name: uk-fade, uk-slide-bottom-small; }

.uk-animation-slide-left-small {
  animation-name: uk-fade, uk-slide-left-small; }

.uk-animation-slide-right-small {
  animation-name: uk-fade, uk-slide-right-small; }

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade, uk-slide-top-medium; }

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade, uk-slide-bottom-medium; }

.uk-animation-slide-left-medium {
  animation-name: uk-fade, uk-slide-left-medium; }

.uk-animation-slide-right-medium {
  animation-name: uk-fade, uk-slide-right-medium; }

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-kenburns;
  animation-duration: 15s; }

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake; }

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  animation-duration: 2s;
  stroke-dasharray: var(--uk-animation-stroke); }

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in; }

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s; }

/* Toggle animation based on the State of the Parent Element
 ========================================================================== */
.uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
  animation-name: none; }

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
 * Scale
 */
@keyframes uk-scale-up {
  0% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes uk-scale-down {
  0% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

/*
 * Slide
 */
@keyframes uk-slide-top {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

/*
 * Slide Small
 */
@keyframes uk-slide-top-small {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom-small {
  0% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left-small {
  0% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right-small {
  0% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

/*
 * Slide Medium
 */
@keyframes uk-slide-top-medium {
  0% {
    transform: translateY(-50px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom-medium {
  0% {
    transform: translateY(50px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left-medium {
  0% {
    transform: translateX(-50px); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right-medium {
  0% {
    transform: translateX(50px); }
  100% {
    transform: translateX(0); } }

/*
 * Kenburns
 */
@keyframes uk-kenburns {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }

/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0); }
  10% {
    transform: translateX(-9px); }
  20% {
    transform: translateX(8px); }
  30% {
    transform: translateX(-7px); }
  40% {
    transform: translateX(6px); }
  50% {
    transform: translateX(-5px); }
  60% {
    transform: translateX(4px); }
  70% {
    transform: translateX(-3px); }
  80% {
    transform: translateX(2px); }
  90% {
    transform: translateX(-1px); } }

/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke); }
  100% {
    stroke-dashoffset: 0; } }

/* déclaration d'une fonction

@mixin truc($variable){
	color:$variable;
}

////* appel de la fonction

.class{
	@include truc(#000);
}

/* déclaration d'une procédure

%ma-fonction{
	color:#000;
}

////* appel de la procédure

.class{
	@extend %ma-fonction;
	border:0;
}


// fonctions déjà existantes
 
 - lighten($color, $amount) : Makes a color lighter.
 - darken($color, $amount) : Makes a color darker.
 - saturate($color, $amount) : Makes a color more saturated.
 - desaturate($color, $amount) : Makes a color less saturated.
 
Le tout sur ce site : http://sass-lang.com/documentation/Sass/Script/Functions.html
 
*/
/* ========= mixin ico utilisé pour faire fonctionner l'icons generated dans le 4
*/
/* ========= appel des fonts
*/
/* =======

  css à placer sur les balises html générale avant de rentrer dans le détail du dossier 5.layout et 6.blocs

======= */
* {
  scrollbar-width: 0.8rem;
  scrollbar-color: #EB9E03; }

*::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem; }

*::-webkit-scrollbar-thumb {
  background: #EB9E03;
  border-radius: 5rem;
  visibility: hidden; }

*::-webkit-scrollbar-track {
  background: transparent; }

* :hover::-webkit-scrollbar-thumb {
  visibility: visible; }

::-moz-selection {
  color: #fff;
  background: #C98600; }

::selection {
  color: #fff;
  background: #C98600; }

html {
  font-size: .625rem;
  height: 100%; }

body {
  font-family: Inter, "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #000;
  height: 100%; }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    body main div,
    body main p {
      font-size: 2rem; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    body main ol li,
    body main ul li {
      font-size: 2rem; } }

table {
  margin-top: 30px; }
  table td {
    padding: 1.5rem !important; }

sup {
  font-size: 60%; }

.invalid {
  color: #dc3545;
  font-weight: bold; }

input.invalid {
  border: 1px solid #dc3545; }

a {
  font-family: Urbanist, "Open Sans", sans-serif;
  font-weight: 700;
  text-decoration: none; }

img,
svg {
  vertical-align: middle; }

.tarteaucitronIconBottomRight {
  bottom: 140px !important; }

.grecaptcha-badge {
  bottom: 80px !important; }

.rl_tooltips.popover {
  font-size: 14px;
  line-height: 20px;
  max-width: 800px !important; }

@media (min-width: 768px) and (max-width: 1399.98px) {
  .page-header {
    margin-bottom: 30px; } }

.optional {
  display: none; }

.form-actions-bar {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.6rem 2.4rem;
  text-align: right;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.06); }

.form-check-input:checked, input:checked[type="radio"], input:checked[type="checkbox"] {
  background-color: transparent !important; }

@media (min-width: 768px) and (max-width: 1399.98px) {
  label {
    font-size: 2rem; } }

@media (min-width: 768px) and (max-width: 1399.98px) {
  .control-group {
    margin-top: 15px; } }

@media (min-width: 768px) and (max-width: 1399.98px) {
  input {
    width: 50%; } }

input[type="radio"], input[type="checkbox"] {
  padding: 0; }

@media (min-width: 768px) and (max-width: 1399.98px) {
  textarea {
    width: 50%; } }

.calendar-container table {
  max-width: 500px; }

.contact-form form .control-group {
  margin-bottom: 10px; }

@media (max-width: 1199.98px) {
  .form-control, .chzn-container .chzn-drop .chzn-search input[type="text"], .chzn-container .chzn-drop .chzn-search input[type="text"] {
    font-size: var(--bs-nav-link-font-size); } }

img,
svg {
  max-width: 100%;
  vertical-align: text-bottom; }

a img {
  border: 0; }

a:hover img, a:focus img, a:active img {
  opacity: 0.8; }

[class^="ico-"],
[class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icons, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1.5;
  color: inherit;
  text-transform: none;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  [class^="ico-"]::before,
  [class*=" ico-"]::before {
    display: inline-block;
    font-family: icons, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
    text-decoration: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  [class^="ico-"]:hover,
  [class*=" ico-"]:hover {
    text-decoration: none; }

@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format("eot"), url("../fonts/icons.woff2") format("woff2"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg"); }

.ico:before {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none; }

.ico-book:before {
  content: "\E001"; }

.ico-clock:before {
  content: "\E002"; }

.ico-error:before {
  content: "\E003"; }

.ico-file:before {
  content: "\E004"; }

.ico-help:before {
  content: "\E005"; }

.ico-logo-skazy:before {
  content: "\E006"; }

.ico-mods:before {
  content: "\E007"; }

.ico-print:before {
  content: "\E008"; }

.ico-ticket:before {
  content: "\E009"; }

.ico-valid:before {
  content: "\E00A"; }

.ico-wait:before {
  content: "\E00B"; }

h1, .h1 {
  font-family: Urbanist, "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 6.4rem; }

h2, .h2 {
  font-family: Urbanist, "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 4.2rem; }

h3, .h3 {
  font-family: Urbanist, "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 2rem; }

h4, .h4 {
  font-family: Inter, "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  line-height: 1.7rem; }

h5, .h5 {
  font-family: Inter, "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem; }

.h1 {
  font-family: Urbanist, "Open Sans", sans-serif !important;
  font-size: 6.4rem !important;
  font-weight: 700 !important; }

.h2 {
  font-family: Urbanist, "Open Sans", sans-serif !important;
  font-size: 3.2rem !important;
  font-weight: 700 !important;
  line-height: 4.2rem !important; }

.h3 {
  font-family: Urbanist, "Open Sans", sans-serif !important;
  font-size: 2rem !important;
  font-weight: 700 !important; }

.h4 {
  font-family: Inter, "Open Sans", sans-serif !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.2rem !important;
  line-height: 1.7rem !important; }

.h5 {
  font-family: Inter, "Open Sans", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.1rem !important; }

.bannergroup_bandeaux:after {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  inset: 0 0 0 0; }

.bannergroup_bandeaux #slider_custom ul li img {
  min-height: calc(100vh - 143px); }

#message-maintenance {
  display: flex;
  background: #FFF3CD;
  align-items: center;
  justify-content: center;
  color: #856404;
  font-family: Inter;
  padding: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; }
  #message-maintenance p {
    margin: 0; }
  #message-maintenance .en-savoir-plus {
    color: #856404;
    font-family: Inter;
    text-align: center;
    margin-left: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
    text-decoration-line: underline; }

.card-sm {
  --bs-card-spacer-y: 2.4rem;
  --bs-card-spacer-x: 2.4rem; }

.card-lg {
  --bs-card-spacer-y: 4.8rem;
  --bs-card-spacer-x: 4.8rem; }

.card-ul-child .card-body ul {
  margin: 5px 0 0;
  padding-left: 15px; }
  .card-ul-child .card-body ul li {
    margin: 10px 0 0;
    position: relative; }
    .card-ul-child .card-body ul li:before {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      left: -15px;
      top: 9px;
      background-color: #EB9E03;
      border-radius: 10px; }

#userForm {
  background-color: #fff;
  border-radius: 1.6rem; }

.form-row {
  --bs-gutter-x: 1.6rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  padding: 0 1rem;
  margin: 0 -2rem; }
  .form-row > div {
    padding: 0 1rem; }
    .form-row > div label {
      margin: 1rem 0; }

.btn {
  box-shadow: none; }
  .btn-back-to-top {
    bottom: 15%;
    right: 20px; }
  .btn.disabled {
    opacity: 0.1; }

@media (max-width: 1199.98px) {
  .btn {
    padding: 0.8rem;
    font-size: var(--bs-nav-link-font-size); } }

.nav.menu .parent:hover .dropdown-menu {
  display: block; }

.nav.menu .default .nav-link {
  padding-left: 0; }

.menu-burger span, .menu-burger span:after, .menu-burger span:before {
  display: block;
  width: 20px;
  height: 2px;
  background-color: #000; }

.menu-burger span {
  position: relative;
  margin: 10px 0; }
  .menu-burger span:before, .menu-burger span:after {
    content: '';
    position: absolute;
    left: 0; }
  .menu-burger span:before {
    bottom: 6px; }
  .menu-burger span:after {
    top: 6px; }

.menu-accueil {
  gap: 2.4rem;
  margin-right: 2.4rem;
  margin-bottom: 2.4rem;
  align-items: center; }
  @media (min-width: 992px) and (max-width: 1399.98px) {
    .menu-accueil {
      padding-right: 10px; } }
  @media (max-width: 767.98px) {
    .menu-accueil {
      margin-right: 0; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .menu-accueil li {
      margin-bottom: 15px;
      font-size: 2rem; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .menu-accueil li {
      font-size: 1.8rem; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .menu-accueil li:last-child {
      margin-bottom: 0; } }
  .menu-accueil a:not(.btn) {
    color: #fff; }
    .menu-accueil a:not(.btn):hover {
      color: #EB9E03; }

@media (min-width: 768px) and (max-width: 1399.98px) {
  body:not(.dashboard) .brand {
    margin-left: 20px; } }

@media (max-width: 991.98px) {
  body:not(.dashboard) .brand {
    margin-left: 10px;
    position: absolute;
    top: 0;
    left: 0; } }

@media (max-width: 767.98px) {
  body:not(.dashboard) .brand {
    position: relative; } }

body:not(.dashboard) .brand img {
  height: 90px; }

.acces-espace {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: #fff;
  text-align: center; }
  .acces-espace .nav {
    margin-top: 3.2rem;
    display: flex;
    justify-content: center; }
    .acces-espace .nav .nav-item {
      flex: 0 0 auto;
      width: 50%; }
      .acces-espace .nav .nav-item:nth-child(1) {
        padding-right: 1.2rem; }
      .acces-espace .nav .nav-item:nth-child(2) {
        padding-left: 1.2rem; }
    .acces-espace .nav .nav-link {
      display: block;
      background: #fff;
      padding: 2.4rem;
      border-radius: 2.4rem;
      color: #000;
      font-weight: 700;
      font-size: 2.4rem;
      font-family: Urbanist, "Open Sans", sans-serif;
      line-height: 2.9rem; }
      @media (max-width: 767.98px) {
        .acces-espace .nav .nav-link {
          padding-left: 0;
          padding-right: 0; } }
      .acces-espace .nav .nav-link:hover {
        background: #EB9E03; }
      .acces-espace .nav .nav-link img {
        display: block;
        margin: auto;
        margin-bottom: 1.2rem; }
      .acces-espace .nav .nav-link span {
        display: inline-block;
        max-width: 130px; }

.pre-footer {
  background: #000; }
  .pre-footer .menu_menufooter li {
    padding: 5px 10px; }

@media (max-width: 767.98px) {
  .mod-languages_langue {
    position: absolute;
    top: 0;
    right: 0; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .menu-accueil {
    gap: 0;
    margin: 0;
    margin-top: 70px;
    margin-bottom: 1.2rem; }
    .menu-accueil .nav-link {
      font-size: 1.6rem;
      padding-left: 10px;
      padding-right: 10px; }
      .menu-accueil .nav-link.btn-primary {
        margin-right: 1.2rem; } }

.dashboard {
  padding-left: 0;
  padding-right: 0;
  transition: all 0.3s; }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard .ico-file,
    .dashboard .ico-print,
    .dashboard .ico-mods {
      color: #EB9E03; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard label {
      font-size: 1.4rem; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #edit-salarie {
      padding: 24px 32px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #edit-salarie #jform_a_cafat,
    .dashboard #edit-salarie #jform_est_etudiant {
      display: flex; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #edit-salarie #jform_a_cafat .form-check,
    .dashboard #edit-salarie #jform_est_etudiant .form-check {
      display: flex;
      align-items: baseline; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #edit-salarie #jform_a_cafat .form-check label,
    .dashboard #edit-salarie #jform_est_etudiant .form-check label {
      margin-left: 10px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #edit-salarie .form-check-label {
      font-size: 1.6rem; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard-login {
      padding-left: 100px; } }
  .dashboard-login #zone-quizz .quizz-39 .draggable p,
  .dashboard-login #zone-quizz .quizz-40 .draggable p {
    color: #414141;
    padding: 0 25px; }
  .dashboard-login #zone-quizz .quizz-39 .draggable img {
    height: 100px;
    max-width: fit-content; }
  .dashboard-login #zone-quizz .quizz-40 .draggable img {
    height: 90px;
    max-width: fit-content; }
  .dashboard-login #menu {
    position: fixed;
    z-index: 20; }
    @media (min-width: 1400px) {
      .dashboard-login #menu {
        width: 360px; } }
  @media (min-width: 1400px) {
    .dashboard-login #main {
      padding-left: 360px; } }
  .dashboard-login #jform_privacyconsent_privacy {
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #989898;
    width: 16px;
    height: 16px;
    flex: inherit;
    margin-right: 8px; }
  .dashboard-login #jform_privacyconsent_privacy label {
    padding-left: 0;
    margin-right: 35px; }
  .dashboard-login #member-profile legend {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px; }
  .dashboard-login #charte-employeur li {
    list-style: none; }
    .dashboard-login #charte-employeur li:before {
      content: "";
      border-radius: 4px;
      width: 4px;
      height: 4px;
      background-color: #EB9E03;
      margin-right: 8px; }
  .dashboard-login #charte-employeur #accepter_charte {
    margin-right: 4px; }
  .dashboard-login .profile {
    background-color: #F9F7F4;
    color: #1A1A1A;
    font-size: 2rem;
    font-weight: 700;
    font-family: Urbanist; }
    .dashboard-login .profile .initiale {
      font-size: 3.2rem;
      line-height: 42px;
      padding: 10px;
      border-radius: 128px;
      color: #fff;
      background-color: #1A1A1A;
      width: 56px;
      height: 56px;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center; }
  .dashboard-login #choisir-employeur .employeur-item {
    margin-bottom: 16px; }
    .dashboard-login #choisir-employeur .employeur-item:last-child {
      margin-bottom: 0; }
  .dashboard-login #formations_employeur .avancement {
    width: min-content;
    text-align: center;
    top: 32px;
    right: 32px; }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #reponses {
      padding: 40px 25px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #reponses > .btn {
      padding: 0;
      margin-bottom: 30px !important; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #reponses > .card-body {
      padding: 40px 48px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #reponses .accordion-button {
      padding: 20px 24px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #reponses .accordion-button span {
      display: flex;
      align-items: center; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #reponses ul li,
    .dashboard #reponses p {
      line-height: 25px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #reponses .text-secondary {
      font-size: 1.4rem; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
      left: 330px;
      padding-left: 0; } }
  .dashboard-menu {
    height: 100vh;
    top: 0;
    z-index: 1;
    overflow: auto; }
  .dashboard-main {
    min-height: 100vh; }
    @media (min-width: 768px) and (max-width: 1399.98px) {
      .dashboard-main .carousel-inner {
        background-position: center !important; } }
  .dashboard-logo {
    display: block;
    margin: 0 auto;
    width: 8.2rem; }
  .dashboard-title-wrapper {
    margin-top: 5vh; }
  .dashboard-breadcrumb .nav-item {
    color: #989898;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 2.4rem; }
    .dashboard-breadcrumb .nav-item:before {
      content: '';
      width: 2rem;
      height: 2rem;
      display: inline-block;
      margin-right: .8rem;
      background-position: center;
      background-repeat: no-repeat; }
    .dashboard-breadcrumb .nav-item.active {
      color: #fff; }
      .dashboard-breadcrumb .nav-item.active:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxOCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC45MTExIDAuNDEwNzYyQzExLjIzNjUgMC4wODUzMjY3IDExLjc2NDIgMC4wODUzMjg5IDEyLjA4OTYgMC40MTA3NjdMMTcuMDg5NiA1LjQxMDc3QzE3LjQxNSA1LjczNjIgMTcuNDE1IDYuMjYzODQgMTcuMDg5NiA2LjU4OTI3TDEyLjA4OTYgMTEuNTg5M0MxMS43NjQyIDExLjkxNDcgMTEuMjM2NSAxMS45MTQ3IDEwLjkxMTEgMTEuNTg5M0MxMC41ODU3IDExLjI2MzggMTAuNTg1NyAxMC43MzYyIDEwLjkxMTEgMTAuNDEwOEwxNC40ODg1IDYuODMzMzVIMS41MDAzM0MxLjA0MDA5IDYuODMzMzUgMC42NjY5OTIgNi40NjAyNiAwLjY2Njk5MiA2LjAwMDAyQzAuNjY2OTkyIDUuNTM5NzggMS4wNDAwOSA1LjE2NjY5IDEuNTAwMzMgNS4xNjY2OUgxNC40ODg1TDEwLjkxMTEgMS41ODkyN0MxMC41ODU3IDEuMjYzODQgMTAuNTg1NyAwLjczNjE5OCAxMC45MTExIDAuNDEwNzYyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=="); }
    .dashboard-breadcrumb .nav-item.validated {
      color: #64B375; }
      .dashboard-breadcrumb .nav-item.validated:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTMuNTE4MzUgMy41MTgxQzUuMTc2MyAxLjg2MDE4IDcuNDY5MSAwLjgzMzI1MiAxMC4wMDAyIDAuODMzMjUyQzEyLjUzMTIgMC44MzMyNTIgMTQuODI0IDEuODYwMTggMTYuNDgyIDMuNTE4MUMxOC4xMzk5IDUuMTc2MDUgMTkuMTY2OCA3LjQ2ODg2IDE5LjE2NjggOS45OTk5MkMxOS4xNjY4IDEyLjUzMSAxOC4xMzk5IDE0LjgyMzggMTYuNDgyIDE2LjQ4MTdDMTQuODI0IDE4LjEzOTYgMTIuNTMxMiAxOS4xNjY2IDEwLjAwMDIgMTkuMTY2NkM3LjQ2OTEgMTkuMTY2NiA1LjE3NjMgMTguMTM5NiAzLjUxODM1IDE2LjQ4MTdDMS44NjA0MyAxNC44MjM4IDAuODMzNDk2IDEyLjUzMSAwLjgzMzQ5NiA5Ljk5OTkyQzAuODMzNDk2IDcuNDY4ODYgMS44NjA0NCA1LjE3NjA0IDMuNTE4MzUgMy41MTgxWk0xNC43NTYxIDguMDg5MTdDMTUuMDgxNSA3Ljc2Mzc0IDE1LjA4MTUgNy4yMzYxIDE0Ljc1NjEgNi45MTA2NkMxNC40MzA2IDYuNTg1MjMgMTMuOTAzIDYuNTg1MjMgMTMuNTc3NiA2LjkxMDY2TDkuMTY2ODMgMTEuMzIxNEw3LjI1NjA4IDkuNDEwNjZDNi45MzA2NSA5LjA4NTIzIDYuNDAzMDEgOS4wODUyMyA2LjA3NzU3IDkuNDEwNjZDNS43NTIxNCA5LjczNjEgNS43NTIxNCAxMC4yNjM3IDYuMDc3NTcgMTAuNTg5Mkw4LjU3NzU3IDEzLjA4OTJDOC45MDMwMSAxMy40MTQ2IDkuNDMwNjUgMTMuNDE0NiA5Ljc1NjA4IDEzLjA4OTJMMTQuNzU2MSA4LjA4OTE3WiIgZmlsbD0iIzY0QjM3NSIvPjwvc3ZnPg=="); }
  .dashboard .login {
    margin-top: 65px;
    padding: 0 50px; }
    .dashboard .login label {
      font-family: Inter;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 8px; }
    .dashboard .login input {
      font-family: Inter;
      font-size: 1.6rem;
      font-weight: 400;
      padding: 16px;
      color: #989898;
      background-color: #F9F7F4; }
    .dashboard .login .btn {
      font-family: Inter;
      font-weight: 600; }
    .dashboard .login a {
      font-family: Inter;
      font-size: 1.4rem; }
  .dashboard-offline .dashboard-logo {
    width: 16rem; }
  .dashboard-offline .dashboard-title {
    margin-top: 40px; }
  .dashboard:not(.dashboard-offline) .dashboard-breadcrumb {
    margin-top: 7vh; }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard .overflow-tablet {
      overflow: hidden; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard .ps-header {
      padding: 16px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard .filtre_statut_passage {
      width: 10%; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard select,
    .dashboard input {
      font-family: Inter;
      font-size: 1.4rem;
      font-weight: 500;
      color: #989898; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard select {
      background-color: #fff; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard .btn_action_employeur {
      height: 100%;
      display: flex; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #filtre-salarie h3, .dashboard #filtre-salarie .h3 {
      display: none; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard .menu-chapitre li {
      margin-bottom: 15px; }
      .dashboard .menu-chapitre li:last-child {
        margin-bottom: 0; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard .actions-salaries a,
    .dashboard #tab-vue-detail-modules,
    .dashboard #tab-vue-generale {
      font-size: 1.6rem; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #tab-vue-generale,
    .dashboard #tab-vue-detail-modules {
      padding: 12px 24px;
      color: #989898; } }
  .dashboard #tab-vue-generale.active,
  .dashboard #tab-vue-detail-modules.active {
    color: #000; }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard .actions-salaries li {
      margin-right: 30px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard .actions-salaries li:last-child {
      margin-right: 0px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard .actions-salaries a {
      padding: 0; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard table {
      margin-top: 0;
      padding: 24px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard table th {
      padding: 12px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard table th:first-child {
      padding: 12px;
      text-align: center; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard table th:first-child div {
      display: none; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard table td {
      padding: 0 12px !important;
      background-color: #fff !important;
      font-family: Inter;
      font-size: 1.4rem;
      font-weight: 500; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard table td span {
      font-family: Inter;
      font-size: 1.4rem;
      font-weight: 400; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard table td .badge,
    .dashboard table td .badge span {
      font-family: Inter;
      font-size: 1.2rem;
      font-weight: 700; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard table td .badge {
      padding: 8px 16px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard table .show-hide-password {
      width: 16px;
      height: 16px;
      right: -7px;
      position: relative;
      top: -1px; } }
  .dashboard table #show-hide-password {
    display: flex; }
    .dashboard table #show-hide-password img {
      width: 16px;
      height: 16px;
      object-fit: none; }
      @media (min-width: 768px) and (max-width: 1399.98px) {
        .dashboard table #show-hide-password img {
          width: 16px;
          height: 16px;
          right: 8px;
          position: absolute; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #liste-modules-complementaires table td {
      padding-top: 12px !important;
      padding-bottom: 12px !important; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #liste-modules-complementaires table,
    .dashboard #vue-detail-modules table {
      padding: 12px 24px; } }
  @media (min-width: 768px) and (max-width: 1399.98px) {
    .dashboard #liste-modules-complementaires table th .text-info,
    .dashboard #vue-detail-modules table th .text-info {
      font-weight: 600; } }

#offcanvas-push {
  display: initial; }
  #offcanvas-push .uk-offcanvas-close {
    opacity: 0; }
  #offcanvas-push .menu {
    font-family: Urbanist;
    font-size: 1.6rem;
    font-weight: 700; }
  #offcanvas-push .informations {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    #offcanvas-push .informations .mod-languages_langue .lang-inline {
      width: max-content;
      transform: translate3d(0, -35px, 0px) !important; }
    #offcanvas-push .informations .dropdown-toggle::after {
      width: 30px; }
    #offcanvas-push .informations .mod-languages_langue,
    #offcanvas-push .informations #mod_solde,
    #offcanvas-push .informations .dashboard-breadcrumb,
    #offcanvas-push .informations h1,
    #offcanvas-push .informations .h1 {
      display: none !important; }
    
    #offcanvas-push .informations h1,
    #offcanvas-push .informations .h1 {
      white-space: nowrap; }
    #offcanvas-push .informations #mod_solde {
      overflow: hidden;
      height: 217px; }
    #offcanvas-push .informations #login-form {
      pointer-events: none; }
      #offcanvas-push .informations #login-form .dropdown-menu {
        width: max-content;
        transform: translate3d(0, -170px, 0px) !important; }
      #offcanvas-push .informations #login-form button {
        border-color: transparent !important; }
        #offcanvas-push .informations #login-form button .ps-avatar-initials {
          transform: scale(1.5); }
        #offcanvas-push .informations #login-form button span:last-child {
          display: none;
          opacity: 0;
          transition: all 1s; }
    
    #offcanvas-push .informations h1,
    #offcanvas-push .informations .h1 {
      margin-top: 48px; }
    #offcanvas-push .informations .dashboard-breadcrumb {
      margin-top: 0; }
  #offcanvas-push.uk-open .informations {
    height: inherit; }
  #offcanvas-push.uk-open .mod-languages_langue {
    display: initial; }
  #offcanvas-push.uk-open .uk-offcanvas-close {
    opacity: 1; }
  #offcanvas-push.uk-open .burger {
    display: none !important; }
  #offcanvas-push.uk-open #mod_solde,
  #offcanvas-push.uk-open .dashboard-breadcrumb,
  #offcanvas-push.uk-open h1,
  #offcanvas-push.uk-open .h1 {
    display: block !important; }
  #offcanvas-push.uk-open .mod-languages_langue {
    display: flex !important; }
  #offcanvas-push.uk-open #login-form {
    pointer-events: initial; }
    #offcanvas-push.uk-open #login-form button {
      border-color: initial !important; }
      #offcanvas-push.uk-open #login-form button .ps-avatar-initials {
        transform: scale(1); }
      #offcanvas-push.uk-open #login-form button span:last-child {
        display: block;
        opacity: 1; }
  #offcanvas-push.uk-open > .uk-offcanvas-bar {
    left: 0;
    width: 330px; }

.uk-offcanvas-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100px;
  left: 0;
  padding: 0 10px;
  padding-bottom: 40px;
  transition: all 0.3s; }

@media (min-width: 768px) and (max-width: 1399.98px) {
  margin-left: 15px; }

.ps-avatar-initials {
  border-radius: 50%;
  background-color: #fff;
  color: #C98600;
  aspect-ratio: 1;
  width: 4.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Urbanist, "Open Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  border: 1px solid #F9F7F4; }

.ps-header {
  --ps-header-padding-x: 1.6rem;
  --ps-header-padding-y: 2.4rem;
  padding: var(--ps-header-padding-x) var(--ps-header-padding-y); }

.ps-avatar-container {
  position: relative; }

.ps-avatar-btn {
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
  padding-top: .8rem;
  padding-bottom: .8rem; }

.ps-avatar-img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover; }

.actions-salaries a.disabled {
  color: #000; }

.actions-salaries a.active {
  color: #C98600;
  font-weight: 700; }

.mdp.hide {
  display: none; }

.table .table-link-hover, table .table-link-hover {
  visibility: hidden; }

.table tr:hover .table-link-hover, table tr:hover .table-link-hover {
  visibility: visible; }

.table .table-highlight td, table .table-highlight td {
  color: #EB9E03;
  font-weight: 600; }

#show-hide-password img {
  width: 24px; }

.show-hide-password img {
  vertical-align: baseline; }

#affectation-modules table {
  font-size: 1.2rem; }
  #affectation-modules table .passeport {
    width: 150px;
    padding: 1rem 4.4rem 1rem 1rem; }

.recherche-salarie {
  font-size: 1.4rem; }
  .recherche-salarie td .js-password:nth-child(2) {
    font-weight: bold; }

#vue-generale table tbody td:nth-child(2), #vue-generale table tbody td:nth-child(3), #vue-detail-modules table tbody td:nth-child(2), #vue-detail-modules table tbody td:nth-child(3) {
  padding: 1.6rem 0.5rem; }

.text-overflow {
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.force-position {
  top: 0 !important;
  margin-top: 50px !important; }

select:invalid {
  height: 0px !important;
  opacity: 0 !important;
  position: absolute !important;
  display: flex !important; }

select:invalid[multiple] {
  margin-top: 15px !important; }

#videowrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }
  #videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.hidden {
  display: none !important; }

#bar {
  width: 0%;
  height: 5px;
  border-radius: 16px;
  border: 1px solid #ECE8DF;
  height: 16px; }

#zone-quizz .mauvais {
  background-color: #E05C5C !important;
  color: #fff;
  border-color: initial; }

#zone-quizz .bon {
  background-color: #64B375 !important;
  color: #fff;
  border-color: initial; }

#zone-quizz .drop-zone {
  position: relative; }
  #zone-quizz .drop-zone > div {
    font-size: 14px; }
  #zone-quizz .drop-zone .draggable {
    margin: auto; }

.form-actions-bar {
  z-index: 10; }

.credit-counter-text {
  font-size: 1.8rem;
  line-height: 2.2rem; }

.credit-counter-action {
  font-family: Urbanist, "Open Sans", sans-serif; }

.divider {
  position: relative;
  display: flex;
  justify-content: center;
  color: #989898;
  margin: 32px 0; }
  .divider:before, .divider:after {
    content: "";
    position: absolute;
    top: 50%;
    max-width: calc(50% - 24px);
    border-bottom: 1px solid #F9F7F4; }
  .divider:before {
    right: calc(50% + 24px);
    width: 100%; }
  .divider:after {
    left: calc(50% + 24px);
    width: 100%; }

.bg-donuts {
  background-image: url(../images/donut.svg);
  background-repeat: no-repeat;
  background-position: top right; }

/*
  Bootstrap a déjà créé ces class :

  - fw-bold // 700
  - fw-bolder / bolder
  - fw-normal // 400
  - fw-light // 300
  - fw-lighter // lighter
  - fst-italic // font-style italic
  - fst-normal // font-style normal
  https://getbootstrap.com/docs/5.1/utilities/text/#font-weight-and-italics
 */
.fw-semi-bold {
  font-weight: 600; }

.fw-medium, .dashboard-login #zone-quizz .quizz-39 .draggable p,
.dashboard-login #zone-quizz .quizz-40 .draggable p {
  font-weight: 500; }

.rounded-ovale {
  border-radius: 61% 39% 50% 50% / 60% 42% 58% 40%; }

/* ====================== Le responsive du vw-full de mobil vers desktop (vw-sm-full etc...)
 */
.vw--full {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw; }

@media (min-width: 576px) {
  .vw--sm-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 768px) {
  .vw--md-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 992px) {
  .vw--lg-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 1200px) {
  .vw--xl-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 1400px) {
  .vw--xxl-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

.min-h-100 {
  min-height: 100%; }

.text-border {
  color: #ECE8DF !important; }

.nav .nav-link {
  line-height: 20px;
  cursor: pointer; }

.nav-pills .nav-link {
  font-family: Urbanist, "Open Sans", sans-serif;
  font-weight: 700;
  --bs-nav-pills-link-active-color: #989898; }
  .nav-pills .nav-link.active {
    --bs-nav-pills-link-active-color: #000; }

.accordion-item {
  border-radius: 0.8rem;
  margin-bottom: .8rem; }

.accordion-button {
  border-radius: 0.8rem;
  font-size: 1.6rem;
  font-family: Inter, "Open Sans", sans-serif; }

.form-label {
  font-family: Inter, "Open Sans", sans-serif; }

.list-group .list-group-item {
  background: none; }

.alert {
  font-weight: 500;
  font-size: 1.4rem; }
  .alert.alert-success {
    --bs-alert-color: #64B375;
    --bs-alert-bg: #EDFEE8;
    --bs-alert-border-color: #EDFEE8; }
  .alert.alert-info {
    --bs-alert-color: #133C6B;
    --bs-alert-bg: #D7E8FD;
    --bs-alert-border-color: #D7E8FD; }
  .alert.alert-warning {
    --bs-alert-color: #C0AB77;
    --bs-alert-bg: #FAF8DC;
    --bs-alert-border-color: #FAF8DC; }
  .alert.alert-danger, .alert.alert-error {
    --bs-alert-color: #E05C5C;
    --bs-alert-bg: #FCF4F7;
    --bs-alert-border-color: #FCF4F7; }
  .alert a {
    font-weight: 700;
    color: var(--bs-alert-color);
    text-decoration: underline; }

.form-control, .chzn-container .chzn-drop .chzn-search input[type="text"] {
  line-height: 1.8rem;
  padding: 1.2rem; }

.form-check-input:checked, input:checked[type="radio"], input:checked[type="checkbox"] {
  background-color: #EB9E03;
  border-color: #EB9E03; }

.form-check-input:checked, input:checked[type="radio"], input:checked[type="checkbox"] {
  background-color: #EB9E03;
  border-color: #EB9E03; }

.form-select {
  padding: 1.2rem 3rem 1.2rem 1.2rem;
  background-position: right 1rem center; }

@media (min-width: 1200px) {
  .form-select, .form-control, .chzn-container .chzn-drop .chzn-search input[type="text"] {
    font-size: 1.2rem; } }

.form-select:focus {
  color: #414141; }

.dropdown .dropdown-menu a.dropdown-item {
  color: #000; }
  .dropdown .dropdown-menu a.dropdown-item:hover {
    color: #EB9E03; }
  .dropdown .dropdown-menu a.dropdown-item.active {
    background: #EB9E03;
    color: #000; }

.table, table {
  border-spacing: 0 .8rem;
  border-collapse: separate; }
  .table-container {
    padding: 2.4rem; }
  .table-fixhead thead th {
    position: sticky;
    top: 0;
    background: #F9F7F4; }
  .table thead th, table thead th {
    color: #989898;
    font-weight: 600;
    font-size: 1.2rem;
    font-family: Urbanist, "Open Sans", sans-serif;
    line-height: 1.4rem;
    padding: .8rem; }
  .table tbody tr:hover, table tbody tr:hover {
    border-radius: 0.8rem;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25); }
  .table th, table th, .table td, table td {
    vertical-align: middle;
    padding: 1.6rem 0; }
    .table th:first-child, table th:first-child, .table td:first-child, table td:first-child {
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
      padding-left: 2.4rem; }
    .table th:last-child, table th:last-child, .table td:last-child, table td:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
      padding-right: 2.4rem; }

.btn-white {
  --bs-btn-color: #989898; }

.btn-outline-primary.btn-text-black {
  --bs-btn-color: #000; }

.btn-toggle-more {
  line-height: 0;
  padding: 0.6rem 1.2rem; }
  .btn-toggle-more:after {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTMuNUM2LjgyODQzIDEzLjUgNy41IDEyLjgyODQgNy41IDEyQzcuNSAxMS4xNzE2IDYuODI4NDMgMTAuNSA2IDEwLjVDNS4xNzE1NyAxMC41IDQuNSAxMS4xNzE2IDQuNSAxMkM0LjUgMTIuODI4NCA1LjE3MTU3IDEzLjUgNiAxMy41WiIgZmlsbD0iY3VycmVudENvbG9yIi8+CjxwYXRoIGQ9Ik0xMiAxMy41QzEyLjgyODQgMTMuNSAxMy41IDEyLjgyODQgMTMuNSAxMkMxMy41IDExLjE3MTYgMTIuODI4NCAxMC41IDEyIDEwLjVDMTEuMTcxNiAxMC41IDEwLjUgMTEuMTcxNiAxMC41IDEyQzEwLjUgMTIuODI4NCAxMS4xNzE2IDEzLjUgMTIgMTMuNVoiIGZpbGw9ImN1cnJlbnRDb2xvciIvPgo8cGF0aCBkPSJNMTggMTMuNUMxOC44Mjg0IDEzLjUgMTkuNSAxMi44Mjg0IDE5LjUgMTJDMTkuNSAxMS4xNzE2IDE4LjgyODQgMTAuNSAxOCAxMC41QzE3LjE3MTYgMTAuNSAxNi41IDExLjE3MTYgMTYuNSAxMkMxNi41IDEyLjgyODQgMTcuMTcxNiAxMy41IDE4IDEzLjVaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg==");
    width: 24px;
    height: 24px; }

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  box-shadow: none; }

.bg-validated {
  color: #64B375;
  background-color: #EDFEE8 !important; }

.bg-to-renew {
  color: #C98600;
  background-color: #FAF8DC !important; }

.bg-waiting {
  color: #C98600;
  background-color: #FCF4F7 !important; }

.bg-success {
  background-color: #EDFEE8 !important; }

.bg-info {
  background-color: #D7E8FD !important; }

.bg-warning {
  background-color: #FAF8DC !important; }

.bg-danger {
  background-color: #FCF4F7 !important; }

.bg-in-progress {
  background-color: #FAF8DC !important;
  color: #C0AB77 !important; }

.bg-failed {
  background-color: #E05C5C !important; }

.dropdown-toggle-more:after {
  display: inline-block;
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTMuNUM2LjgyODQzIDEzLjUgNy41IDEyLjgyODQgNy41IDEyQzcuNSAxMS4xNzE2IDYuODI4NDMgMTAuNSA2IDEwLjVDNS4xNzE1NyAxMC41IDQuNSAxMS4xNzE2IDQuNSAxMkM0LjUgMTIuODI4NCA1LjE3MTU3IDEzLjUgNiAxMy41WiIgZmlsbD0iY3VycmVudENvbG9yIi8+CjxwYXRoIGQ9Ik0xMiAxMy41QzEyLjgyODQgMTMuNSAxMy41IDEyLjgyODQgMTMuNSAxMkMxMy41IDExLjE3MTYgMTIuODI4NCAxMC41IDEyIDEwLjVDMTEuMTcxNiAxMC41IDEwLjUgMTEuMTcxNiAxMC41IDEyQzEwLjUgMTIuODI4NCAxMS4xNzE2IDEzLjUgMTIgMTMuNVoiIGZpbGw9ImN1cnJlbnRDb2xvciIvPgo8cGF0aCBkPSJNMTggMTMuNUMxOC44Mjg0IDEzLjUgMTkuNSAxMi44Mjg0IDE5LjUgMTJDMTkuNSAxMS4xNzE2IDE4LjgyODQgMTAuNSAxOCAxMC41QzE3LjE3MTYgMTAuNSAxNi41IDExLjE3MTYgMTYuNSAxMkMxNi41IDEyLjgyODQgMTcuMTcxNiAxMy41IDE4IDEzLjVaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg==");
  width: 24px;
  height: 24px; }

.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.827452 0.910734C1.15289 0.585297 1.68053 0.585297 2.00596 0.910734L6.41671 5.32148L10.8275 0.910734C11.1529 0.585297 11.6805 0.585297 12.006 0.910734C12.3314 1.23617 12.3314 1.76381 12.006 2.08925L7.00596 7.08925C6.68053 7.41468 6.15289 7.41468 5.82745 7.08925L0.827452 2.08925C0.502015 1.76381 0.502015 1.23617 0.827452 0.910734Z' fill='white'/%3E%3C/svg%3E%0A");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  border: none; }

.dropdown-toggle:hover::after {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.827452 0.910734C1.15289 0.585297 1.68053 0.585297 2.00596 0.910734L6.41671 5.32148L10.8275 0.910734C11.1529 0.585297 11.6805 0.585297 12.006 0.910734C12.3314 1.23617 12.3314 1.76381 12.006 2.08925L7.00596 7.08925C6.68053 7.41468 6.15289 7.41468 5.82745 7.08925L0.827452 2.08925C0.502015 1.76381 0.502015 1.23617 0.827452 0.910734Z' fill='%23EB9E03'/%3E%3C/svg%3E%0A"); }

.field-calendar .input-group .btn {
  z-index: initial; }

@media (min-width: 768px) and (max-width: 1399.98px) {
  .table-striped > tbody > tr:nth-of-type(2n+1) > *, table > tbody > tr:nth-of-type(2n+1) > *, table > tbody > tr:nth-of-type(2n+1) > * {
    --bs-table-accent-bg: transparent; } }

@media (min-width: 768px) and (max-width: 1399.98px) {
  .table-striped > tbody > tr:nth-of-type(2n+1) > *, table > tbody > tr:nth-of-type(2n+1) > *, table > tbody > tr:nth-of-type(2n+1) > * {
    --bs-table-accent-bg: inherit;
    color: #414141; } }

@media (min-width: 768px) and (max-width: 1399.98px) {
  .table > :not(caption) > * > *, table > :not(caption) > * > *, table > :not(caption) > * > * {
    --bs-table-accent-bg: inherit;
    color: #414141; } }

html:not(.uk-modal-page) body {
  overflow: initial !important; }

.uk-tab {
  margin: 20px 0; }
  .uk-tab li {
    border-radius: 5px;
    font-weight: bold;
    margin: 0 10px;
    padding: 8px 24px;
    color: #989898; }
    .uk-tab li a {
      padding: 0; }
    .uk-tab li.uk-active {
      background: #EB9E03;
      color: #000; }
      .uk-tab li.uk-active:hover {
        background: #C98600; }

.uk-slider-items > img {
  object-fit: cover; }

.uk-modal-dialog {
  border-radius: 2.4rem; }

.uk-modal-header {
  background: none;
  border-bottom: 1px solid #F9F7F4;
  padding: 3.2rem; }

.uk-modal-body {
  padding: 3.2rem; }

.uk-tooltip {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  line-height: 2.4rem; }
  .uk-tooltip.uk-active.arrow-bottom-left:after, .uk-tooltip.uk-active.arrow-bottom-right:after {
    content: '';
    position: absolute;
    top: -11px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 11px solid #fff; }
  .uk-tooltip.uk-active.arrow-bottom-left:after {
    left: 32px; }
  .uk-tooltip.uk-active.arrow-bottom-right:after {
    right: 32px; }

.uk-notification-message {
  background: #F9F7F4;
  color: #000;
  font-size: 1.6rem;
  cursor: pointer;
  font-weight: 600; }
  .uk-notification-message-success {
    background-color: #64B375;
    color: #EDFEE8; }
  .uk-notification-message-primary {
    background-color: #133C6B;
    color: #D7E8FD; }
  .uk-notification-message-warning {
    background-color: #C0AB77;
    color: #FAF8DC; }
  .uk-notification-message-danger {
    background-color: #E05C5C;
    color: #FCF4F7; }

#tarteaucitronRoot #tarteaucitronManager {
  padding: 8px !important; }

.chzn-container {
  font-family: Inter, "Open Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  width: 100% !important; }
  .chzn-container .chzn-single {
    font-family: Inter, "Open Sans", sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    background: #F9F7F4;
    color: #000;
    padding: 1.2rem 2rem;
    border-radius: 0.8rem;
    height: auto;
    border: 1px solid #F9F7F4;
    box-shadow: none; }
    .chzn-container .chzn-single div {
      width: 6rem; }
      .chzn-container .chzn-single div b {
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 2rem center;
        background-size: 16px 12px; }
  .chzn-container-active.chzn-with-drop .chzn-single {
    background: #F9F7F4;
    border-color: #f5cf81; }
  .chzn-container .chzn-drop {
    background: #F9F7F4;
    border-color: #f5cf81;
    box-shadow: none; }
    .chzn-container .chzn-drop .chzn-search input[type="text"] {
      background: none;
      border-color: #F9F7F4; }
  .chzn-container .chzn-results {
    color: #000; }
    .chzn-container .chzn-results li.highlighted {
      background: #f0ebe4;
      color: #000; }
