/*
  Bootstrap a déjà créé ces class :

  - fw-bold // 700
  - fw-bolder / bolder
  - fw-normal // 400
  - fw-light // 300
  - fw-lighter // lighter
  - fst-italic // font-style italic
  - fst-normal // font-style normal
  https://getbootstrap.com/docs/5.1/utilities/text/#font-weight-and-italics
 */

.fw-semi-bold{
  font-weight: $font-weight-semi-bold;
}

.fw-medium{
  font-weight: $font-weight-medium;
}