// surcharge des class bootstrap
.text{
  &-border {
    color: $border-color !important;
  }
}

.nav{
  .nav-link{
    line-height: 20px;
    cursor: pointer;
  }
  &-pills {
    .nav-link {
      font-family: $font-01;
      font-weight: 700;
      --bs-nav-pills-link-active-color: #{$secondary};
      &.active {
        --bs-nav-pills-link-active-color: #{$black};
      }
    }
  }
}

.accordion {
  &-item {
    border-radius: $accordion-border-radius;
    margin-bottom: .8rem;
  }
  &-button {
    border-radius: $accordion-inner-border-radius;
    font-size: map-get($font-sizes, 16);
    font-family: $font-02;
  }
}

.form-label {
  font-family: $font-02;
}

.list-group{
  .list-group-item{
    background: none;
  }
}

.alert {
  font-weight: 500;
  font-size: map-get($font-sizes, 14);
  &.alert-success {
    --bs-alert-color: #{$success};
    --bs-alert-bg: #{$success-light};
    --bs-alert-border-color: #{$success-light};
  }
  &.alert-info {
    --bs-alert-color: #{$info};
    --bs-alert-bg: #{$info-light};
    --bs-alert-border-color: #{$info-light};
  }
  &.alert-warning {
    --bs-alert-color: #{$warning};
    --bs-alert-bg: #{$warning-light};
    --bs-alert-border-color: #{$warning-light};
  }
  &.alert-danger, &.alert-error {
    --bs-alert-color: #{$error};
    --bs-alert-bg: #{$error-light};
    --bs-alert-border-color: #{$error-light};
  }
  a {
    font-weight: 700;
    color: var(--bs-alert-color);
    text-decoration: underline;
  }
}

.form-control {
  line-height: 1.8rem;
  padding: 1.2rem;
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}

.form-select {
  padding: 1.2rem 3rem 1.2rem 1.2rem;
  background-position: right 1rem center;
}
@media (min-width: 1200px) {
  .form-select, .form-control {
    font-size: 1.2rem;
  }
}

.form-select:focus {
  color: $gray-dark;
}

.dropdown {
  .dropdown-menu {
    a {
      &.dropdown-item {
        color: $black;
        &:hover {
          color: $primary;
        }
        &.active {
          background: $primary;
          color: $black;
        }
      }
    }
  }
}

.table {
  border-spacing: 0 .8rem;
  border-collapse: separate;
  &-container {
    padding: 2.4rem;
  }
  &-fixhead thead th {
    position:sticky;
    top:0;
    background: $light;
  }
  thead th {
    color: $secondary;
    font-weight: 600;
    font-size: map-get($font-sizes, 12);
    font-family: $font-01;
    line-height: 1.4rem;
    padding: .8rem;
  }
  tbody tr {
    &:hover {
      border-radius: $border-radius-sm;
      box-shadow: 0 2px 3px rgba($black, 0.25);
    }
  }
  th, td {
    vertical-align: middle;
    padding: 1.6rem 0;
    &:first-child {
      border-top-left-radius: $border-radius-sm;
      border-bottom-left-radius: $border-radius-sm;
      padding-left: 2.4rem;
    }
    &:last-child {
      border-top-right-radius: $border-radius-sm;
      border-bottom-right-radius: $border-radius-sm;
      padding-right: 2.4rem;
    }
  }
}

.btn{
  &-white {
    --bs-btn-color: #{$secondary};
  }
  &-outline-primary.btn-text-black {
    --bs-btn-color: #{$black};
  }
  &-toggle-more {
    line-height: 0;
    padding: 0.6rem 1.2rem;
    &:after {
      display: inline-block;
      content: "";
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTMuNUM2LjgyODQzIDEzLjUgNy41IDEyLjgyODQgNy41IDEyQzcuNSAxMS4xNzE2IDYuODI4NDMgMTAuNSA2IDEwLjVDNS4xNzE1NyAxMC41IDQuNSAxMS4xNzE2IDQuNSAxMkM0LjUgMTIuODI4NCA1LjE3MTU3IDEzLjUgNiAxMy41WiIgZmlsbD0iY3VycmVudENvbG9yIi8+CjxwYXRoIGQ9Ik0xMiAxMy41QzEyLjgyODQgMTMuNSAxMy41IDEyLjgyODQgMTMuNSAxMkMxMy41IDExLjE3MTYgMTIuODI4NCAxMC41IDEyIDEwLjVDMTEuMTcxNiAxMC41IDEwLjUgMTEuMTcxNiAxMC41IDEyQzEwLjUgMTIuODI4NCAxMS4xNzE2IDEzLjUgMTIgMTMuNVoiIGZpbGw9ImN1cnJlbnRDb2xvciIvPgo8cGF0aCBkPSJNMTggMTMuNUMxOC44Mjg0IDEzLjUgMTkuNSAxMi44Mjg0IDE5LjUgMTJDMTkuNSAxMS4xNzE2IDE4LjgyODQgMTAuNSAxOCAxMC41QzE3LjE3MTYgMTAuNSAxNi41IDExLjE3MTYgMTYuNSAxMkMxNi41IDEyLjgyODQgMTcuMTcxNiAxMy41IDE4IDEzLjVaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg==");
      width: 24px;
      height: 24px;
    }
  }
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  box-shadow: none;
}

.bg {
  &-validated {
    color: $success;
    background-color: $success-light !important;
  }

  &-to-renew {
    color: $primary-dark;
    background-color: $waiting-light !important;
  }

  &-waiting {
    color: $primary-dark;
    background-color: $error-light !important;
  }
  &-success {
    background-color: $success-light !important;
  }
  &-info {
    background-color: $info-light !important;
  }
  &-warning {
    background-color: $warning-light !important;
  }
  &-danger {
    background-color: $error-light !important;
  }
  &-in-progress {
    background-color: $warning-light !important;
    color: $warning !important;
  }
  &-failed {
    background-color: $error !important;
  }
}

.dropdown-toggle-more {
  &:after {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTMuNUM2LjgyODQzIDEzLjUgNy41IDEyLjgyODQgNy41IDEyQzcuNSAxMS4xNzE2IDYuODI4NDMgMTAuNSA2IDEwLjVDNS4xNzE1NyAxMC41IDQuNSAxMS4xNzE2IDQuNSAxMkM0LjUgMTIuODI4NCA1LjE3MTU3IDEzLjUgNiAxMy41WiIgZmlsbD0iY3VycmVudENvbG9yIi8+CjxwYXRoIGQ9Ik0xMiAxMy41QzEyLjgyODQgMTMuNSAxMy41IDEyLjgyODQgMTMuNSAxMkMxMy41IDExLjE3MTYgMTIuODI4NCAxMC41IDEyIDEwLjVDMTEuMTcxNiAxMC41IDEwLjUgMTEuMTcxNiAxMC41IDEyQzEwLjUgMTIuODI4NCAxMS4xNzE2IDEzLjUgMTIgMTMuNVoiIGZpbGw9ImN1cnJlbnRDb2xvciIvPgo8cGF0aCBkPSJNMTggMTMuNUMxOC44Mjg0IDEzLjUgMTkuNSAxMi44Mjg0IDE5LjUgMTJDMTkuNSAxMS4xNzE2IDE4LjgyODQgMTAuNSAxOCAxMC41QzE3LjE3MTYgMTAuNSAxNi41IDExLjE3MTYgMTYuNSAxMkMxNi41IDEyLjgyODQgMTcuMTcxNiAxMy41IDE4IDEzLjVaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg==");
    width: 24px;
    height: 24px;
  }
}

.dropdown-toggle {
  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.827452 0.910734C1.15289 0.585297 1.68053 0.585297 2.00596 0.910734L6.41671 5.32148L10.8275 0.910734C11.1529 0.585297 11.6805 0.585297 12.006 0.910734C12.3314 1.23617 12.3314 1.76381 12.006 2.08925L7.00596 7.08925C6.68053 7.41468 6.15289 7.41468 5.82745 7.08925L0.827452 2.08925C0.502015 1.76381 0.502015 1.23617 0.827452 0.910734Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
  }
  &:hover {
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.827452 0.910734C1.15289 0.585297 1.68053 0.585297 2.00596 0.910734L6.41671 5.32148L10.8275 0.910734C11.1529 0.585297 11.6805 0.585297 12.006 0.910734C12.3314 1.23617 12.3314 1.76381 12.006 2.08925L7.00596 7.08925C6.68053 7.41468 6.15289 7.41468 5.82745 7.08925L0.827452 2.08925C0.502015 1.76381 0.502015 1.23617 0.827452 0.910734Z' fill='%23EB9E03'/%3E%3C/svg%3E%0A");
    }
  }
}

.field-calendar .input-group .btn {
  z-index: initial;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > *, table > tbody > tr:nth-of-type(2n+1) > * {
  // Samsung galaxy TAB A7 +Lenovo M10 ZAG4 + Samsung TAB3
  @include media-breakpoint-between(md, xxl) {
    --bs-table-accent-bg: transparent;
  }
}

.table-striped > tbody > tr:nth-of-type(2n+1) > *, table > tbody > tr:nth-of-type(2n+1) > * {
  // Samsung galaxy TAB A7 +Lenovo M10 ZAG4 + Samsung TAB3
  @include media-breakpoint-between(md, xxl) {
    --bs-table-accent-bg: inherit;
    color: $gray-dark;
  }
}

.table > :not(caption) > * > *, table > :not(caption) > * > * {
  // Samsung galaxy TAB A7 +Lenovo M10 ZAG4 + Samsung TAB3
  @include media-breakpoint-between(md, xxl) {
    --bs-table-accent-bg: inherit;
    color: $gray-dark;
  }
}

html {
  &:not(.uk-modal-page) {
    body {
      overflow: initial !important;
    }
  }
}