.divider {
  position: relative;
  display: flex;
  justify-content: center;
  color: $secondary;
  margin: 32px 0;
  &:before, &:after {
    content: "";
    position: absolute;
    top: 50%;
    max-width: calc(50% - 24px);
    border-bottom: 1px solid $light;
  }
  &:before {
    right: calc(50% + 24px);
    width: 100%;
  }
  &:after {
    left: calc(50% + 24px);
    width: 100%;
  }
}