/* =======

  css à placer sur les balises html générale avant de rentrer dans le détail du dossier 5.layout et 6.blocs

======= */

* {
  & {
    scrollbar-width: 0.8rem;
    scrollbar-color: $primary;
  }
  &::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: $border-radius-pill;
    visibility: hidden;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  :hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

::-moz-selection {
  color: $white;
  background: $primary-dark;
}

::selection {
  color: $white;
  background: $primary-dark;
}

html {
  font-size: .625rem; // 1rem = 10px with this line (easier calcul)
  height: 100%;
}

body {
  @include font($font-02, $base-body-font-size, $font-weight-normal, $black);
  height: 100%;

  main {
    div,
    p {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        font-size: map-get($font-sizes, 20);
      }
    }

    ol,
    ul {
      li {
        // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
        @include media-breakpoint-between(md, xxl) {
          font-size: map-get($font-sizes, 20);
        }
      }
    }
  }
}

table {
  @extend .table;
  @extend .table-striped;
  @extend .w-100;
  margin-top: 30px;
  td {
    padding: 1.5rem !important;
  }
}

sup {
	font-size: 60%;
}

.invalid {
    color: $red;
    font-weight: bold;
}

input.invalid {
    border: 1px solid $red;
}

a{
  font-family: $font-01;
  font-weight: 700;
  text-decoration: none;
}

img, svg {
  vertical-align: middle;
}

.tarteaucitronIconBottomRight {
  bottom: 140px !important;
}
.grecaptcha-badge {
  bottom: 80px !important;
}

.rl_tooltips.popover {
  font-size: 14px;
  line-height: 20px;
  max-width: 800px !important;
}

.page-header {
  // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
  @include media-breakpoint-between(md, xxl) {
    margin-bottom: 30px;
  }
}