// Image

img,
svg {
	max-width: 100%;
	vertical-align: text-bottom;
}
a{
  img {
	border: 0;
  }
  &:hover, &:focus, &:active {
	img {
	  opacity: 0.8;
	}
  }
}

