.chzn {
  &-container {
    font-family: $form-select-font-family;
    font-size: $form-select-font-size;
    font-weight: $form-select-font-weight;
    width: 100% !important;
    .chzn-single {
      font-family: $form-select-font-family;
      font-size: $form-select-font-size;
      font-weight: $form-select-font-weight;
      background: $form-select-bg;
      color: $form-select-color;
      padding: $form-select-padding-y $form-select-padding-x;
      border-radius: $form-select-border-radius;
      height: auto;
      border: 1px solid $form-select-border-color;
      box-shadow: none;
      div {
        width: 6rem;
        b {
          background: escape-svg($form-select-indicator);
          background-repeat: no-repeat;
          background-position: $form-select-bg-position;
          background-size: $form-select-bg-size;
        }
      }
    }
    &-active.chzn-with-drop {
      .chzn-single {
        background: $form-select-bg;
        border-color: $form-select-focus-border-color;
      }
    }
    .chzn-drop {
      background: $form-select-bg;
      border-color: $form-select-focus-border-color;
      box-shadow: none;
      .chzn-search {
        input[type="text"] {
          background: none;
          @extend .form-control;
          border-color: $form-select-border-color;
        }
      }
    }
    .chzn-results{
      color: $form-select-color;
      li {
        &.highlighted {
          background: darken($form-select-bg, 5%);
          color: $form-select-color;
        }
      }
    }
  }
}