
// Gestion de l'apparition du menu déroulant
.nav.menu .parent:hover .dropdown-menu{
  display: block;
}

.nav.menu .default .nav-link{
  padding-left: 0;
}

.menu-burger{
  span, span:after, span:before{
    display: block;
    width: 20px;
    height: 2px;
    background-color: $black;
  }
  span{
    position: relative;
    margin: 10px 0;
    &:before, &:after{
      content: '';
      position: absolute;
      left: 0;
    }
    &:before{
      bottom: 6px;
    }
    &:after{
      top: 6px;
    }
  }
}

.menu-accueil {
  gap: 2.4rem;
  margin-right: 2.4rem;
  margin-bottom: 2.4rem;
  align-items: center;
  // Ipad
  @include media-breakpoint-between(lg, xxl) {
    padding-right: 10px;
  }

  // Phone
  @include media-breakpoint-down(md) {
    margin-right: 0;
  }

  li {
    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      margin-bottom: 15px;
      font-size: map-get($font-sizes, 20);
    }

    // Ipad
    @include media-breakpoint-between(md, xxl) {
      font-size: map-get($font-sizes, 18);
    }

    &:last-child {
      // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
      @include media-breakpoint-between(md, xxl) {
        margin-bottom: 0;
      }
    }
  }

  a:not(.btn) {
    color: $white;
    &:hover {
      color: $primary;
    }
  }
}

body:not(.dashboard) {
  .brand {

    // Samsung galaxy TAB A7 + Lenovo M10 ZAG4 + Samsung TAB3
    @include media-breakpoint-between(md, xxl) {
      margin-left: 20px;
    }

    @include media-breakpoint-down(lg) {
      margin-left: 10px;
      position: absolute;
      top: 0;
      left: 0;
    }

    // Phone
    @include media-breakpoint-down(md) {
      position: relative;
    }

    img {
      height: 90px;
    }
  }
}

.acces-espace {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: $white;
  text-align: center;
  .nav {
    margin-top: 3.2rem;
    display: flex;
    justify-content: center;
    .nav-item {
      &:nth-child(1) {
        padding-right: 1.2rem;
      }
      &:nth-child(2) {
        padding-left: 1.2rem;
      }
      @include make-col(6)
    }
    .nav-link {
      display: block;
      background: $white;
      padding: 2.4rem;
      border-radius: $border-radius-lg;
      color: $black;
      font-weight: 700;
      font-size: map-get($font-sizes, 24);
      font-family: $font-01;
      line-height: 2.9rem;

      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
      }

      &:hover {
        background: $primary;
      }
      img {
        display: block;
        margin: auto;
        margin-bottom: 1.2rem;
      }
      span {
        display: inline-block;
        max-width: 130px;
      }
    }
  }
}

.pre-footer {
  background: $black;
  .menu_menufooter {
    li {
      padding: 5px 10px;
    }
  }
}

.mod-languages_langue {
  @include media-breakpoint-down(md) {
    position: absolute;
    top: 0;
    right: 0;
  }
}



.menu-accueil {
  @include media-breakpoint-between(md, xl) {
    gap: 0;
    margin: 0;
    margin-top: 70px;
    margin-bottom: 1.2rem;

    .nav-link {
      font-size: map-get($font-sizes, 16);
      padding-left: 10px;
      padding-right: 10px;
      &.btn-primary {
        margin-right: 1.2rem;
      }
    }
  }
}