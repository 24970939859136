$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #373736;
$gray-900: #212529;
$black:    #000;

// Branding colors
$orange-light: #FAE8C2;
$orange: #EB9E03;
$orange-dark : #C98600;

$gray-light: #F9F7F4;
$gray: #ECE8DF;
$gray-md: #989898;
$gray-dark: #414141;
$gray-black: #1A1A1A;

// --- Info
$info: #133C6B;
$info-light: #D7E8FD;
// --- Success
$success: #64B375;
$success-light: #EDFEE8;
// --- Warning
$warning: #C0AB77;
$warning-light: #FAF8DC;
// --- Error
$error: #E05C5C;
$error-light: #FCF4F7;
// --- Waiting
$waiting: $warning;
$waiting-light: $warning-light;

$primary:       $orange;
$primary-light: $orange-light;
$primary-dark:  $orange-dark;
$secondary:     $gray-md;
$danger:        $error;

$light:          $gray-light;
$dark:          $gray-black;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "primary-dark":    $primary-dark,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "white":       $white,
    //"new-color":  $new-color, // permet de créer des class bootstrap complète de type btn-new-color, bg-new-color, text-new-color etc...
  ),
  $theme-colors
);

$font-color-light : $gray-md;
$font-color : $black;
$font-color-dark : $gray-black;
$body-color:$font-color;

/* ======= uikit overlay couleur
*/

$overlay-primary-background: rgba($gray-700, 0.6);

/* ======= couleur des liens par défault
*/

$nav-link-color: $gray-800;
$nav-link-hover-color: $black;

$dropdown-color: $secondary;
$dropdown-bg: $white;

$dropdown-header-color: $secondary;

$border-color: $gray;
