// card amélioré

.card {
	&-sm {
		--bs-card-spacer-y: #{$card-spacer-y-sm};
		--bs-card-spacer-x: #{$card-spacer-x-sm};
	}
	&-lg {
		--bs-card-spacer-y: #{$card-spacer-y-lg};
		--bs-card-spacer-x: #{$card-spacer-x-lg};
	}
}

.card-ul-child{
  .card-body{
	ul{
	  margin: 5px 0 0;
	  padding-left: 15px;
	  li{
		margin: 10px 0 0;
		position: relative;
		&:before{
		  content: '';
		  width: 6px;
		  height: 6px;
		  position: absolute;
		  left: -15px;
		  top: 9px;
		  background-color: $primary;
		  border-radius: 10px;
		}
	  }
	}
  }
}