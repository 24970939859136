.btn{
  box-shadow: none;
  &-back-to-top {
	bottom: 15%;
	right: 20px;
  }

  &.disabled {
    opacity: 0.1;
  }
}

@include media-breakpoint-down(xl) {
  .btn {
    padding: 0.8rem;
    font-size: var(--bs-nav-link-font-size);
  }
}